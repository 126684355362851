import jsPDF from 'jspdf';
import { useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { PKG } from '../../config/imports';
import { Loading } from '../../packages/wookie';
import {
  API,
  END,
  services,
  orgaosProtocolo,
  CONF,
} from '../../config/imports';

export default function ProtocolosTable({ view_protocolos }) {
  const { theme } = useContext(PKG.PackageContext);
  const [protocoloExigencia, setProtocoloExigencia] = useState(false);
  const [exigencia, setExigencia] = useState('');
  const [exigenciaNumero, setExigenciaNumero] = useState('');
  const [loadingProtocolo, setLoadingProtocolo] = useState(false);

  function downloadPDF() {
    const jsPdf = new jsPDF();
    const htmlElement = document.getElementById(
      `exigencia_${protocoloExigencia.NUMERO}_${protocoloExigencia.DIGITO}`,
    );

    const opt = {
      callback: function (jsPdf) {
        jsPdf.save(
          `Exigencia${exigenciaNumero}_${protocoloExigencia.NUMERO}-${protocoloExigencia.DIGITO}.pdf`,
        );
      },
      margin: [30, 20, 30, 20],
      autoPaging: 'text',
      html2canvas: {
        allowTaint: true,
        letterRendering: true,
        logging: false,
        scale: 0.8,
        unit: 'cm',
        format: 'a4',
        orientation: 'portrait',
        compress: false,
        putOnlyUsedFonts: true,
      },
    };

    jsPdf.html(htmlElement, opt);
  }

  const protocolosTable = () => {
    if (view_protocolos.length < 1) {
      return (
        <p className='ffSoft fw500 fs10 textContrast'>
          Nenhum protocolo encontrado
        </p>
      );
    }

    return (
      <>
        <Table
          className={`table table-striped table-hover mb-3 fs10`}
          variant={theme}
          data-html2canvas-ignore={true}
        >
          <thead className='fw500 fs10 ffSoft neverwrap faCenter breakAvoid'>
            <tr className='p-1'>
              <th colSpan={2}>Protocolo</th>
              <th>Orgão</th>
              <th>Tipo</th>
              <th>Status</th>
              <th className='faLeft'>Descrição</th>
              <th>Criado em</th>
              <th>Atualizado</th>
              <th>Usuário</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {view_protocolos.length > 0 &&
              view_protocolos.map((protocolo) => (
                <tr
                  key={protocolo.ID}
                  className='p-1 faCenter breakAvoid'
                >
                  <td className='textContrastLow'>#{protocolo.ID}</td>
                  <td className='neverwrap'>
                    {protocolo.NUMERO}-{protocolo.DIGITO}
                  </td>
                  <td>{protocolo.ORGAO}</td>
                  <td>{protocolo.TIPO}</td>
                  <td>{protocolo.STATUS}</td>
                  <td className='prewrap faLeft'>{protocolo.DESCRICAO}</td>
                  <td>
                    {new Date(protocolo.CREATED_AT).toLocaleDateString(
                      'pt-BR',
                      {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                      },
                    )}
                    <br />
                    {new Date(protocolo.CREATED_AT).toLocaleTimeString(
                      'pt-BR',
                      {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      },
                    )}
                  </td>
                  <td>
                    {' '}
                    {new Date(protocolo.UPDATED_AT).toLocaleDateString(
                      'pt-BR',
                      {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                      },
                    )}
                    <br />
                    {new Date(protocolo.UPDATED_AT).toLocaleTimeString(
                      'pt-BR',
                      {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      },
                    )}
                  </td>
                  <td>{protocolo.USER_NOME}</td>
                  <td>
                    {loadingProtocolo ? (
                      <Loading />
                    ) : (
                      <PKG.IconsUI
                        info={PKG.ui.action.update}
                        clsnm='svg14 cLink hovGreen'
                        tooltip='Atualizar protocolo'
                        click={async () => {
                          setProtocoloExigencia(protocolo);
                          setLoadingProtocolo(true);

                          const tipokey = orgaosProtocolo.find(
                            (orgao) => orgao.NOME === protocolo.ORGAO,
                          ).tipos;

                          const tipoConsulta = Object.keys(tipokey).find(
                            (key) => tipokey[key] === protocolo.TIPO,
                          );

                          try {
                            const dataProt = await services.readCRI(
                              [
                                {
                                  key: 'protocolo',
                                  value: protocolo.NUMERO,
                                },
                                {
                                  key: 'digitoVerificador',
                                  value: protocolo.DIGITO,
                                },
                              ],
                              tipoConsulta,
                            );

                            const atual = dataProt.situacaoAtual;
                            let status;
                            switch (atual) {
                              case 'Em análise':
                                status = 1;
                                break;
                              case 'Em exigência':
                                status = 3;
                                break;
                              case 'Concluído':
                              case 'Entregue':
                                status = 4;
                                break;
                              default:
                                status = 2;
                                break;
                            }

                            const andamentoProtocolo = `Protocolo: ${protocolo.NUMERO}-${protocolo.DIGITO} - ${protocolo.TIPO}
Abertura:  ${dataProt.protocolo.dataAbertura}
Natureza: ${dataProt.protocolo.natureza}
Situação: ${dataProt.situacaoAtual}
Outorgado: ${dataProt.protocolo.outorgado}
Outorgante: ${dataProt.protocolo.outorgante}`;

                            await API(END.protocolos.update.status, {
                              codigo: protocolo.CODIGO,
                              descricao: andamentoProtocolo,
                              status_id: status,
                            });

                            if (dataProt.exigencias.numero) {
                              setExigencia(dataProt.exigencias.lines);
                              setExigenciaNumero(dataProt.exigencias.numero);
                            }
                          } catch (error) {
                            console.error(error);
                          }

                          setLoadingProtocolo(false);
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div
          className='protocoloDetail'
          id='protocoloExigencia'
        >
          {exigencia ? (
            <>
              <h6
                className='ffSoft textContrastColor'
                data-html2canvas-ignore={true}
              >
                Exigência em {protocoloExigencia.NUMERO}-
                {protocoloExigencia.DIGITO}
              </h6>
              <p className='w100 faRight textContrast'>
                <span className='fw600 fs12 me-1'>Baixar PDF</span>
                <PKG.IconsUI
                  info={PKG.ui.action.download}
                  clsnm='svg16 mousePointerHover'
                  tooltip='Download exigência'
                  click={downloadPDF}
                />
              </p>
              <div className='bgWhite p-3 pageSize'>
                <div
                  className='exigenciaDocument'
                  id={`exigencia_${protocoloExigencia.NUMERO}_${protocoloExigencia.DIGITO}`}
                >
                  <div className='d-flex justify-content-end'>
                    <img
                      src={CONF.images.logotext}
                      alt='logo HamannCorp'
                      aria-label='logo HamannCorp'
                      width={250}
                    />
                  </div>
                  <div className='ffSoft cBlue mb-3'>
                    <p className='m-0 fw600'>{protocoloExigencia.ORGAO}</p>
                    <p className='m-0 fw600'>
                      Exigência no protocolo {protocoloExigencia.NUMERO}-
                      {protocoloExigencia.DIGITO} / {exigenciaNumero}
                    </p>
                  </div>
                  <div className='exigenciaText'>
                    {exigencia.map((line, idx) => (
                      <p key={idx}>{line}</p>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            ' '
          )}
        </div>
      </>
    );
  };

  return protocolosTable();
}
