import axios from 'axios';

// async function basePost (type, base, endpoint, body) {
//   let postType = type === 'files' ? 'multipart/form-data' : 'application/json';
//   const API = axios.create({
//     baseURL: base,
//     headers: {
//       'Content-Type': postType,
//     },
//   });
//   const token = sessionStorage.getItem('@App:token');
//   API.defaults.headers.Authorization = token;
//   body.token = token;

//   const response = await API(endpoint, body);
//   return response;
// }

// export async function post(base, endpoint, body) {
//   return basePost('json', base, `${endpoint}/`, body);
// }

// export async function postFiles(base, endpoint, body) {
//   return basePost('files', base, endpoint, body);
// }

export default async function API(endpoint, body) {
  const CONTENT = () => {
    switch (endpoint.content) {
      case 'files':
        return 'multipart/form-data';
      default:
        return 'application/json';
    }
  };

  const api = axios.create({
    baseURL: endpoint.base,
    headers: {
      'Content-Type': CONTENT,
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  api.defaults.headers.Authorization = token;
  body.token = token;
  if (endpoint.method === 'post') {
    response = await api.post(endpoint.endpoint, body);
  } else {
    response = await api.get(endpoint.endpoint);
  }
  return response;
}
