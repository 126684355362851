import { useContext, useEffect, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { PKG, CONF } from '../../../config/imports';

export default function TaxasCharts({ data }) {
  const { theme } = useContext(PKG.PackageContext);
  const [useData, setUseData] = useState([]);
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(false);

  const { randomColors } = CONF;
  const getRandomColor = () => {
    return randomColors[theme][
      Math.floor(Math.random() * randomColors[theme].length)
    ];
  };

  useEffect(() => {
    setLoading(true);
    const transformedData = [];
    const newData = data.reduce((acc, data, index) => {
      const { DATA, IGPM, IPCA, TR, SELIC, COPOM } = data;
      const label = `${DATA.slice(5, 7)}/${DATA.slice(0, 4)}`;
      acc[index] = {
        IGPM,
        IPCA,
        TR,
        SELIC,
        COPOM,
        'IPCA Acumulado (12m)': data['IPCA Acumulado (12m)'],
        'IGPM Acumulado (12m)': data['IGPM Acumulado (12m)'],
        'TR Acumulada (12m)': data['TR Acumulada (12m)'],
        'SELIC Acumulada (12m)': data['SELIC Acumulada (12m)'],
        name: label,
      };
      transformedData.push(acc[index]);
      return acc;
    }, {});

    const line = newData[0];
    const lineKeys = Object.keys(line).filter((key) => key !== 'name');
    setLines(lineKeys);

    setUseData(transformedData);
    setLoading(false);
  }, [data]);

  if (loading) {
    return <PKG.Loading />;
  }

  return (
    <ResponsiveContainer
      width='100%'
      height={400}
      className='ffReg bgMenuSoft borRad25 py-3'
      id={`homeTaxasCharts`}
    >
      <LineChart
        data={useData}
        margin={{ top: 25, right: 5, left: -40, bottom: 15 }}
      >
        <CartesianGrid strokeDasharray='3' />
        <XAxis dataKey='name' />
        <YAxis tickFormatter={(value) => value} />
        <Tooltip formatter={(value, name) => [value, name]} />
        <Legend 
          align='center'
          margin={{ top: 25, right: 5, left: 0, bottom: 15 }}
          />
        {lines.map((line) => (
          <Line
            key={`line_${line}`}
            type='monotone'
            dataKey={line}
            stroke={getRandomColor()}
            strokeWidth={2}
            animationDuration={5000}
            animationEasing='linear'
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}
