import { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import Swal from 'sweetalert2';

import * as SIMULACAO_JS from '../../../assets/js/simulacao.js';
import '../../../assets/css/app/simulacao.css';

import { API, END, FormGroup } from '../../../config/imports';

export default function SimuladorForm({
  obj,
  setObj,
  theme,
  full = true,
  corretor = null,
  client = true,
}) {
  const {
    // data_int,
    is_valid_phone,
    is_compound_name,
    is_valid_cpf,
    is_valid_email,
    is_valid_nascimento,
    mascaraMoeda,
    numberReFormat,
    not_blank,
  } = SIMULACAO_JS;
  const starterObj = obj;
  const colorSet =
    theme === 'light' ? 'secondary hovbgLightBlueTransp hovWhite' : 'secondary';
  const buttonColor = `outline-${colorSet} textContrast`;
  const buttonColorActive =
    theme === 'light' ? 'outline-primary active' : 'outline-warning active';
  const colorChecked = theme === 'light' ? 'cBlue fw600' : 'cYellow fw600';

  const voce = client ? 'você' : 'o cliente';

  const [cities, setCities] = useState([]);
  const [operations, setOperations] = useState([]);
  // const [loading, setLoading] = useState(false);

  const [objHid, setObjHid] = useState({
    IMOVEL_VALOR: starterObj.IMOVEL_VALOR,
    RENDA: starterObj.RENDA,
    FGTS: starterObj.FGTS,
    RECURSOS: starterObj.RECURSOS,
    OUTRO_RENDA: starterObj.OUTRO_RENDA,
    OUTRO_FGTS: starterObj.OUTRO_FGTS,
    OUTRO_RECURSOS: starterObj.OUTRO_RECURSOS,
  });

  // const imovelDescricao = {
  //   1: 'Aquisição de Imóvel Novo',
  //   2: 'Construção',
  //   3: 'Reforma ou Ampliação',
  //   4: 'Aquisição de Imóvel Usado',
  //   6: 'Aquisição de Terreno',
  //   7: 'Empréstimo Garantido por Imóvel',
  //   11: 'Imóveis CAIXA',
  // };

  useEffect(() => {
    const get_info = async () => {
      try {
        const [citiesResponse, operationsResponse] = await Promise.all([
          API(END.cidades.read, {}),
          API(END.operacoes.read, {}),
        ]);

        const { data: citiesData } = citiesResponse;
        const { data: operationsData } = operationsResponse;

        if (corretor) {
          const corretorData = await API(END.realstate.read, {
            alias: corretor,
          });
          if (corretorData.data) {
            const rst = corretorData.data[0];
            setObj({
              ...obj,
              CORRETOR: rst.CORRETOR_NOME,
              CORRETOR_CODIGO: rst.CORRETOR_CODIGO,
              IMOBILIARIA: rst.IMOBILIARIA_NOME,
              IMOBILIARIA_CODIGO: rst.IMOBILIARIA_CODIGO,
            });
          }
        }
        setCities(citiesData.items);
        setOperations(operationsData);
        setObj({
          ...obj,
          IMOVEL_VALOR: mascaraMoeda(obj.IMOVEL_VALOR),
          RENDA: mascaraMoeda(obj.RENDA),
          FGTS: mascaraMoeda(obj.FGTS),
          RECURSOS: mascaraMoeda(obj.RECURSOS),
          OUTRO_RENDA: mascaraMoeda(obj.OUTRO_RENDA),
          OUTRO_FGTS: mascaraMoeda(obj.OUTRO_FGTS),
          OUTRO_RECURSOS: mascaraMoeda(obj.OUTRO_RECURSOS),
        });
      } catch (error) {
        console.log(error);
      }
    };

    get_info();
    // eslint-disable-next-line
  }, []);

  const updateSimulacaoState = (key, value) => {
    setObj({ ...obj, [key]: value });
    if (key in objHid) {
      setObj({
        ...obj,
        [key]: mascaraMoeda(value),
      });
      setObjHid({
        ...objHid,
        [key]: numberReFormat(value),
      });
    }
  };

  const updateState = (event, isNumber = false) => {
    const element = document.getElementById(event.target.id);
    if (isNumber) {
      setObj({
        ...obj,
        [event.target.id]: mascaraMoeda(element.value),
      });
      setObjHid({
        ...objHid,
        [event.target.id]: numberReFormat(element.value),
      });
    } else {
      setObj({ ...obj, [event.target.id]: element.value });
    }
  };

  // const checkButtonsFields = () => {
  //   let errorMessage = '';
  //   let requiredFields = ['IMOVEL_DESCRICAO', 'ESTADO_CIVIL'];
  //   if (full && obj.COMPOR) {
  //     requiredFields.push('RELACIONAMENTO');
  //   }
  //   const fieldReference = {
  //     IMOVEL_DESCRICAO: 'Tipo de Financiamento',
  //     ESTADO_CIVIL: 'Seu Estado Civil',
  //     RELACIONAMENTO: 'Relacionamento com a pessoa que irá compor renda',
  //     COMPROVAR: 'Sua comprovação de renda',
  //     OUTRO_COMPROVAR: 'Comprovação de renda da pessoa que irá compor renda',
  //   };

  //   requiredFields.forEach((field) => {
  //     if (!not_blank(obj[field])) {
  //       document.getElementById(`div_${field}`).classList.add('invalid-box');
  //       errorMessage = `${errorMessage} <span className="swalDivErrorSimulacao-item">${fieldReference[field]}</span>`;
  //     } else {
  //       document.getElementById(`div_${field}`).classList.remove('invalid-box');
  //     }
  //   });

  //   const comprovacaoFields =
  //     full && obj.COMPOR ? ['COMPROVAR', 'OUTRO_COMPROVAR'] : ['COMPROVAR'];

  //   comprovacaoFields.forEach((field) => {
  //     if (obj[field].length > 0) {
  //       document.getElementById(`div_${field}`).classList.remove('invalid-box');
  //     } else {
  //       document.getElementById(`div_${field}`).classList.add('invalid-box');
  //       errorMessage = `${errorMessage} <span className="swalDivErrorSimulacao-item">${fieldReference[field]}</span>`;
  //     }
  //   });

  //   if (errorMessage.length > 0) {
  //     errorMessage = `<div id="swalDivErrorSimulacao"><p id="swalDivErrorSimulacao-title">Preencha os campos obrigatórios:</p><div  id="swalDivErrorSimulacao-items">${errorMessage}</div></div>`;
  //   }

  //   return errorMessage;
  // };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const checked = checkButtonsFields();

  //   if (checked.length > 0) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Oops...',
  //       background: theme === 'light' ? '#fff' : '#333',
  //       html: checked,
  //       iconColor: theme === 'light' ? '#aa0011' : '#aa0011c2',
  //       customClass: {
  //         title: 'fs24 fw600 textContrastColor',
  //         htmlContainer: 'fs12',
  //       },
  //     });
  //     return;
  //   }

  //   setLoading(true);

  //   setObj({
  //     ...obj,
  //     ANOS_FGTS: parseInt(obj.ANOS_FGTS),
  //     dependentes: parseInt(obj.DEPENDENTES),
  //     CLT: parseInt(obj.CLT),
  //     POSSUI_IMOVEL: parseInt(obj.POSSUI_IMOVEL),
  //     BENEFICIADO: parseInt(obj.BENEFICIADO),
  //     SERVIDOR_PUBLICO: parseInt(obj.SERVIDOR_PUBLICO),
  //     IMOVEL_DESCRICAO: imovelDescricao[obj.IMOVEL_CODIGO],
  //     RENDA: numberReFormat(objHid.RENDA),
  //     FGTS: numberReFormat(objHid.FGTS),
  //     RECURSOS: numberReFormat(objHid.RECURSOS),
  //     OUTRO_RENDA: numberReFormat(objHid.OUTRO_RENDA),
  //     OUTRO_FGTS: numberReFormat(objHid.OUTRO_FGTS),
  //     OUTRO_RECURSOS: numberReFormat(objHid.OUTRO_RECURSOS),
  //   });

  //   setLoading(false);
  // };

  const reqField = () => {
    const color = theme === 'light' ? 'cRed' : 'cDarkRed';
    return <span className={`fs10 ${color}`}>*</span>;
  };

  const validate = (e, isValid, isNumber = false) => {
    const feedbackMessages = {
      NOME: 'Informe seu nome completo',
      EMAIL: 'Informe seu e-mail corretamente',
      WHATSAPP: 'Informe um número de WhatsApp válido',
      CPF: 'Informe um CPF válido',
      NASCIMENTO: 'Informe a data de nascimento corretamente',
      IMOVEL_VALOR: 'Informe o valor do imóvel',
      IMOVEL_CIDADE: 'Informe a cidade do imóvel',
      IMOVEL_DESCRICAO: 'Informe o tipo de financiamento',
      RENDA: 'Informe a renda',
      FGTS: 'Informe o saldo aproximado de FGTS que pretende utilizar',
      RECURSOS: 'Informe o saldo de outros recursos próprios',
      OUTRO_NOME: 'Informe o nome completo',
      OUTRO_CPF: 'Informe um CPF válido',
      OUTRO_NASCIMENTO: 'Informe a data de nascimento corretamente',
      OUTRO_RENDA: 'Informe a renda',
      OUTRO_FGTS: 'Informe o saldo aproximado de FGTS que pretende utilizar',
      OUTRO_RECURSOS: 'Informe o saldo de outros recursos próprios',
    };

    const inputElement = document.getElementById(e.target.id);
    const feedbackElement = document.getElementById(`fdbk_${e.target.id}`);
    const boxElement = document.getElementById(`box_${e.target.id}`);

    if (isValid) {
      inputElement.classList.remove('is-invalid');
      feedbackElement.innerText = '';
      boxElement.classList.remove('invalid-box');
    } else {
      if (e.type === 'blur' || e.type === 'accept') {
        inputElement.classList.add('is-invalid');
        feedbackElement.innerText = feedbackMessages[e.target.id];
        boxElement.classList.add('invalid-box');
      }
    }
    updateState(e, isNumber);
  };

  const estadoCivilButtons = () => {
    const estadoCivil = [
      'Solteiro(a)',
      'Casado(a)',
      'Divorciado(a)',
      'Viúvo(a)',
      'União Estável',
    ];

    return estadoCivil.map((item) => (
      <Button
        key={item}
        size='sm'
        variant={obj.ESTADO_CIVIL === item ? buttonColorActive : buttonColor}
        className={`m-2 wpMin100 ${obj.ESTADO_CIVIL === item ? 'active' : ''}`}
        onClick={() => {
          const compor = item === 'Casado(a)' ? 1 : 0;
          document
            .getElementById('div_ESTADO_CIVIL')
            .classList.remove('invalid-box');
          const relacionamento = item === 'Casado(a)' ? 'Cônjuge' : '';
          setObj({
            ...obj,
            ESTADO_CIVIL: item,
            COMPOR: compor,
            RELACIONAMENTO: relacionamento,
          });
        }}
      >
        {item}
      </Button>
    ));
  };

  const comprovarRendaButtons = (outro = false) => {
    const comprovarRenda = [
      'Holerite',
      'Extrato bancário',
      'Declaração de Imposto de Renda',
      'Pró Labore',
      'Não tenho como COMPROVAR',
    ];

    const param = outro ? 'OUTRO_COMPROVAR' : 'COMPROVAR';

    return comprovarRenda.map((item) => (
      <Button
        key={item}
        variant={obj[param].includes(item) ? buttonColorActive : buttonColor}
        size='sm'
        className={`m-1 ${obj[param].includes(item) ? 'active' : ''}`}
        onClick={() => {
          let COMPROVAR = obj[param];
          if (COMPROVAR.includes(item)) {
            COMPROVAR = COMPROVAR.filter((i) => i !== item);
          } else {
            COMPROVAR.push(item);
          }
          if (COMPROVAR.length === 0) {
            document
              .getElementById(`div_${param}`)
              .classList.add('invalid-box');
          } else {
            document
              .getElementById(`div_${param}`)
              .classList.remove('invalid-box');
          }

          setObj({
            ...obj,
            [param]: COMPROVAR,
          });
        }}
      >
        {item}
      </Button>
    ));
  };

  const imovelDescricaoButtons = () => {
    return operations.map((operation) => (
      <Button
        key={`${operation.C}_${operation.OPERACAO}`}
        variant={
          obj.IMOVEL_CODIGO === operation.C ? buttonColorActive : buttonColor
        }
        size='sm'
        className={`m-1 ${obj.IMOVEL_CODIGO === operation.C ? 'active' : ''}`}
        onClick={() => {
          setObj({
            ...obj,
            IMOVEL_CODIGO: operation.C,
            IMOVEL_DESCRICAO: operation.OPERACAO,
          });
          document
            .getElementById('div_IMOVEL_DESCRICAO')
            .classList.remove('invalid-box');
        }}
      >
        {operation.OPERACAO}
      </Button>
    ));
  };

  const relacionamentoButtons = () => {
    const RELACIONAMENTO = [
      'Cônjuge',
      'Companheiro(a)',
      'Pai/Mãe',
      'Filho(a)',
      'Irmão/Irmã',
      'Outro',
    ];

    return RELACIONAMENTO.map((item) => (
      <Button
        key={item}
        variant={obj.RELACIONAMENTO === item ? buttonColorActive : buttonColor}
        size='sm'
        className={`m-1 ${obj.RELACIONAMENTO === item ? 'active' : ''}`}
        onClick={() => {
          setObj({
            ...obj,
            RELACIONAMENTO: item,
          });
          document
            .getElementById('div_RELACIONAMENTO')
            .classList.remove('invalid-box');
        }}
      >
        {item}
      </Button>
    ));
  };

  useEffect(() => {
    if (full) {
      if (obj.COMPOR && obj.ESTADO_CIVIL === 'Casado(a)') {
        document.getElementById('div_RELACIONAMENTO').classList.add('divHide');
      }
    }

    // eslint-disable-next-line
  }, [obj.COMPOR, obj.ESTADO_CIVIL]);

  return (
    <>
      <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 textContrastColor ffSoft'>
        Sobre {voce}
      </p>

      <FormGroup
        id='NOME'
        label='Nome completo'
        addFormControl={true}
        formControlProps={{
          type: 'text',
          required: true,
          value: obj.NOME,
          onChange: (e) => {
            validate(e, is_compound_name(e.target.value));
          },
          onBlur: (e) => {
            validate(e, is_compound_name(e.target.value));
          },
        }}
      />

      <FormGroup
        id='EMAIL'
        label='E-mail'
        addFormControl={true}
        formControlProps={{
          type: 'email',
          required: true,
          value: obj.EMAIL,
          onChange: (e) => {
            validate(e, is_valid_email(e.target.value));
          },
          onBlur: (e) => {
            validate(e, is_valid_email(e.target.value));
          },
        }}
      />

      <FormGroup
        id='WHATSAPP'
        label='WhatsApp'
        addFormControl={true}
        formControlProps={{
          type: 'text',
          as: IMaskInput,
          mask: '(00) 00000-0000',
          required: true,
          value: obj.WHATSAPP,
          onChange: (e) => {
            validate(e, is_valid_phone(e.target.value));
          },
          onBlur: (e) => {
            validate(e, is_valid_phone(e.target.value));
          },
        }}
      />

      <FormGroup
        id='CPF'
        label='CPF'
        addFormControl={true}
        formControlProps={{
          type: 'text',
          as: IMaskInput,
          mask: '000.000.000-00',
          required: true,
          value: obj.CPF,
          onChange: (e) => {
            validate(e, is_valid_cpf(e.target.value));
          },
          onBlur: (e) => {
            validate(e, is_valid_cpf(e.target.value));
          },
        }}
      />

      <FormGroup
        id='NASCIMENTO'
        label='Data de nascimento'
        addFormControl={true}
        formControlProps={{
          type: 'text',
          as: IMaskInput,
          mask: '00/00/0000',
          required: true,
          value: obj.NASCIMENTO,
          onChange: (e) => {
            validate(e, is_valid_nascimento(e.target.value));
          },
          onBlur: (e) => {
            validate(e, is_valid_nascimento(e.target.value));
          },
        }}
      />

      <div
        className='bgContrastSoftDarker p-3 mt-4'
        id='div_ESTADO_CIVIL'
      >
        <span className='mt-4 textContrast fw500 fs10'>
          Estado Civil {reqField()}
        </span>
        <div className='divButtons'>{estadoCivilButtons()}</div>
      </div>

      <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 textContrastColor ffSoft'>
        Sobre o imóvel
      </p>

      <FormGroup
        id='IMOVEL_VALOR'
        label='Valor do imóvel'
        addFormControl={true}
        formControlProps={{
          type: 'text',
          required: true,
          value: obj.IMOVEL_VALOR,
          onChange: (e) => {
            validate(e, true, true);
          },
          onKeyUp: (e) => {
            validate(e, true, true);
          },
        }}
      />

      {cities.length > 0 ? (
        <Form.Group
          className='bgContrastSoftDarker p-3 mt-4'
          id='box_IMOVEL_CIDADE'
        >
          <Form.Label
            htmlFor='IMOVEL_CIDADE'
            className='input-label textContrast fw500 fs10'
          >
            Cidade do imóvel {reqField()}
          </Form.Label>
          <Form.Select
            id='IMOVEL_CIDADE'
            name='IMOVEL_CIDADE'
            required
            value={obj.IMOVEL_CIDADE}
            onChange={(e) => {
              validate(e, not_blank(e.target.value));
            }}
          >
            <option value=''>Selecione</option>
            {cities.map((city) => (
              <option
                key={city.codigo_cef}
                value={city.nome}
              >
                {city.nome}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback
            type='invalid'
            className='mt-0 fs10'
          >
            Informe a cidade do imóvel
          </Form.Control.Feedback>
        </Form.Group>
      ) : (
        ''
      )}

      <div
        className='bgContrastSoftDarker p-3 mt-4'
        id='div_IMOVEL_DESCRICAO'
      >
        <span className='d-flex my-1 textContrast fw500 fs10'>
          Tipo de Financiamento {reqField()}
        </span>
        <div className='divButtons'>
          {operations.length > 0 && imovelDescricaoButtons()}
        </div>
      </div>

      <Form.Group className='bgContrastSoftDarker p-3 mt-4'>
        <Form.Label
          htmlFor='CORRETOR'
          className='input-label textContrast fw500 fs10'
        >
          Corretor
        </Form.Label>
        <Form.Control
          type='text'
          id='CORRETOR'
          name='CORRETOR'
          value={obj.CORRETOR}
          disabled={corretor ? true : false}
          onChange={(e) => updateSimulacaoState(e.target.id, e.target.value)}
        />
      </Form.Group>
      <Form.Group className='bgContrastSoftDarker p-3 mt-4'>
        <Form.Label
          htmlFor='IMOBILIARIA'
          className='input-label textContrast fw500 fs10'
        >
          Imobiliária
        </Form.Label>
        <Form.Control
          type='text'
          id='IMOBILIARIA'
          name='IMOBILIARIA'
          value={obj.IMOBILIARIA}
          disabled={corretor ? true : false}
          onChange={(e) => updateSimulacaoState(e.target.id, e.target.value)}
        />
      </Form.Group>

      <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 textContrastColor ffSoft'>
        Perfil de Financiamento
      </p>

      <FormGroup
        id='RENDA'
        label='Renda bruta mensal'
        addFormControl={true}
        formControlProps={{
          type: 'text',
          required: true,
          value: obj.RENDA,
          onChange: (e) => {
            validate(e, true, true);
          },
          onKeyUp: (e) => {
            validate(e, true, true);
          },
        }}
      />

      <div
        className='bgContrastSoftDarker p-3 mt-4'
        id='div_COMPROVAR'
      >
        <span className='d-flex my-1 textContrast fw500 fs10'>
          Comprovação de renda {reqField()}
        </span>
        <div className='divButtons'>{comprovarRendaButtons()}</div>
      </div>

      <FormGroup
        id='FGTS'
        label='Informe o saldo aproximado de FGTS que pretende utilizar'
        addFormControl={true}
        includeDenyField={true}
        denyFieldText='Não será utilizado saldo de FGTS'
        formControlProps={{
          type: 'text',
          required: true,
          value: obj.FGTS,
          onChange: (e) => {
            validate(e, true, true);
          },
          onKeyUp: (e) => {
            validate(e, true, true);
          },
        }}
      />

      <FormGroup
        id='RECURSOS'
        label='Informe o saldo de outros recursos próprios (exceto FGTS)'
        addFormControl={true}
        includeDenyField={true}
        denyFieldText='Não há outros recursos próprios'
        formControlProps={{
          type: 'text',
          required: true,
          value: obj.RECURSOS,
          onChange: (e) => {
            validate(e, true, true);
          },
          onKeyUp: (e) => {
            validate(e, true, true);
          },
        }}
      />

      <Form.Group className='bgContrastSoftDarker p-3 mt-4'>
        <Form.Check
          type='checkbox'
          id='COMPOR'
          required={obj.ESTADO_CIVIL.includes('Casado') ? 1 : 0}
          name='COMPOR'
          checked={obj.ESTADO_CIVIL.includes('Casado') || obj.compor}
          className='mb-0 textContrast fw500'
          label={
            obj.ESTADO_CIVIL.includes('Casado') ? (
              <>
                Incluir dados do cônjuge para compor renda
                <span
                  className={`fs10 ${theme === 'light' ? 'cRed' : 'cDarkRed'} `}
                >
                  {' '}
                  * (obrigatório para casados)
                </span>
              </>
            ) : client ? (
              'Deseja compor renda com mais uma pessoa?'
            ) : (
              'Cliente irá compor renda com mais uma pessoa?'
            )
          }
          onChange={(e) =>
            updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
          }
        />
      </Form.Group>

      {full && (obj.COMPOR || obj.ESTADO_CIVIL.includes('Casado')) ? (
        <div>
          <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 textContrastColor ffSoft'>
            Sobre{' '}
            {obj.ESTADO_CIVIL.includes('Casado')
              ? 'o cônjuge'
              : 'a pessoa que irá compor renda'}
          </p>

          {full ? (
            <>
              {obj.ESTADO_CIVIL.includes('Casado') ? (
                <p className='mt-3 mb-2 fs10'>
                  {client
                    ? 'Como você informou ser casado(a), é necessário informar os dados abaixo, mesmo que '
                    : 'Como o cliente informou ser casado(a), é necessário informar os dados abaixo, mesmo que '}
                  {obj.ESTADO_CIVIL.includes('Casado')
                    ? 'o cônjuge '
                    : 'a pessoa que irá compor '}
                  não tenha renda.
                </p>
              ) : (
                ''
              )}

              <div id='div_RELACIONAMENTO'>
                {obj.COMPOR || obj.ESTADO_CIVIL.includes('Casado') ? (
                  <Form.Group className='bgContrastSoftDarker p-3 mt-4'>
                    <Form.Label className='input-label textContrast fw500 fs10 w100'>
                      Relacionamento com a pessoa que irá compor renda{' '}
                      {reqField()}
                    </Form.Label>

                    <div className='divButtons'>{relacionamentoButtons()}</div>
                  </Form.Group>
                ) : (
                  ''
                )}
              </div>
            </>
          ) : (
            ''
          )}

          <FormGroup
            id='OUTRO_NOME'
            label='Nome completo'
            addFormControl={true}
            formControlProps={{
              type: 'text',
              required: true,
              value: obj.OUTRO_NOME,
              onChange: (e) => {
                validate(e, is_compound_name(e.target.value));
              },
              onBlur: (e) => {
                validate(e, is_compound_name(e.target.value));
              },
            }}
          />

          <FormGroup
            id='OUTRO_CPF'
            label='CPF'
            addFormControl={true}
            formControlProps={{
              type: 'text',
              as: IMaskInput,
              mask: '000.000.000-00',
              required: true,
              value: obj.OUTRO_CPF,
              onChange: (e) => {
                validate(e, is_valid_cpf(e.target.value));
              },
              onBlur: (e) => {
                validate(e, is_valid_cpf(e.target.value));
              },
            }}
          />

          <FormGroup
            id='OUTRO_NASCIMENTO'
            label='Data de nascimento'
            addFormControl={true}
            formControlProps={{
              type: 'text',
              as: IMaskInput,
              mask: '00/00/0000',
              required: true,
              value: obj.OUTRO_NASCIMENTO,
              onChange: (e) => {
                validate(e, is_valid_nascimento(e.target.value));
              },
              onBlur: (e) => {
                validate(e, is_valid_nascimento(e.target.value));
              },
            }}
          />

          <FormGroup
            id='OUTRO_RENDA'
            label='Renda bruta mensal'
            addFormControl={true}
            includeDenyField={true}
            denyFieldText='Não possui renda'
            formControlProps={{
              type: 'text',
              required: true,
              value: obj.OUTRO_RENDA,
              onChange: (e) => {
                validate(e, true, true);
              },
              onKeyUp: (e) => {
                validate(e, true, true);
              },
            }}
          />

          <FormGroup
            id='OUTRO_FGTS'
            label='Informe o saldo aproximado de FGTS que pretende utilizar'
            addFormControl={true}
            includeDenyField={true}
            denyFieldText='Não possui saldo de FGTS'
            formControlProps={{
              type: 'text',
              required: true,
              value: obj.OUTRO_FGTS,
              onChange: (e) => {
                validate(e, true, true);
              },
              onKeyUp: (e) => {
                validate(e, true, true);
              },
            }}
          />

          <FormGroup
            id='OUTRO_RECURSOS'
            label='Informe o saldo de outros recursos próprios (exceto FGTS)'
            addFormControl={true}
            includeDenyField={true}
            denyFieldText='Não possui outros recursos próprios'
            formControlProps={{
              type: 'text',
              required: true,
              value: obj.OUTRO_RECURSOS,
              onChange: (e) => {
                validate(e, true, true);
              },
              onKeyUp: (e) => {
                validate(e, true, true);
              },
            }}
          />

          <div
            className='bgContrastSoftDarker p-3 mt-4'
            id='div_OUTRO_COMPROVAR'
          >
            <span className='d-flex my-1 textContrast fw500 fs10'>
              Comprovação de renda dessa pessoa {reqField()}
            </span>
            <div className='divButtons'>{comprovarRendaButtons(true)}</div>
          </div>
        </div>
      ) : (
        ''
      )}
      <p className='border-top border-2 border-warning pt-3  mt-5 mb-3 fs14 fw600 textContrastColor ffSoft'>
        {obj.COMPOR || obj.ESTADO_CIVIL.includes('Casado')
          ? `Marque abaixo se as opções se aplicam a${voce} ou a quem irá compor renda`
          : `Marque abaixo se as opções se aplicam a${voce}`}
      </p>

      <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
        <p className='mb-1 textContrast fw500 fs10'>
          Possui Imóvel Registrado {reqField()}
        </p>
        <div className='mt-0 divYesNo'>
          <Form.Check
            required
            type='radio'
            id='POSSUI_IMOVEL_sim'
            className={`radioYesNo  ${
              obj.POSSUI_IMOVEL === 1 ? colorChecked : ''
            } `}
            name='POSSUI_IMOVEL'
            checked={obj.POSSUI_IMOVEL === '1'}
            label='Sim'
            value={'1'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />
          <Form.Check
            type='radio'
            id='POSSUI_IMOVEL_nao'
            checked={obj.POSSUI_IMOVEL === '0'}
            className={`radioYesNo ${
              obj.POSSUI_IMOVEL === '0' ? colorChecked : ''
            } `}
            name='POSSUI_IMOVEL'
            label='Não'
            value={'0'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />
        </div>
        {obj.POSSUI_IMOVEL === '1' ? (
          <Form.Group className='my-2'>
            <Form.Label
              htmlFor='POSSUI_IMOVEL_CIDADE'
              className='input-label textContrast fw500 fs10'
            >
              Cidade do imóvel {reqField()}
            </Form.Label>
            <Form.Control
              type='text'
              id='POSSUI_IMOVEL_CIDADE'
              name='POSSUI_IMOVEL_CIDADE'
              required
              value={obj.POSSUI_IMOVEL_CIDADE}
              onChange={(e) =>
                updateSimulacaoState(e.target.id, e.target.value)
              }
            />
          </Form.Group>
        ) : (
          ''
        )}
      </Form.Group>

      <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
        <p className='mb-1 textContrast fw500 fs10'>
          É servidor público? {reqField()}
        </p>
        <div className='mt-0 divYesNo'>
          <Form.Check
            required
            type='radio'
            id='SERVIDOR_PUBLICO_sim'
            name='SERVIDOR_PUBLICO'
            className={`radioYesNo ${
              obj.SERVIDOR_PUBLICO === '1' ? colorChecked : ''
            } `}
            label='Sim'
            checked={obj.SERVIDOR_PUBLICO === '1'}
            value={'1'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />{' '}
          <Form.Check
            type='radio'
            id='SERVIDOR_PUBLICO_nao'
            name='SERVIDOR_PUBLICO'
            className={`radioYesNo ${
              obj.SERVIDOR_PUBLICO === '0' ? colorChecked : ''
            } `}
            label='Não'
            checked={obj.SERVIDOR_PUBLICO === '0'}
            value={'0'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />
        </div>
      </Form.Group>

      <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
        <p className='mb-1 textContrast fw500 fs10'>
          Possui 3 anos de trabalho registrado, considerando todos os empregos
          que já teve? (mesmo se atualmente não estiver registrado) {reqField()}
        </p>
        <div className='mt-0 divYesNo'>
          <Form.Check
            required
            type='radio'
            id='ANOS_FGTS_sim'
            className={`radioYesNo ${
              obj.ANOS_FGTS === '1' ? colorChecked : ''
            } `}
            name='ANOS_FGTS'
            checked={obj.ANOS_FGTS === '1'}
            label='Sim'
            value={'1'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />
          <Form.Check
            type='radio'
            id='ANOS_FGTS_nao'
            checked={obj.ANOS_FGTS === '0'}
            className={`radioYesNo ${
              obj.ANOS_FGTS === '0' ? colorChecked : ''
            } `}
            name='ANOS_FGTS'
            label='Não'
            value={'0'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />
        </div>
      </Form.Group>

      <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
        <p className='mb-1 textContrast fw500 fs10'>
          Possui dependentes (filhos ou cônjuge)? {reqField()}
        </p>
        <div className='mt-0 divYesNo'>
          <Form.Check
            required
            type='radio'
            id='DEPENDENTES_sim'
            className={`radioYesNo ${
              obj.DEPENDENTES === '1' ? colorChecked : ''
            } `}
            name='DEPENDENTES'
            checked={obj.DEPENDENTES === '1'}
            label='Sim'
            value={'1'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />
          <Form.Check
            type='radio'
            id='DEPENDENTES_nao'
            checked={obj.DEPENDENTES === '0'}
            className={`radioYesNo ${
              obj.DEPENDENTES === '0' ? colorChecked : ''
            } `}
            name='DEPENDENTES'
            label='Não'
            value={'0'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />
        </div>
      </Form.Group>

      <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
        <p className='mb-1 textContrast fw500 fs10'>
          Atualmente registrado CLT? {reqField()}
        </p>
        <div className='mt-0 divYesNo'>
          <Form.Check
            required
            type='radio'
            id='CLT_sim'
            className={`radioYesNo ${obj.CLT === '1' ? colorChecked : ''} `}
            name='CLT'
            checked={obj.CLT === '1'}
            label='Sim'
            value={'1'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />
          <Form.Check
            type='radio'
            id='CLT_nao'
            checked={obj.CLT === '0'}
            className={`radioYesNo ${obj.CLT === '0' ? colorChecked : ''} `}
            name='CLT'
            label='Não'
            value={'0'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />
        </div>
      </Form.Group>

      <Form.Group className='bgContrastSoftDarker p-3 pb-2 mt-4'>
        <p className='mb-1 textContrast fw500 fs10'>
          Foi beneficiado por algum programa habitacional? {reqField()}
        </p>
        <div className='mt-0 divYesNo'>
          <Form.Check
            required
            type='radio'
            id='BENEFICIADO_sim'
            className={`radioYesNo ${
              obj.BENEFICIADO === '1' ? colorChecked : ''
            } `}
            name='BENEFICIADO'
            checked={obj.BENEFICIADO === '1'}
            label='Sim'
            value={'1'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />
          <Form.Check
            type='radio'
            id='BENEFICIADO_nao'
            checked={obj.BENEFICIADO === '0'}
            className={`radioYesNo ${
              obj.BENEFICIADO === '0' ? colorChecked : ''
            } `}
            name='BENEFICIADO'
            label='Não'
            value={'0'}
            onChange={(e) =>
              updateSimulacaoState(e.target.name, e.target.value)
            }
          />
        </div>
      </Form.Group>

      <Form.Group className='bgContrastSoftDarker p-3 mt-4'>
        <Form.Label
          htmlFor='INFORMACOES'
          className='input-label textContrast fw500 fs10'
        >
          Informações adicionais
        </Form.Label>
        <Form.Control
          as='textarea'
          id='INFORMACOES'
          name='INFORMACOES'
          rows={3}
          value={obj.INFORMACOES}
          onChange={(e) => updateSimulacaoState(e.target.id, e.target.value)}
        />
      </Form.Group>

      <Form.Group className='bgContrastSoftDarker p-3 my-4 textContrast fw500 fs10'>
        <Form.Check
          required
          id='COMPARTILHAR_CORRETOR'
          name='COMPARTILHAR_CORRETOR'
          checked={obj.COMPARTILHAR_CORRETOR}
          className='m-0'
          label={
            <span>
              Concordo em compartilhar as informações necessárias com o meu
              corretor
              <br />
              <span className='d-flex flex-column mt-1 fs10'>
                <span className='fw600 ffSoft textContrastLow'>
                  Por que isso é essencial?
                </span>
                <span className='mt-1 faJustify'>
                  O seu corretor de imóveis é o profissional que apoiará no
                  envio de documentos (inclusive a documentação do vendedor),
                  acompanhando vistoria de engenharia e outros procedimentos. Se
                  você não tem um corretor, desmarque essa opção.
                </span>
                <span className='mt-1 textContrastLow'>
                  Se você mudar de ideia, basta nos informar.
                </span>
              </span>
            </span>
          }
          onChange={(e) =>
            updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
          }
        />
      </Form.Group>
      <Form.Group className='bgContrastSoftDarker p-3 my-4 textContrast fw500 fs10'>
        <Form.Check
          required
          id='TERMOS'
          name='TERMOS'
          checked={obj.TERMOS}
          className='m-0'
          label={
            <span>
              Concordo com os{' '}
              <a
                href='https://www.hamanncorp.com/privacidade'
                target='_blank'
                rel='noreferrer'
                aria-label='Termos e Condições'
                title='Termos e Condições'
              >
                termos e condições
              </a>{' '}
              {reqField()}
            </span>
          }
          feedback='É necessário concordar com os termos antes de prosseguir.'
          feedbackType='invalid'
          onChange={(e) =>
            updateSimulacaoState(e.target.id, e.target.checked ? 1 : 0)
          }
        />
      </Form.Group>
    </>
  );
}
