import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { GlobalContext } from '../config/imports';

export default function ProtectedRoute({ children }) {
  const { isAuthenticated, setRedirectPath } = useContext(GlobalContext);
  const location = useLocation();

  if (!isAuthenticated) {
    setRedirectPath(location.pathname);
    return (
      <Navigate
        to='/login'
        replace
      />
    );
  }
  return children;
}
