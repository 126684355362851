import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PackageContext } from '..';

export default function PackageProvider({ children }) {
  const navigate = useNavigate();

  const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';

  const [theme, setTheme] = useState(systemTheme);
  const [allowFullscreen, setAllowFullscreen] = useState(false);

  const changeTheme = useCallback((th) => {
    document.documentElement.className = th;
    localStorage.setItem('theme', th);
    setTheme(th);
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
    const savedTheme = localStorage.getItem('theme') || mediaQuery;
    changeTheme(savedTheme);

    if (allowFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.fullscreenElement) {
        document
          .exitFullscreen()
          .then(() => console.log('Document Exited from Full screen mode'))
          .catch((err) => console.error(err));
      }
    }
  }, [theme, changeTheme, allowFullscreen]);

  const context = {
    navigate,
    theme,
    setTheme,
    changeTheme,
    systemTheme,
    allowFullscreen,
    setAllowFullscreen,
  };

  return (
    <PackageContext.Provider value={context}>
      {children}
    </PackageContext.Provider>
  );
}
