import { useCallback, useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';

import {
  API,
  END,
  GlobalContext,
  PKG,
} from '../../../config/imports';

export default function AdminFiliais() {
  const { checkLogin } = useContext(GlobalContext);
  const { theme } = useContext(PKG.PackageContext);
  const [arrayData, setArrayData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);

  const get_filiais = useCallback(async () => {
    setLoading(true);
    try {
      const response = await API(END.filiais.read, {});
      const data = response.data;
      setArrayData(data);
      setFiltered(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    checkLogin();
    get_filiais();
  }, [checkLogin, get_filiais]);

  useEffect(() => {
    checkLogin();
    get_filiais();
  }, [checkLogin, get_filiais]);

  if (loading)
    return (
      <>
        <PKG.Loading />
      </>
    );

  const filter = () => {
    const terms = document.getElementById('filter-filiais').value;

    if (terms.length > 2) {
      let filtered = arrayData.filter(
        ({ NOME, EMAIL, TELEFONE, CEP, CIDADE, ENDERECO }) =>
          NOME.toLowerCase().includes(terms.toLowerCase()) ||
          CEP.toLowerCase().includes(terms.toLowerCase()) ||
          EMAIL.toLowerCase().includes(terms.toLowerCase()) ||
          TELEFONE.toLowerCase().includes(terms.toLowerCase()) ||
          CIDADE.includes(terms.toLowerCase()) ||
          ENDERECO.toLowerCase().includes(terms.toLowerCase()),
      );
      setFiltered(filtered);
    }

    if (terms.length < 3) {
      setFiltered(arrayData);
    }
  };

  const button_on = (user) => {
    if (loading)
      return (
        <Spinner
          animation='border'
          variant='success'
          size='sm'
        />
      );
    if (user.ACTIVE === 1)
      return (
        <Badge
          className='fw200 bad-35'
          bg='success'
        >
          ON
        </Badge>
      );
    return (
      <Badge
        className='fw200 bad-35'
        bg='dark'
      >
        OFF
      </Badge>
    );
  };

  return (
    <>
      <h4 className='hc-section-title'>Filiais</h4>
      <p className='mt-2 mb-5'>
        <span className='me-2 ffSoft'>Filtrar por</span>
        <input
          type='text'
          id='filter-filiais'
          className='filter-table'
          onChange={filter}
          pattern='.{3,20}'
        />
      </p>
      <Table className='table table-striped table-hover fs10 table-vertical-middle'
        variant={theme}
      >
        <thead>
          <tr>
            <th>Nome</th>
            <th>Telefone</th>
            <th>Email</th>
            <th>Cidade</th>
            <th>Principal</th>
            <th>Ativo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filtered.map((filial) => (
            <tr
              key={`tr_profile_${filial.CODIGO}`}
              className={
                filial.ACTIVE === 1 ? 'table-users' : 'table-users-off'
              }
            >
              <td>{filial.NOME}</td>
              <td>{filial.TELEFONE}</td>
              <td>{filial.EMAIL}</td>
              <td>{filial.CIDADE}</td>
              <td>{filial.PRINCIPAL ? 'Sim' : 'Não. Tornar Principal'}</td>

              <td>
                <span
                  className='me-1'
                  data-toggle='tooltip'
                  title={filial.ACTIVE ? 'Desativar' : 'Ativar'}
                  onClick={async () => {
                    let codigo = filial.CODIGO;
                    let updated_active = filial.ACTIVE === 1 ? 0 : 1;

                    try {
                      setLoading(true);
                      await API(END.filiais.update.active, {
                        codigo,
                        active: updated_active,
                      });
                      setFiltered((prev) =>
                        prev.map((u) => {
                          if (u.CODIGO === filial.CODIGO) {
                            u.ACTIVE = updated_active;
                          }
                          return u;
                        }),
                      );
                      get_filiais();
                    } catch (error) {
                      console.error(error);
                    }
                    setLoading(false);
                  }}
                >
                  {button_on(filial)}
                </span>
              </td>
              <td>
                <button
                  type='button'
                  className='btn btn-outline-light btn-sm ms-1'
                  data-toggle='tooltip'
                  title='Deletar filial'
                  onClick={async () => {
                    setLoading(true);
                    let codigo = filial.CODIGO;
                    try {
                      await API(END.filiais.delete, {
                        codigo,
                      });
                      await get_filiais();
                    } catch (error) {
                      console.log(error);
                    }
                    setLoading(false);
                  }}
                >
                  <PKG.IconsUI
                    info={PKG.ui.action.delete.trash}
                    clsnm='svg09 fc-red'
                  />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
