import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../../assets/css/app/support.css';

import { GlobalContext, PKG, API, END } from '../../config/imports';

function Support() {
  const { checkLogin, a, store } = useContext(GlobalContext);
  const [titulo, setTitulo] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [message, setMessage] = useState('');
  const [meusTickets, setMeusTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prioridadeSelecionada, setPrioridadeSelecionada] = useState(3);
  const [categoria, setCategoria] = useState(1);
  const { prioridades } = a;
  const [isLogged, setIsLogged] = useState(false);

  // const codigo = sessionStorage.getItem('@App:codigo');

  const { support_categories } = a;
  const { user, email } = store();

  useEffect(() => {
    const logged = checkLogin();
    setIsLogged(logged);
    setMessage('');
  }, [checkLogin]);

  const handlePrioridadeChange = (novaPrioridade) => {
    setPrioridadeSelecionada(novaPrioridade);
  };
  // const obj = {
  //   section: 'support',
  // };

  // useEffect(() => {
  //   const getTickets = async () => {
  //     try {
  //       const response = await API(END.support.read, {
  //         user: user,
  //         email: email,
  //         codigo: codigo,
  //         scope: 'm',
  //       });
  //       const data = response.data;
  //       setMeusTickets(data.data);
  //     } catch (error) {
  //       setMessage(error);
  //       console.log(error);
  //     }
  //   };
  //   getTickets();
  // }, [user, email, codigo, API, support]);

  const sendTicket = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await API(END.support.create, {
        user: user.user,
        email: user.email,
        user_code: user.codigo,
        subject: titulo,
        message: mensagem,
        priority: prioridadeSelecionada,
        category: categoria,
      });
      const response = await API(END.support.read, {
        user: user.user,
        email: user.email,
        scope: 'm',
      });
      const data = response.data;
      setMeusTickets(data);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  if (loading) {
    return <PKG.Loading />;
  }

  if (isLogged) {
    return (
      <div className='setWidth mt-5'>
        <h4 className='d-flex flex-row align-items-center mb-5'>
          <PKG.IC.BiSupport />
          <span className='ms-2'>Suporte</span>
        </h4>
        <h5 className='mb-3'>Olá, {user}!</h5>
        <p className='mt-2 mb-4 f09'>
          Preencha abaixo os dados para enviar uma solicitação de suporte
        </p>

        <p className='d-flex flex-row my-0'>
          <span className='wp125 ffSoft textContrastLow fw500'>
            Solicitante
          </span>
          <span className=''>{user}</span>
        </p>
        <p className='d-flex flex-row my-0'>
          <span className='wp125 ffSoft textContrastLow fw500'>E-mail</span>
          <span className=''>{email}</span>
        </p>

        <div className='d-flex flex-row align-items-center mt-1'>
          <span className='wp100 ffSoft textContrastLow fw500 me-1'>
            Prioridade
          </span>

          <PKG.CustomDropdown
            arrayObj={prioridades}
            defaultValue={prioridadeSelecionada}
            value={prioridadeSelecionada}
            changeCallApi={handlePrioridadeChange}
            showLabel='Prioridade'
          />
        </div>

        <div className='d-flex flex-row align-items-center mt-1'>
          <span className='wp125 ffSoft textContrastLow fw500'>Categoria</span>
          <Form.Select
            className='form-select wp200'
            size='sm'
            onChange={({ target: { value } }) => setCategoria(value)}
          >
            {support_categories.map((category) => (
              <option
                key={`key_cat_${category.ID}_sup`}
                value={category.c}
              >
                {category.NOME}
              </option>
            ))}
          </Form.Select>
        </div>

        <Form
          action=''
          className='w100 mt-4'
        >
          <Form.Group className='mb-4'>
            <Form.Label
              htmlFor='cpf'
              className='input-label'
            >
              Título
            </Form.Label>
            <Form.Control
              type='text'
              id='titulo'
              autoComplete='titulo'
              name='titulo'
              aria-describedby='titulo'
              onChange={({ target: { value } }) => setTitulo(value)}
            />
          </Form.Group>

          <Form.Group className='mb-4'>
            <Form.Label
              htmlFor='mensagem'
              className='input-label'
            >
              Mensagem
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              id='mensagem'
              autoComplete='mensagem'
              name='mensagem'
              aria-describedby='mensagem'
              onChange={({ target: { value } }) => setMensagem(value)}
            />
          </Form.Group>
          <p>
            <span className='text-danger'>{message}</span>
          </p>
          <Button
            variant='primary'
            type='submit'
            className='mt-4'
            onClick={sendTicket}
          >
            Enviar
          </Button>
        </Form>

        <div>
          <h5 className='mt-5 mb-3'>Meus Tickets</h5>
          <div>
            {meusTickets.length > 0 ? (
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'>Data</th>
                    <th scope='col'>Título</th>
                    <th scope='col'>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {meusTickets.map((ticket) => (
                    <tr key={ticket.codigo}>
                      <td>
                        {new Date(`${ticket.created_at}`).toLocaleString(
                          'pt-BR',
                        )}
                      </td>
                      <td>{ticket.subject}</td>
                      <td>{ticket.status}</td>
                      <td>
                        <PKG.IC.MdOpenInNew />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className='f09'>Não há tickets cadastrados</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Support;
