export default function GoogleCalendarLink({
  title,
  description,
  location,
  startTime,
  endTime,
  participants,
  idLink,
}) {
  const encodedTitle = encodeURIComponent(title);
  const encodedDescription = encodeURIComponent(description);
  const encodedLocation = encodeURIComponent(location);
  const encodedStartTime = encodeURIComponent(startTime);
  const encodedEndTime = encodeURIComponent(endTime);
  const encodedParticipants = participants
    .map((participant) => encodeURIComponent(participant))
    .join(',');

  const googleCalendarBaseUrl =
    'https://calendar.google.com/calendar/render?action=TEMPLATE';

  const eventUrl = `${googleCalendarBaseUrl}&text=${encodedTitle}&details=${encodedDescription}&location=${encodedLocation}&dates=${encodedStartTime}/${encodedEndTime}&add=${encodedParticipants}`;

  return (
    <a
      href={eventUrl}
      target='_blank'
      rel='noreferrer'
      aria-label='Abrir no Google Calendar'
      className='fs10 ffSoft btn btn-sm btn-outline-secondary'
      id={idLink}
    >
      Google Calendar
    </a>
  );
}

export function GoogleCalendarURL({
  title,
  description,
  location,
  startTime,
  endTime,
  participants
}) {
  const encodedTitle = encodeURIComponent(title);
  const encodedDescription = encodeURIComponent(description);
  const encodedLocation = encodeURIComponent(location);
  const encodedStartTime = encodeURIComponent(startTime);
  const encodedEndTime = encodeURIComponent(endTime);
  const encodedParticipants = participants
    .map((participant) => encodeURIComponent(participant))
    .join(',');

  const googleCalendarBaseUrl =
    'https://calendar.google.com/calendar/render?action=TEMPLATE';

  return `${googleCalendarBaseUrl}&text=${encodedTitle}&details=${encodedDescription}&location=${encodedLocation}&dates=${encodedStartTime}/${encodedEndTime}&add=${encodedParticipants}`;

}
