import React, { useContext, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import '../../assets/css/app/page.processo.css';
import Swal from 'sweetalert2';

import {
  ProtocolosTable,
  AndamentosLine,
  EtapaIcon,
  Footer,
  GlobalContext,
  PKG,
  CONF,
  SCR,
  NavBar,
  API,
  END,
} from '../../config/imports';

export default function ProcessoReport() {
  const { checkLogin, navigate, emailArchive, setEmailArchive, emailccArchive, setEmailccArchive } =
    useContext(GlobalContext);
  const { theme, changeTheme } = useContext(PKG.PackageContext);
  const savedTheme = theme;
  const { numberFormat } = SCR.Helpers;
  const { codigo } = useParams();

  const [processo, setProcesso] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [andamentos, setAndamentos] = useState([]);
  const [protocolos, setProtocolos] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [percentColor, setPercentColor] = useState('primary');

  const [procdocs, setProcdocs] = useState([]);
  const [logged, setLogged] = useState(true);

  useEffect(() => {
    document.documentElement.className = 'light';
    changeTheme('light');
    const status = checkLogin();
    setLogged(status);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handlePDF(mail) {
    setLoading(true);
    document.documentElement.className = 'light';
    document.getElementById('printHeaderImage').classList.remove('divHide');
    const filename = `Relatório de Processo - ${processo.CLIENTE_NOME}.pdf`;

    const emailBody = `
      <p>Olá, segue em anexo o relatório do processo de ${processo.CLIENTE_NOME}.</p>
      <br><br>
      <p>Atenciosamente,</p>
      <p>${CONF.setup.name}</p>
    `;

    await SCR.PDF.sendPdfToMail(
      'downloadProcessoReport',
      filename,
      mail,
      `Relatório de Processo | ${processo.CLIENTE_NOME} [${processo.PROCESSO_CODIGO}]`,
      emailBody,
      emailccArchive,
    );
    changeTheme(savedTheme);
    setEmailArchive('');
    setEmailccArchive('');
    setLoading(false);
    navigate(`/processo/${processo.PROCESSO_CODIGO}`);
  }

  async function handleDownload() {
    setLoading(true);
    document.documentElement.className = 'light';
    document.getElementById('printHeaderImage').classList.remove('divHide');
    const filename = `Relatório de Processo - ${processo.CLIENTE_NOME}.pdf`;

    await SCR.PDF.download('downloadProcessoReport', filename);
    changeTheme(savedTheme);
    // navigate(-1);
    setLoading(false);
  }

  useEffect(() => {
    const get_processo = async () => {
      setLoading(true);
      try {
        const [
          processoResponse,
          etapasResponse,
          andamentosResponse,
          protocolosResponse,
          procdocsResponse,
        ] = await Promise.all([
          API(END.processos.read.codigo, {
            codigo,
          }),
          API(END.processo_etapa.read, {
            codigo,
          }),
          API(END.andamentos.read.all, {
            codigo,
          }),
          API(END.views.protocolos_processo, {
            codigo,
          }),
          API(END.views.procdocs, {
            codigo,
          }),
        ]);

        const { data: processoData } = processoResponse;
        const { data: etapasData } = etapasResponse;
        const { data: andamentosData } = andamentosResponse;
        const { data: protocolosData } = protocolosResponse;
        const { data: procdocsData } = procdocsResponse;

        const percent_concluido =
          etapasData.filter((et) => et.PROCESSO_ETAPAS_CONCLUIDA).length /
          etapasData.length;
        const perc = percent_concluido * 100;
        setPercent(perc);

        if (perc < 20) {
          setPercentColor('secondary');
        } else if (perc < 40) {
          setPercentColor('warning');
        } else if (perc < 60) {
          setPercentColor('info');
        } else if (perc < 80) {
          setPercentColor('primary');
        } else {
          setPercentColor('success');
        }

        if (processoData) setProcesso(processoData[0]);
        if (andamentosData) setAndamentos(andamentosData);
        if (etapasData) setEtapas(etapasData);
        if (protocolosData) setProtocolos(protocolosData);
        if (procdocsData) setProcdocs(procdocsData);
      } catch (error) {
        console.error(error);
      }
      setLoaded(true);
      setLoading(false);
    };
    get_processo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigo, END]);

  useEffect(() => {
    if (loaded && emailArchive) handlePDF(emailArchive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const headerProcesso = () => {
    return (
      <>
        <div className='processo-header my-3'>
          <h6 className='ffSoft mb-0'>Relatório de Processo</h6>
          <p className='fs10 textContrastLow mb-3'>
            Gerado em {new Date().toLocaleDateString('pt-BR')}
            {' às '}
            {new Date().toLocaleTimeString('pt-BR')}
          </p>

          <h4 className='textContrastColor'>{processo.CLIENTE_NOME}</h4>
          <div>
            <p>{`${processo.OPERACAO}: ${numberFormat(
              processo.VALOR_IMOVEL,
            )} (${processo.CIDADE_IMOVEL})`}</p>
            <p>
              {`Corretor: ${
                processo.CORRETOR_NOME === null
                  ? 'Nenhum'
                  : processo.CORRETOR_NOME
              }`}
              {processo.IMOBILIARIA_NOME !== null &&
              processo.IMOBILIARIA_NOME !== '' &&
              processo.IMOBILIARIA_NOME !== 'Nenhuma'
                ? ` | Imobiliária: ${processo.IMOBILIARIA_NOME}`
                : ''}
            </p>
          </div>
          <ProgressBar
            now={percent}
            variant={percentColor}
            label={`${percent}%`}
            className='bgContrastSoft'
          />
        </div>
        {!processo.C ? (
          <>
            <h6 className='ffSoft textContrastColorBlue'>
              Protocolos do Processo
            </h6>
            <div
              id='dv_cont_processo'
              className={`mb-3`}
            >
              <ProtocolosTable view_protocolos={protocolos} />
            </div>
          </>
        ) : (
          ''
        )}
        <>
          <h6 className='ffSoft textContrastColorBlue'>Documentos</h6>

          <>
            {procdocs.map((doc) => {
              return (
                <div
                  key={doc.CODIGO}
                  className='d-flex flex-row justify-content-start align-items-center breakAvoid'
                  id={`doc_${doc.CODIGO}`}
                >
                  <div className='d-flex flex-row justify-content-start align-items-center py-1 '>
                    <span className='me-2 py-1'>
                      {PKG.fileIcon(doc.FILES_TYPE, 'svg14')}
                    </span>
                    <div className='gocol align-items-start'>
                      <span className='gorow'>
                        <span className='fs10 textContrastColor me-2'>
                          {doc.DOCUMENTO_TYPE}
                        </span>
                        <span className='fs10 me-2 '>{doc.FILES_NAME}</span>
                      </span>
                      <span className='fs09 textContrastLow me-2'>
                        {`por ${doc.CREATED_BY_NOME} em ${new Date(
                          doc.CREATED_AT,
                        ).toLocaleDateString('pt-BR')} às ${new Date(
                          doc.CREATED_AT,
                        ).toLocaleTimeString('pt-BR')}`}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </>

        <h6 className='my-4 ffSoft textContrastColorBlue'>Etapas</h6>
      </>
    );
  };

  if (!logged) {
    navigate('/logout');
  } else {
    if (loading) {
      return <PKG.LoadingDotsLight conf={CONF} />;
    }

    return (
      <PKG.ContainerBox
        main={
          <div className='w100 mb-5'>
            <div className='gorow'>
              <PKG.IconsUI
                info={PKG.ui.file.pdf}
                clsnm='svg14 cRed hovGreen mousePointerHover ms-2'
                canvasIgnore={true}
                click={() => {
                  Swal.fire({
                    title: 'Informe o e-mail para envio do relatório',
                    input: 'email',
                    inputPlaceholder: 'E-mail',
                    showCancelButton: true,
                    confirmButtonText: 'Enviar',
                    cancelButtonText: 'Cancelar',
                    background: theme === 'light' ? '#fff' : '#333',
                    iconColor: theme === 'light' ? '#aa0011' : '#aa0011c2',
                    customClass: {
                      title: 'fs24 mt-3 mb-2 fw600 textContrastColor',
                      confirmButton: 'btn btn-success',
                      cancelButton: 'btn btn-danger',
                      validationMessage: 'fs12 cRed bgContrastSoftDarker',
                      input: 'fs14 textContrast',
                    },
                    inputValidator: (value) => {
                      if (!SCR.Helpers.is_valid_email(value)) {
                        return 'E-mail inválido';
                      }
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handlePDF(result.value);
                    }
                  });
                }}
                sideText='Gerar PDF'
                sideTextClass='fs14 ms-2'
                sideDivClass='wp125 py-3 mousePointerHover divIconsUi hovGreen'
              />
              <PKG.IconsUI
                info={PKG.ui.action.download}
                clsnm='svg14 cLink hovGreen mousePointerHover ms-2'
                canvasIgnore={true}
                click={() => {
                  handleDownload();
                }}
                sideText='Baixar'
                sideTextClass='fs14 ms-2'
                sideDivClass='wp125 p-3 mousePointerHover divIconsUi hovGreen'
              />
            </div>
            <div
              className='w100 mb-5'
              id='downloadProcessoReport'
            >
              <div
                className='pt-2 pb-3 d-flex flex-row justify-content-end align-items-center'
                id='printHeaderImage'
              >
                <img
                  src={CONF.images.logo}
                  alt='logo'
                  title='logo'
                  width='250'
                />
              </div>
              {headerProcesso()}
              {etapas &&
                etapas.map((et, index) => {
                  return (
                    <div
                      key={et.PROCESSO_ETAPAS_ID}
                      className={`py-1 ps-2 pe-1  ${
                        et.PROCESSO_ETAPAS_CONCLUIDA
                          ? 'etConcluida'
                          : processo.ETAPA_ATUAL_ID === et.PROCESSO_ETAPAS_ID
                          ? 'etAtual'
                          : 'etPendente'
                      }`}
                    >
                      <div className='etapaHeader'>
                        <div className='etapaHeaderStart py-1 gorow  gocol550'>
                          <span className='headerSection ffSoft '>
                            <span className='me-2'>
                              <EtapaIcon etapa={et.ETAPAS_NOME} />
                            </span>
                            <span
                              className={`me-3 fs12 ffSoft ${
                                et.PROCESSO_ETAPAS_CONCLUIDA
                                  ? 'textContrastLow'
                                  : processo.ETAPA_ATUAL_ID ===
                                    et.PROCESSO_ETAPAS_ID
                                  ? 'fw700 fs16 textContrastColorBlue'
                                  : ''
                              }`}
                            >
                              {et.ETAPAS_NOME}
                            </span>
                            {et.PROCESSO_ETAPAS_CONCLUIDA ? (
                              <PKG.IconsUI
                                info={PKG.ui.misc.check.circle}
                                clsnm='cGreen me-2'
                              />
                            ) : (
                              ''
                            )}
                          </span>

                          <span className='headerSection fs10'>
                            <span className='mx-1'>
                              <PKG.IconsUI
                                info={PKG.ui.action.start}
                                clsnm={`svg14 ${
                                  et.PROCESSO_ETAPAS_CONCLUIDA
                                    ? ' cGreen'
                                    : processo.ETAPA_ATUAL_ID ===
                                      et.PROCESSO_ETAPAS_ID
                                    ? ' textContrastColorBlue'
                                    : ' textContrastLow'
                                }`}
                              />
                            </span>
                            <span className='mx-1'>
                              {et.PROCESSO_ETAPAS_CONCLUIDA ||
                              processo.ETAPA_ATUAL_ID === et.PROCESSO_ETAPAS_ID
                                ? new Date(
                                    et.PROCESSO_ETAPAS_DATA_INICIAL,
                                  ).toLocaleDateString('pt-BR')
                                : ''}
                            </span>
                            <span className='mx-1'>
                              {et.PROCESSO_ETAPAS_CONCLUIDA ? (
                                <>
                                  <PKG.IconsUI
                                    info={PKG.ui.status.done}
                                    clsnm={`svg14  cGreen`}
                                  />
                                  <span className='mx-1'>
                                    {new Date(
                                      et.PROCESSO_ETAPAS_DATA_FINAL,
                                    ).toLocaleDateString('pt-BR')}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <PKG.IconsUI
                                    info={PKG.ui.status.waiting}
                                    clsnm={`svg14 textContrastColorBlue mx-2`}
                                  />
                                  <span className='mx-1'>
                                    {et.ETAPAS_DIAS_ESTIMATIVA === 1
                                      ? 'Hoje'
                                      : `+ ${et.ETAPAS_DIAS_ESTIMATIVA} dias`}
                                  </span>
                                </>
                              )}
                            </span>
                          </span>
                        </div>
                        {processo.ETAPA_ATUAL_ID === et.PROCESSO_ETAPAS_ID ? (
                          <span className='fs10 fw600 ffSoft textContrastColor px-2'>
                            Etapa Atual
                          </span>
                        ) : null}
                      </div>
                      <div
                        id={`ands_${et.PROCESSO_ETAPAS_ID}`}
                        className={`etapaContainer`}
                      >
                        {andamentos
                          .filter(
                            (and) =>
                              and.PROCESSO_ETAPA_ID === et.PROCESSO_ETAPAS_ID,
                          )
                          .map((and) => {
                            return (
                              <AndamentosLine
                                key={and.CODIGO}
                                and={and}
                                c={processo.C}
                                showDelete={false}
                                allowBlobView={false}
                              />
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        }
        footer={<Footer />}
        navbar={<NavBar />}
        overlayMain={false}
        classMain='setWidth'
        navreturn={true}
        navreturnClass='setWidth'
      />
    );
  }
}
