import React from 'react';
import {
  Account,
  Admin,
  Clientes,
  Financiamentos,
  Protocolos,
  News,
  Processos,
  Simulacoes,
  Support,
  Tasks,
  services,
} from '../../config/imports';

function RenderSection({ section, defRole = null }) {
  switch (section) {
    case 'account':
      return <Account />;
    case 'support':
      return <Support />;

    case 'admin':
      if (services.Authorize(3, defRole)) {
        return <Admin />;
      } else {
        return '';
      }
    case 'news':
      if (services.Authorize(3, defRole)) {
        return <News />;
      } else {
        return '';
      }

    case 'simulacoes':
      if (services.Authorize(5, defRole)) {
        return <Simulacoes />;
      } else {
        return '';
      }
    case 'protocolos':
      if (services.Authorize(5, defRole)) {
        return <Protocolos />;
      } else {
        return '';
      }
    case 'financiamentos':
      if (services.Authorize(5, defRole)) {
        return <Financiamentos />;
      } else {
        return '';
      }

    case 'tasks':
      if (services.Authorize(5, defRole)) {
        return <Tasks />;
      } else {
        return '';
      }
    case 'processos':
      if (services.Authorize(5, defRole)) {
        return <Processos />;
      } else {
        return '';
      }
    case 'clientes':
      if (services.Authorize(5, defRole)) {
        return <Clientes />;
      } else {
        return '';
      }

    default:
      return '';
  }
}

export default RenderSection;
