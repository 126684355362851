import * as PKG from '../packages/wookie';

const ORDS =
  'https://g3c32193a94f6bf-bwhabita.adb.sa-saopaulo-1.oraclecloudapps.com/ords/habita';
const HAPI = 'https://API.bewook.com';
const OAPI = 'https://core.bewook.com';

let hostname;
hostname = window.location.hostname.replace('www.', '');
hostname = window.location.hostname.replace('app.', '');

const hostnameConfig = {
  'hamanncorp.com': {
    tenant: 'HamannCorp',
    company: 'HamannCorp Financiamentos',
    brand: 'hamanncorp',
    headline: 'Financiamentos imobiliários de sucesso',
    description: 'Financiamentos imobiliários e crédito bancário ágil e seguro',
    site: 'hamanncorp.com',
    backupmail: 'hamanncorp@yahoo.com',
    backupmailcc: 'soldi@yahoo.com',
  },
  'habitamaster.com': {
    tenant: 'HabitaMaster',
    company: 'HabitaMaster Financiamentos',
    brand: 'habitamaster',
    headline: 'Financiamentos imobiliários de sucesso',
    description: 'Financiamentos imobiliários e crédito bancário ágil e seguro',
    site: 'habitamaster.com',
    backupmail: 'hamanncorp@yahoo.com',
    backupmailcc: 'soldi@yahoo.com',
  },
  'habitaprov.com': {
    tenant: 'Habitaprov',
    company: 'Habitaprov Financiamentos',
    brand: 'habitaprov',
    headline: 'Transformando seu sonho em realidade',
    description: 'Financiamentos imobiliários e crédito bancário ágil e seguro',
    site: 'habitaprov.com',
    backupmail: 'hamanncorp@yahoo.com',
    backupmailcc: 'soldi@yahoo.com',
  },
  'API.telegram.org': {
    tenant: 'Habitaprov',
    company: 'Habitaprov Financiamentos',
    brand: 'habitaprov',
    headline: 'Transformando seu sonho em realidade',
    description: 'Financiamentos imobiliários e crédito bancário ágil e seguro',
    site: 'habitaprov.com',
    backupmail: 'hamanncorp@yahoo.com',
    backupmailcc: 'soldi@yahoo.com',
  },
  default: {
    tenant: 'HamannCorp',
    company: 'HamannCorp Financiamentos',
    brand: 'localhost',
    headline: 'Financiamentos imobiliários de sucesso',
    description: 'Financiamentos imobiliários e crédito bancário ágil e seguro',
    site: 'hamanncorp.com',
    backupmail: 'hamanncorp@yahoo.com',
    backupmailcc: 'soldi@yahoo.com',
  },
};

const setup = {
  name: 'Marcelo Hamann',
  whatsapp: '12997777374',
  phone: '12997777374',
  phone_formatted: '(12) 99777-7374',
  email: 'credito@hamanncorp.com',
  title: 'HamannCorp',
  instagram: 'hamanncorp',
  twitter: 'hamanncorp',
  telegram: 'hamanncorp',
};

const config = hostnameConfig[hostname] || hostnameConfig['default'];

const { tenant, company, brand, headline, site, backupmail, backupmailcc } = config;

const meta = {
  type: 'HomeAndConstructionBusiness',
  imageLink: `https://www.${site}/img/brand/${brand}/logotext512.png`,
  imageType: 'image/png',
  imageWidth: 180,
  imageHeight: 162,
  description:
    'Consultoria especializada em financiamento imobiliário e crédito bancário, crédito com garantia de imóvel e compra a vista com FGTS. Conte com a melhor assessoria de Taubaté, São José dos Campos e toda a Região Metropolitana do Vale do Paraíba.',
  keywords:
    'correspondente bancário, canal de atendimento, correspondente, serviços, pontos de atendimento, habitação, casa, apartamento, financiamento, crédito, imobiliário, Caixa, Santander Itaú, Bradesco, Inter, banco público, banco privado, facilitador, correspondente, sonho, casa própria, minha casa minha vida, sbpe, poupança, fgts, consórcio, imóveis, Taubaté, São José dos Campos, Jacareí, Pindamonhangaba, Tremembé, Campos do Jordão, Ubatuba, Caraguatatuba, Litoral Norte, Vale do Paraíba, Serra da Mantiqueira, Litoral Norte, São Paulo, SP, Brasil',
  author: tenant, 
  robots: 'index, follow',
};

const swalProps = {

  light: {
    background: '#e9ecef',
    iconColor: '#aa0011',
    color: '#495057',
    customClass: {
      title: 'fs24 fw600 textContrastColor',
      htmlContainer: 'fs12',
    },
  },
  dark: {
    background: '#495057',
    iconColor: '#aa0011c2',
    color: '#e9ecef',
    customClass: {
      title: 'fs24 fw600 textContrastColor',
      htmlContainer: 'fs12',
    },
  },
}

const images = {
  light: {
    logo: require(`../assets/img/brand/${brand}/logo512.png`),
    logotext: require(`../assets/img/brand/${brand}/logotext512.png`),
  },
  dark: {
    logo: require(`../assets/img/brand/${brand}/logo512negativo.png`),
    logotext: require(`../assets/img/brand/${brand}/logotext512negativo.png`),
  },
  logo: require(`../assets/img/brand/${brand}/logotext512.png`),
  logotext: require(`../assets/img/brand/${brand}/logotext512.png`),
};
images.normal = images.light;

const links = {
  site: `https://www.${site}`,
  instagram: `https://www.instagram.com/${setup.instagram}`,
  mail: `mailto:${setup.email}`,
  whatsapp: `https://API.whatsapp.com/send?phone=55${setup.whatsapp}`,
  phone: `tel:+55${setup.phone}`,
  login: `/login`,
  register: `/register`,
  simule: `/simule`,
  simulador: `/simulador`,
  logo: `https://www.${site}/img/brand/${brand}/logotext512.png`,
  telegram: `https://t.me/${setup.telegram}`,
};

const objLinks = {
  app: {
    a: {
      href: `https://app.${site}`,
      class: 'btn outline-secondary',
      aria: 'Acesse nosso aplicativo exclusivo e acompanhe seu financiamento em tempo real',
      targetBlank: false,
      rel: 'noreferrer',
    },
    icon: {
      icon: PKG.ui.misc.app.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip:
        'Acesse nosso aplicativo exclusivo e acompanhe seu financiamento em tempo real',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Acesse nosso aplicativo exclusivo e acompanhe seu financiamento em tempo real',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  instagram: {
    a: {
      href: `https://www.instagram.com/${setup.instagram}`,
      class: 'btn outline-secondary',
      aria: 'Siga nosso Instagram',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: PKG.ui.social.instagram.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Siga nosso Instagram',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Siga nosso Instagram',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  mail: {
    a: {
      href: `mailto:${setup.email}`,
      class: 'btn outline-secondary',
      aria: 'Envie um email',
      targetBlank: false,
      rel: 'noreferrer',
    },
    icon: {
      icon: PKG.ui.social.email.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Envie um email',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Envie um email',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  whatsapp: {
    a: {
      href: `https://API.whatsapp.com/send?phone=55${setup.whatsapp}`,
      class: 'btn outline-secondary',
      aria: 'Converse pelo WhatsApp',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: PKG.ui.social.whatsapp.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Converse pelo WhatsApp',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Converse pelo WhatsApp',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  phone: {
    a: {
      href: `tel:+55${setup.phone}`,
      class: 'btn outline-secondary',
      aria: 'Ligue para nós',
      targetBlank: false,
      rel: 'noreferrer',
    },
    icon: {
      icon: PKG.ui.social.phone.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Ligue para nós',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Ligue para nós',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  telegram: {
    a: {
      href: `https://t.me/${setup.telegram}`,
      class: 'btn outline-secondary',
      aria: 'Converse pelo Telegram',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: PKG.ui.social.telegram.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Converse pelo Telegram',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Converse pelo Telegram',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  simulacao: {
    a: {
      href: `https://www.${site}/simulacao`,
      class: 'btn outline-secondary',
      aria: 'Simule seu financiamento agora',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: PKG.ui.etapas.simulacao.icon,
      class: 'svg14 hovGreen tran05 growSvg16',
      click: null,
      tooltip: 'Simule seu financiamento agora',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Simule seu financiamento agora',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  site: {
    a: {
      href: `https://www.${site}`,
      class: 'btn outline-secondary',
      aria: 'Acesse nosso site',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: PKG.ui.social.site.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Acesse nosso site',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Acesse nosso site',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  login: {
    a: {
      href: `/login`,
      class: 'btn outline-secondary',
      aria: 'Faça login',
      targetBlank: false,
      rel: 'noreferrer',
    },
    icon: {
      icon: PKG.ui.user.lock.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Faça login',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Faça login em nosso app',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  }
};

objLinks.simule = {
  ...objLinks.simulacao,
  a: {
    ...objLinks.simulacao.a,
    href: `https://www.${site}/simule`,
  },
};

const bio = {
  tenant,
  image: links.logo,
  items: [
    objLinks.site,
    objLinks.simule,
    objLinks.whatsapp,
    objLinks.phone,
    objLinks.mail,
    objLinks.app,
  ],
};

const favicon = `https://www.${site}/img/brand/${brand}/favicon.ico`;

const colors = {
  black: '#000000',
  darkest: '#02071b',
  darker: '#070d24',
  dark: '#0d142f',
  grafitte: '#393e46',
  blue: '#3a4776',
  bluer: '#007fd4',
  lightBlue: '#007fd4',
  yellow: '#f3c82e',
  gold: '#d1bb57',
  gray: '#cccccc',
  grey: '#f0f0f0',
  white: '#ffffff',
  bs: {
    blue: '#1e337c',
    indigo: '#6610f2',
    purple: '#6f42c1',
    pink: '#d63384',
    red: '#aa0000',
    orange: '#fd7e14',
    yellow: '#ffbb00',
    green: '#2cbe00',
    teal: '#00a372',
    cyan: '#007fd4',
    black: '#000',
    white: '#fff',
    gray: {
      100: '#f8f9fa',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#6c757d',
      700: '#495057',
      800: '#343a40',
      900: '#212529',
    },
  },
};

const randomColors = {
  light: [
    '#1e337c',
    '#4b0082',
    '#a020f0',
    '#d63384',
    '#aa0000',
    '#fd7e14',
    '#ffa600',
    '#2cbe00',
    '#00a372',
    '#0073ff',
  ],
  dark: [
    '#9CAFE7',
    '#BD61FF',
    '#BC62F4',
    '#DF5D9E',
    '#FF4747',
    '#fd7e14',
    '#ffa600',
    '#2cbe00',
    '#00a372',
    '#2E8CFF', 
  ],
}

randomColors.normal = randomColors.light;

const CONF = {
  brand,
  config,
  colors,
  randomColors,
  headline,
  backupmail,
  backupmailcc,
  images,
  links,
  objLinks,
  meta,
  setup,
  bio,
  swalProps,
  tenant,
  company,
  favicon,
  site,
  hostname,
  domain: window.location.hostname,
  slash: '/',
  ORDS,
  HAPI,
  OAPI,  
};

export default CONF;
