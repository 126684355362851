import { useParams } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { Form, Button } from 'react-bootstrap';

import {
  GlobalContext,
  PKG,
  NavBar,
  API,
  END,
  DragAndDrop,
} from '../config/imports';
import { IMaskInput } from 'react-imask';
import Swal from 'sweetalert2';

export default function ProcessoDocumento() {
  const { codigo, mail, phone } = useParams();
  const { theme } = useContext(PKG.PackageContext);
  const { a } = useContext(GlobalContext);

  const [email, setEmail] = useState(mail);
  const [whatsapp, setWhatsapp] = useState(phone);

  const [files, setFiles] = useState([]);

  const [validated, setValidated] = useState(false);

  const validarDados = (e) => {
    e.preventDefault();

    const messageError = '<span>Preencha todos os campos corretamente</span>';

    if (!email || !whatsapp) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        background: theme === 'light' ? '#fff' : '#333',
        html: messageError,
        iconColor: theme === 'light' ? '#aa0011' : '#aa0011c2',
        customClass: {
          title: 'fs24 fw600 textContrastColor',
          htmlContainer: 'fs12 textContrast',
        },
      });
      return;
    }

    API.post(END.processos.read.validar, {
      codigo,
      email,
      whatsapp,
    })
      .then((res) => {
        if (res.data.success) {
          setValidated(true);
        } else {
          alert(res.data.message);
          setValidated(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <PKG.ContainerBox
      navbar={<NavBar />}
      navreturn={true}
      navreturnClass='setWidth mousePointerHover hovGreen divIconsUi'
      main={
        <div className=' w100 wpMax400 faCenter p-1'>
          {/* <h3>{codigo}</h3> */}

          <h4 className='textContrastColor ffSoft mb-3 '>
            Envio de Documentos
          </h4>

          <div className='my-3 faLeft'>
            <Form
              data-bs-theme={theme}
              className='p-1'
            >
              <p className='fw500 ffSoft fs10 mt-3'>
                Valide seus dados:
                <br />
                <span className={`fs08 m-0 textContrastLow`}>
                  Informe os dados utilizados na sua simulação ou cadastro
                </span>
              </p>

              <Form.Group className='mb-3'>
                <Form.Label
                  className='fw500 fs12 ffSoft m-0'
                  htmlFor='email'
                >
                  E-mail
                </Form.Label>
                <Form.Control
                  type='email'
                  id='email'
                  name='email'
                  size='sm'
                  required
                  className='form-control fs10'
                  placeholder='E-mail'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label
                  className='fw500 fs12 ffSoft m-0'
                  htmlFor='whatsapp'
                >
                  Whatsapp
                </Form.Label>
                <Form.Control
                  type='text'
                  as={IMaskInput}
                  mask='(00) 00000-0000'
                  required
                  id='whatsapp'
                  name='whatsapp'
                  size='sm'
                  className='form-control fs10'
                  placeholder='Whatsapp'
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </Form.Group>

              <Button
                variant='outline-info'
                type='submit'
                className='w100 fs10'
                size='sm'
                onClick={validarDados}
              >
                Validar
              </Button>
            </Form>

            {validated && (
              <>
                <p className='fw500 ffSoft fs10 m-1 mt-4'>
                  Dados validados com sucesso!
                  <br />
                  <span className={`fs08 m-0 textContrastLow`}>
                    Envie seus documentos para darmos continuidade ao seu
                    processo
                  </span>
                </p>
                <DragAndDrop
                  onFilesSelected={setFiles}
                  filesSt={files}
                  docs={a.documentos}
                />
              </>
            )}
          </div>
        </div>
      }
    />
  );
}
