import { useContext, useEffect, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { PKG, CONF } from '../../../config/imports';

const NewsCharts = ({ data }) => {
  const { curr } = PKG.SCR.Utils;
  const { theme } = useContext(PKG.PackageContext);
  const [useData, setUseData] = useState([]);
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(false);

  const { randomColors } = CONF;
  const getRandomColor = () => {
    return randomColors[theme][
      Math.floor(Math.random() * randomColors[theme].length)
    ];
  };

  useEffect(() => {
    setLoading(true);

    const transformData = (data) => {
      const transformedData = [];
      const lineKeys = new Set();

      const currentMonth = new Date();
      const labels = Array.from({ length: 12 }, (_, index) => {
        const date = new Date(currentMonth);
        date.setMonth(currentMonth.getMonth() - index);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear().toString().slice(-2);
        return `${month}/${year}`;
      }); // Reverse to align month1 with the current month

      data.reduce((acc, { LINHA, TENANT_CODIGO, ...months }) => {
        acc[LINHA] = months;
        lineKeys.add(LINHA);
        return acc;
      }, {});

      labels.forEach((label, index) => {
        const monthData = { name: label };
        data.forEach((item) => {
          const linha = item.LINHA;
          monthData[linha] = parseFloat(item[`MONTH${index + 1}`]) || 0;
        });
        transformedData.push(monthData);
      });

      transformedData.reverse();

      setLines(Array.from(lineKeys));
      setUseData(transformedData);
      setLoading(false);
    };

    transformData(data);
  }, [data]);

  if (loading) {
    return <PKG.Loading />;
  }

  return (
    <ResponsiveContainer
      width='100%'
      height={400}
      className='ffReg bgMenuSoft borRad25 py-3'
      id={`homeNewsCharts`}
    >
      <LineChart
        data={useData}
        margin={{ top: 25, right: 20, left: 50, bottom: 15 }}
      >
        <CartesianGrid strokeDasharray='3' />
        <XAxis dataKey='name' />
        <YAxis tickFormatter={(value) => curr(value)} />
        <Tooltip
          formatter={(value, name) => {
            if (name === 'Total de Simulações') return [value, name];
            return [`${curr(value)}`, name];
          }}
        />
        <Legend
          layout='vertical'
          align='right'
          verticalAlign='middle'
        />
        {lines.map((line) => (
          <Line
            key={`line_${line}`}
            type='monotone'
            dataKey={line}
            strokeWidth={2}
            stroke={getRandomColor()}
            animationDuration={5000}
            animationEasing='linear'
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default NewsCharts;
