import { addYears, differenceInMonths, parse } from 'date-fns';
import { numberFormat } from './helpers';

const evalAnalises = (renda, imovel, analises, variacoes) => {
  let resultado = {};

  for (let key in analises) {
    let obj = {};
    obj.linha = analises[key].NOME;
    obj.taxa = analises[key].VALUE;
    obj.taxaEsp = analises[key].VALUE_ESP;
    obj.renda =
      renda >= analises[key].VAR_RENDA && renda <= analises[key].VAR_RENDA_MAX;
    obj.imovel =
      imovel >= analises[key].VAR_IMOVEL &&
      imovel <= analises[key].VAR_IMOVEL_MAX;
    obj.variacao = {
      id: analises[key].FK_ID,
      nome: analises[key].COD,
      valor: analises[key].VAR_VALUE,
    };
    obj.subsidio = obj.linha.includes('MCMV') ? true : false;
    obj.success = obj.renda && obj.imovel ? true : false;

    obj.evaluate = {
      renda: obj.renda,
      imovel: obj.imovel,
      subsidio: obj.subsidio,
      success: obj.success,
    };

    obj.renda = renda;
    obj.parcelaMax = renda * 0.3;

    if (obj.linha.includes('Poupança')) {
      obj.taxa = taxaPoupanca(obj.taxa, variacoes);
    }

    resultado[analises[key].NOME] = obj;
  }

  return resultado;
};

const taxaPoupanca = (TX, variacoes) => {
  const selic = parseFloat(
    variacoes  && variacoes.find((item) => item.NOME === 'Selic').VALUE,
  );
  const tr = parseFloat(
    variacoes && variacoes.find((item) => item.NOME === 'TR 12 meses').VALUE,
  );

  if (parseFloat(selic) > 8.5) {
    return 6.17 + tr + parseFloat(TX);
  }
  return 0.7 * selic + tr + parseFloat(TX);
};

const calcularMeses = (dataNascimento) => {
  const dataNascimentoDate = parse(dataNascimento, 'dd/MM/yyyy', new Date());
  const data70Anos = addYears(dataNascimentoDate, 70);
  const dataAtual = new Date();
  const totalMesesDiferenca = differenceInMonths(data70Anos, dataAtual);
  if (totalMesesDiferenca > 420) return 420;
  return totalMesesDiferenca;
};

const classTr = (result, beneficiado, possuiImovel) => {
  const match =
    result.evaluate &&
    result.evaluate.success &&
    result.compativel &&
    result.compativelPrice
      ? 'bg-table-greener-light'
      : result.evaluate &&
        result.evaluate.success &&
        (result.compativel || result.compativelPrice)
      ? 'bg-table-green-light'
      : '';
  const isMcmv = result.tabela.includes('mcmv');
  if (match && isMcmv && (beneficiado || possuiImovel))
    return 'bg-table-grey-light';
  return match;
};

const tooltip_faixa = (result, analises) => {

  const faixa = analises.find((item) => item.NOME === result.tabela);
  const valor_imovel = faixa.VAR_IMOVEL_MAX;
  const valor_renda = faixa.VAR_RENDA;
  return `Renda: ${numberFormat(valor_renda)}
Imóvel: ${numberFormat(valor_imovel)}`;
};

const tooltipParcela = (result, tab, parcelaMax) => {
  const comp = tab === 'SAC' ? result.compativel : result.compativelPrice;
  const parc = tab === 'SAC' ? result.sacInicial : result.price;
  if (comp) return `Renda compatível com a parcela inicial ${tab}`;
  return `Parcela máxima suportada pela renda: ${numberFormat(parcelaMax)}
Renda incompatível com a parcela${
    tab === 'SAC' ? ' inicial ' : ' '
  }${tab}: ${numberFormat(parc)}`;
};

const tooltipIcons = (result, icon, analises, moneyValues) => {
  const faixa = analises && analises.find((item) => item.NOME === result.tabela);
  const renda = faixa.VAR_RENDA;
  const imovel = faixa.VAR_IMOVEL_MAX;
  const evaluate = result.evaluate;
  const rendaText = `Renda ${
    result.tabela.includes('MCMV') ? 'limite' : 'mínima'
  }: ${numberFormat(renda)}
Renda informada: ${numberFormat(moneyValues.renda)}`;

  const tooltipRenda =
    evaluate && evaluate.renda
      ? `Compatível:
${rendaText}`
      : `Incompatível:
${rendaText}`;

  const imovelText = `Imóvel limite: ${numberFormat(imovel)}
Imóvel escolhido: ${numberFormat(moneyValues.imovel)}`;

  const tooltipImovel =
    evaluate && evaluate.imovel
      ? `Compatível:
${imovelText}`
      : `Incompatível:
${imovelText}`;

  const tooltipSubsidio =
    evaluate && evaluate.subsidio ? `Pode ter subsídio` : `Não possui subsídio`;

  if (icon === 'renda') return tooltipRenda;
  if (icon === 'imovel') return tooltipImovel;
  if (icon === 'subsidio') return tooltipSubsidio;
};

export {
  calcularMeses,
  classTr,
  // evaluateFaixas,
  evalAnalises,
  taxaPoupanca,
  tooltipIcons,
  tooltipParcela,
  tooltip_faixa,
};
