export default class Financiamentos {
  constructor(
    valorFinanciado,
    taxaJuros,
    n,
    seguro = 0.02,
    admin = 25,
    frequenciaJuros = 'mensal',
    maxParcSac = 420,
    maxParcPrice = 360,
  ) {
    this.prestacao = [];
    this.financiado = parseFloat(valorFinanciado);
    this.n = n;
    this.amortizacao = 0;
    this.totalJurosSac = 0;
    this.totalJurosPrice = 0;
    this.totalPagoSac = 0;
    this.totalPagoPrice = 0;
    if (frequenciaJuros === 'mensal') {
      this.taxa = parseFloat(taxaJuros) / 100;
    } else {
      this.taxa = Math.pow(1 + parseFloat(taxaJuros) / 100, 1 / 12) - 1;
    }
    this.admin = parseFloat(admin);
    this.seguro = parseFloat(seguro);
    this.nSac =
      parseInt(n) > parseInt(maxParcSac) ? parseInt(maxParcSac) : parseInt(n);
    this.nPrice =
      parseInt(n) > parseInt(maxParcPrice)
        ? parseInt(maxParcPrice)
        : parseInt(n);
    this.encargos = this.admin + this.financiado * (this.seguro / 100);
    this.prestacaoPrice = 0;
    this.prestacaoSac = 0;
    this.imovel = 0;
    this.entrada = 0.2;
  }

  mascaraToFloat() {
    let valorPresente = this.financiado.replace('.', '');
    valorPresente = valorPresente.replace(',', '.');
    this.financiado = valorPresente;
    let i = this.taxa.replace('.', '');
    i = i.replace(',', '.');
    this.taxa = i;
  }

  formatarMascara(valor) {
    let formato = {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    };
    return valor && valor.toLocaleString('pt-BR', formato);
  }

  calcularAmortizacao() {
    this.amortizacao = this.financiado / this.nSac;
    return this.amortizacao;
  }

  financiarSac() {
    this.calcularAmortizacao();
    for (let j = 0; j < this.nSac; j++) {
      let valorPrestacao =
        this.amortizacao + this.taxa * (this.financiado - j * this.amortizacao);
      this.prestacao.push(valorPrestacao);
    }
    this.prestacaoSac = this.prestacao[0];
  }

  calcularTotalPagoSac() {
    for (let j = 0; j < this.nSac; j++) {
      this.totalPagoSac += this.prestacao[j];
    }
    return this.totalPagoSac;
  }

  calcularTotalJurosSac() {
    if (this.totalPagoSac === 0) this.calcularTotalPagoSac();
    this.totalJurosSac = this.totalPagoSac - this.financiado;
    return this.totalJurosSac;
  }

  financiarPrice() {
    let valorPrestacao =
      (this.financiado * (Math.pow(1 + this.taxa, this.nPrice) * this.taxa)) /
      (Math.pow(1 + this.taxa, this.nPrice) - 1);
    this.prestacaoPrice = valorPrestacao;
  }

  financiarPriceImovel(prestacao) {
    this.financiado =
      (prestacao - this.admin) /
      ((Math.pow(1 + this.taxa, this.nPrice) * this.taxa) /
        (Math.pow(1 + this.taxa, this.nPrice) - 1) +
        this.seguro / 100);
    this.imovel = this.financiado / (1 - this.entrada);
    this.financiarPrice();
  }

  financiarSacImovel(prestacao) {
    this.financiado =
      (prestacao - this.admin) /
      (1 / this.nSac + this.taxa + this.seguro / 100);
    this.imovel = this.financiado / 0.8;
    this.financiarSac();
  }

  calcularTotalPagoPrice() {
    this.totalPagoPrice = this.prestacao[0] * this.nPrice;
    return this.totalPagoPrice;
  }

  calcularTotalJurosPrice() {
    if (this.totalPagoPrice === 0) this.calcularTotalPagoPrice();
    this.totalJurosPrice = this.totalPagoPrice - this.financiado;
    return this.totalJurosPrice;
  }

  calcular = () => {
    this.financiarSac();
    this.financiarPrice();
    this.calcularTotalPagoPrice();
    this.calcularTotalJurosPrice();
    this.calcularTotalPagoSac();
    this.calcularTotalJurosSac();
  };

  result() {
    this.calcular();
    return {
      encargos: this.formatarMascara(this.encargos),
      primeiraParcelaSac: this.formatarMascara(this.prestacao[0]),
      ultimaParcelaSac: this.formatarMascara(this.prestacao[this.nSac - 1]),
      totalJurosSac: this.formatarMascara(this.totalJurosSac),
      totalPagoSac: this.formatarMascara(this.totalPagoSac),
      prestacaoPrice: this.formatarMascara(this.prestacaoPrice),
      totalPagoPrice: this.formatarMascara(this.totalPagoPrice),
      totalJurosPrice: this.formatarMascara(this.totalJurosPrice),
      prestacoes: this.prestacao,
      sacInicial: this.prestacao[0],
      sacFinal: this.prestacao[this.nSac - 1],
      price: this.prestacaoPrice,
    };
  }
}
