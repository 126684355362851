import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/esm/Spinner';

import {
  PackageContext,
  CustomDropdown,
  GoogleCalendarLink,
  GoogleCalendarURL,
  IconsUI,
  SCR,
  ui,
  Loading,
} from '../../../packages/wookie';

import {
  CONF,
  API,
  END,
  GlobalContext,
  DragAndDrop,
  services,
  orgaosProtocolo,
} from '../../../config/imports';

export default function AndamentoModal({
  show,
  setShow,
  updated,
  proc_etapa_id,
  procs,
}) {
  const proc_codigo = procs.PROCESSO_CODIGO;
  const proc_c = procs.PROCESSO_C;

  const { theme } = useContext(PackageContext);
  const { a } = useContext(GlobalContext);
  const { prioridades, status, andamentos, documentos } = a;
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingProtocolo, setLoadingProtocolo] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [enviar, setEnviar] = useState('whats');

  const [compromisso, setCompromisso] = useState({
    title: '',
    description: '',
    nota: '',
    duration: 50,
    location: '',
    startTime: '',
    endTime: '',
    incluirCliente: false,
    incluirCorretor: false,
    participants: [],
    tipo: '',
  });

  const tipos = [
    {
      type: 'reuniao',
      label: 'Reunião',
    },
    {
      type: 'contrato',
      label: 'Assinatura de Contrato',
    },
    {
      type: 'forms',
      label: 'Assinatura de Formulários',
    },
    {
      type: 'outros',
      label: 'Compromisso',
    },
  ];

  const filteredtipos = (orgao) => {
    return orgaosProtocolo.find((item) => item.ID === orgao).tipos;
  };

  const initialStatus = {
    processo_etapa_id: proc_etapa_id,
    andamento_id: 1,
    visivel_cliente: proc_c,
    notificar_escritorio: proc_c,
    prioridade_id: 3,
    prazo: null,
    status_id: 1,
    title: null,
    andamento: null,
    nota: null,
  };

  const initialProtocolo = {
    processo_codigo: proc_codigo,
    status_id: 1,
    orgao: 'Cartório de Imóveis de Taubaté',
    orgaokey: 1,
    numero: null,
    digito: null,
    tipokey: 'registro',
    tipo: 'Registro',
    descricao: '',
  };

  const [andamento, setAndamento] = useState(initialStatus);
  const [protocolo, setProtocolo] = useState(initialProtocolo);

  const [files, setFiles] = useState([]);
  const [andamentoNome, setAndamentoNome] = useState('andamento');

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  const handleFiles = (f) => {
    setFiles(f);
  };

  const andamentosTypes = () => {
    const clienteButtons = [
      { ID: 1, NOME: 'andamento' },
      { ID: 2, NOME: 'documento' },
    ];

    const createButton = (item) => {
      return (
        <Button
          key={`modal_andamento_${proc_etapa_id}_item_${item.ID}`}
          className='fs12 fw500 my-1 me-2 wp100'
          variant={
            andamento.andamento_id === item.ID
              ? 'outline-success'
              : `outline-secondary`
          }
          size='sm'
          onClick={() => {
            setAndamento({ ...initialStatus, andamento_id: item.ID });
            setProtocolo(initialProtocolo);
            setAndamentoNome(item.NOME);
          }}
        >
          {item.NOME}
        </Button>
      );
    };

    const buttons = () => {
      if (proc_c) {
        return clienteButtons.map((item) => createButton(item));
      }
      return andamentos.map((item) => createButton(item));
    };

    return buttons();
  };

  const handleChange = (e) => {
    setAndamento({ ...andamento, [e.target.name]: e.target.value });
    if (e.target.name === 'title') {
      setCompromisso({ ...compromisso, title: e.target.value });
    }
    if (e.target.name === 'andamento') {
      setCompromisso({ ...compromisso, description: e.target.value });
    }
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    const updatedCompromisso = { ...compromisso, [name]: value };
    if (name === 'startTime' || name === 'duration') {
      const startTime = new Date(updatedCompromisso.startTime);
      const duration = parseInt(updatedCompromisso.duration, 10) || 0;
      const endTime = new Date(startTime.getTime() + duration * 60000);
      updatedCompromisso.endTime = new Date(
        endTime.getTime() - startTime.getTimezoneOffset() * 60000,
      )
        .toISOString()
        .slice(0, 16);
    }
    setCompromisso({
      ...compromisso,
      ...updatedCompromisso,
    });
  };

  const handlePrioridadeChange = (novaPrioridade) => {
    setAndamento({ ...andamento, prioridade_id: novaPrioridade });
  };

  const handleStatusChange = (novoStatus) => {
    setAndamento({ ...andamento, status_id: novoStatus });
    setProtocolo({ ...protocolo, status_id: novoStatus });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      setLoading(true);

      if (andamento.andamento_id === 2) {
        const uploadPromises = [];
        const formData = new FormData();
        for (let i = 0; i < files.length; i += 1) {
          const fileObject = files[i];
          formData.append('files', fileObject.file);
          formData.append('processo_etapa_id', proc_etapa_id);
          formData.append('documento_type', fileObject.description);
          formData.append('files_name', fileObject.file.name);
          formData.append('files_type', fileObject.file.type);
          formData.append('prioridade_id', andamento.prioridade_id);
          formData.append('visivel_cliente', andamento.visivel_cliente ? 1 : 0);
          formData.append(
            'notificar_escritorio',
            andamento.notificar_escritorio ? 1 : 0,
          );
          formData.append('andamento_id', andamento.andamento_id);

          const uploadPromise = API(END.and.create.doc, formData);
          uploadPromises.push(uploadPromise);
        }
        await Promise.all(uploadPromises);
      } else {
        await API(END.and.create.andamento, {
          ...andamento,
          visivel_cliente: andamento.visivel_cliente ? 1 : 0,
          notificar_escritorio: andamento.notificar_escritorio ? 1 : 0,
        });

        if (andamento.andamento_id === 22) {
          await API(END.prot.create, protocolo);
        }
      }
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
    setTimeout(() => {
      setLoading(false);
      setShow(false);
    }, 1000);
    updated();

    if (andamentoNome === 'agendamento') {
      const url = GoogleCalendarURL({
        title: compromisso.title,
        description: compromisso.description,
        location: compromisso.location,
        startTime: compromisso.startTime,
        endTime: compromisso.endTime,
        participants: compromisso.participants,
      });
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.click();
    }

    if (andamentoNome === 'mensagem') {
      if (enviar === 'whats') {
        const url = SCR.Utils.sendMessageWhatsapp(
          procs.CLIENTE_TELEFONE,
          andamento.andamento,
        );
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      } else {
        const emailRef = `Proc. ${proc_codigo}`;
        const emailBody = SCR.Utils.formatEmailBody(
          procs.CLIENTE_NOME,
          andamento.andamento,
          emailRef,
          CONF.tenant,
        );
        const emailSubject = `Atualização em processo de financiamento imobiliário`;
        const emailTo = procs.CLIENTE_EMAIL;

        try {
          const logo = await API(END.tenants.logo_link, {
            brand: CONF.brand,
          });
          const logo_link = logo.data;

          const formData = new FormData();
          formData.append('email', emailTo);
          formData.append('subject', emailSubject);
          formData.append('content', emailBody);
          formData.append('logo_link', logo_link);
          formData.append('brand', CONF.brand);

          await API(END.bwAPI.text, formData);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        fullscreen={windowWidth < 768 ? true : false}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        data-bs-theme={theme}
        className='cstModal'
        onHide={setShow}
      >
        <Modal.Header closeButton>
          <div className='d-flex flex-column'>
            <Modal.Title>
              Adicionar <b>{andamentoNome}</b>
            </Modal.Title>
            <p className='mt-3 mb-1'>
              Selecione o tipo de andamento que deseja adicionar:
            </p>
            <div className='d-flex flex-row flex-wrap'>{andamentosTypes()}</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='d-flex flex-row flex-wrap justify-content-start align-items-center'>
              {!proc_c && (
                <>
                  <IconsUI
                    info={
                      andamento.notificar_escritorio
                        ? ui.misc.bell.on
                        : ui.misc.bell.off
                    }
                    clsnm={`svg14 mx-2 mousePointerHover ${
                      andamento.notificar_escritorio ? 'cGreen' : 'cGray500'
                    }`}
                    tooltip={
                      andamento.notificar_escritorio
                        ? 'Notificar Escritório'
                        : 'Não Notificar Escritório'
                    }
                    click={() => {
                      const previous = andamento.notificar_escritorio;
                      setAndamento({
                        ...andamento,
                        notificar_escritorio: !previous,
                      });
                    }}
                  />

                  <IconsUI
                    info={
                      andamento.visivel_cliente
                        ? ui.misc.eye.on
                        : ui.misc.eye.off
                    }
                    clsnm={`svg14 mx-2 mousePointerHover ${
                      andamento.visivel_cliente ? 'cGreen' : 'cGray500'
                    }`}
                    tooltip={
                      andamento.visivel_cliente
                        ? 'Visível ao Cliente'
                        : 'Não Visível ao Cliente'
                    }
                    click={() => {
                      const previous = andamento.visivel_cliente;
                      setAndamento({
                        ...andamento,
                        visivel_cliente: !previous,
                      });
                    }}
                  />
                </>
              )}

              <CustomDropdown
                arrayObj={prioridades}
                defaultValue={andamento.prioridade_id}
                value={andamento.prioridade_id}
                changeCallApi={handlePrioridadeChange}
                identification={proc_etapa_id}
                showLabel={'Prioridade'}
                customClass='dropStart'
              />
              {andamentoNome === 'tarefa' || andamentoNome === 'protocolo' ? (
                <>
                  <CustomDropdown
                    arrayObj={status}
                    defaultValue={andamento.status_id}
                    value={andamento.status_id}
                    changeCallApi={handleStatusChange}
                    identification={proc_etapa_id}
                    showLabel={'Status'}
                  />
                  <Form.Label
                    className='fs08 my-0 ms-1 me-2'
                    htmlFor='prazo'
                  >
                    prazo
                  </Form.Label>
                  <Form.Control
                    type='date'
                    className='fs10 wp125 px-1 faCenter'
                    size='sm'
                    id={`new_prazo_task_${proc_etapa_id}`}
                    name='prazo'
                    value={andamento.prazo}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </>
              ) : null}
            </div>

            {andamentoNome === 'agendamento' && (
              <Form.Group
                className='my-3'
                id={`new_agenda_${proc_etapa_id}`}
              >
                {tipos.map((tipo) => (
                  <Form.Check
                    key={tipo.type}
                    className='me-3 fs08'
                    type='radio'
                    name='tipo'
                    label={tipo.label}
                    id={`set_tipo_${tipo.type}_${proc_etapa_id}`}
                    onClick={() =>
                      setCompromisso({ ...compromisso, tipo: tipo.type })
                    }
                  />
                ))}
              </Form.Group>
            )}

            {andamentoNome !== 'documento' ? (
              <Form.Control
                required
                type='text'
                id='title'
                name='title'
                value={andamento.title}
                placeholder='Título'
                className='form-control w100 my-1  fs12 textContrast'
                onChange={(e) => handleChange(e)}
              />
            ) : null}

            {andamentoNome === 'andamento' ||
            andamentoNome === 'tarefa' ||
            andamentoNome === 'agendamento' ||
            andamentoNome === 'mensagem' ? (
              <>
                <Form.Group
                  className='my-3'
                  id={`new_andamento_${proc_etapa_id}`}
                >
                  <Form.Label htmlFor='andamento'>
                    <IconsUI
                      info={ui.misc.pen}
                      clsnm='svg10 me-1 cGray500'
                    />
                    <span className='ffSoft fs12 textContrast me-2'>
                      {andamentoNome}
                    </span>
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    id='andamento'
                    name='andamento'
                    value={andamento.andamento}
                    className='form-control w100 mb-2  fs12 textContrast'
                    rows={3}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Form.Group>

                {andamentoNome === 'mensagem' && (
                  <div className='d-flex flex-row justify-content-end f08'>
                    <Form.Check
                      checked={enviar === 'mail'}
                      className='ms-3 f08'
                      type='radio'
                      name='enviar'
                      label={`E-mail`}
                      id={`enviar_mail_${proc_etapa_id}`}
                      onChange={(e) => {
                        e.target.checked
                          ? setEnviar('mail')
                          : setEnviar('whats');
                      }}
                    />

                    <Form.Check
                      checked={enviar === 'whats'}
                      className='ms-3 f08'
                      type='radio'
                      name='enviar'
                      label={`WhatsApp`}
                      id={`enviar_whats_${proc_etapa_id}`}
                      onChange={(e) => {
                        e.target.checked
                          ? setEnviar('whats')
                          : setEnviar('mail');
                      }}
                    />
                  </div>
                )}

                {(!proc_c && andamentoNome === 'andamento') ||
                  (andamentoNome === 'agendamento' && (
                    <Form.Group
                      className='my-3'
                      id={`new_nota_${proc_etapa_id}`}
                    >
                      <Form.Label>
                        <div className='d-flex flex-row justify-content-around align-items-center'>
                          <IconsUI
                            info={ui.misc.lock}
                            clsnm='svg10 me-1 cGray500'
                          />
                          <span className='ffSoft fs12 textContrast me-2'>
                            nota Interna
                          </span>
                        </div>
                      </Form.Label>
                      <Form.Control
                        as='textarea'
                        id='nota'
                        name='nota'
                        value={andamento.nota}
                        className='form-control w100 mb-2 fs12'
                        placeholder='nota Interna nunca será visível ao cliente ou corretor'
                        rows={2}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </Form.Group>
                  ))}
              </>
            ) : null}

            {andamentoNome === 'documento' ? (
              <DragAndDrop
                onFilesSelected={handleFiles}
                filesSt={files}
                docs={documentos}
              />
            ) : null}

            {andamentoNome === 'protocolo' ? (
              <div className='d-flex flex-column flex-wrap'>
                <CustomDropdown
                  arrayObj={orgaosProtocolo}
                  changeCallApi={(novaorgao) =>
                    setProtocolo({
                      ...protocolo,
                      orgaokey: novaorgao,
                      orgao: orgaosProtocolo[novaorgao].NOME,
                      tipo: initialProtocolo.tipo,
                    })
                  }
                  identification={proc_etapa_id}
                  showLabel={'Órgão: '}
                  customClass='droporgaos'
                />
                <div className='d-flex flex-row flex-wrap'>
                  <Form.Group
                    className='my-1 me-1'
                    id={`new_protocolo_numero_${proc_etapa_id}`}
                  >
                    <Form.Label
                      className='m-0 fs10 textContrastColor ps-1'
                      htmlFor='numero'
                    >
                      Protocolo
                    </Form.Label>
                    <Form.Control
                      required
                      type='text'
                      id='numero'
                      name='numero'
                      value={protocolo.numero}
                      placeholder='Número'
                      className='form-control wp100 fs12 textContrast'
                      onChange={(e) => {
                        setProtocolo({ ...protocolo, numero: e.target.value });
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    className='my-1 me-1'
                    id={`new_protocolo_digito_${proc_etapa_id}`}
                  >
                    <Form.Label
                      className='m-0 fs10 textContrastColor ps-1'
                      htmlFor='digito'
                    >
                      Dígito
                    </Form.Label>

                    <Form.Control
                      type='text'
                      id='digito'
                      name='digito'
                      value={protocolo.digito}
                      placeholder='Dígito'
                      className='form-control wp60 fs12 textContrast'
                      onChange={(e) => {
                        setProtocolo({ ...protocolo, digito: e.target.value });
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    className='my-1 me-1'
                    id={`new_protocolo_tipo_${proc_etapa_id}`}
                  >
                    <Form.Label
                      className='m-0 fs10 textContrastColor ps-1'
                      htmlFor='tipo'
                    >
                      tipo
                    </Form.Label>
                    <div className='gorow align-items-center'>
                      <Form.Select
                        id='tipo'
                        required
                        name='tipo'
                        value={protocolo.tipokey}
                        className='form-select fs12 textContrast'
                        onChange={(e) => {
                          setProtocolo({
                            ...protocolo,
                            tipokey: e.target.value,
                            tipo: e.target.options[e.target.selectedIndex].text,
                          });
                        }}
                      >
                        <option value=''>Selecione</option>
                        {protocolo.orgao &&
                          Object.keys(filteredtipos(protocolo.orgaokey)).map(
                            (item) => (
                              <option
                                key={
                                  orgaosProtocolo[protocolo.orgaokey].tipos[
                                    item
                                  ]
                                }
                                value={item}
                                name={
                                  orgaosProtocolo[protocolo.orgaokey].tipos[
                                    item
                                  ]
                                }
                              >
                                {
                                  orgaosProtocolo[protocolo.orgaokey].tipos[
                                    item
                                  ]
                                }
                              </option>
                            ),
                          )}
                      </Form.Select>
                      {loadingProtocolo ? (
                        ''
                      ) : (
                        <IconsUI
                          info={ui.action.magnifier}
                          clsnm='svg18 cLink mx-2 mousePointerHover'
                          tooltip='Pesquisar Protocolo'
                          click={async () => {
                            setLoadingProtocolo(true);
                            setErrorMessage('');
                            if (protocolo.numero === '') {
                              setErrorMessage('Informe o número do protocolo');
                              return;
                            }

                            if (protocolo.orgaokey === 0) {
                              setErrorMessage('Selecione o órgão do protocolo');
                              return;
                            }

                            if (protocolo.orgaokey === 1) {
                              try {
                                const dataProt = await services.readCRI(
                                  [
                                    {
                                      key: 'protocolo',
                                      value: protocolo.numero,
                                    },
                                    {
                                      key: 'digitoVerificador',
                                      value: protocolo.digito,
                                    },
                                  ],
                                  protocolo.tipokey,
                                );

                                if (dataProt) {
                                  const atual = dataProt.situacaoAtual;
                                  let status;
                                  switch (atual) {
                                    case 'Em análise':
                                      status = 1;
                                      break;
                                    case 'Em exigência':
                                      status = 3;
                                      break;
                                    case 'Concluído':
                                    case 'Entregue':
                                      status = 4;
                                      break;
                                    default:
                                      status = 2;
                                      break;
                                  }

                                  handlePrioridadeChange(status);

                                  const andamentoProtocolo = `Protocolo: ${protocolo.numero}-${protocolo.digito} - ${protocolo.tipo}
Abertura:  ${dataProt.protocolo.dataAbertura}
Natureza: ${dataProt.protocolo.natureza}
Situação: ${dataProt.situacaoAtual}
Outorgado: ${dataProt.protocolo.outorgado}
Outorgante: ${dataProt.protocolo.outorgante}`;
                                  setProtocolo({
                                    ...protocolo,
                                    descricao: andamentoProtocolo,
                                    status_id: status,
                                  });
                                  setAndamento({
                                    ...andamento,
                                    prazo: SCR.Utils.convertDate(
                                      dataProt.dataPrevisao,
                                    ),
                                    status_id: status,
                                    title: `Protocolo: ${protocolo.numero}-${protocolo.digito} [${dataProt.protocolo.natureza}]`,
                                    andamento: andamentoProtocolo,
                                  });
                                }
                              } catch (error) {
                                console.error(error);
                                setErrorMessage(
                                  'Ooops... Ainda não sei consultar esse protocolo!',
                                );
                              }
                            } else {
                              setErrorMessage(
                                'Ooops... Ainda não sei consultar esse protocolo!',
                              );
                            }
                            setLoadingProtocolo(false);
                          }}
                        />
                      )}
                    </div>
                  </Form.Group>
                </div>
                {loadingProtocolo ? <Loading /> : ''}
                <p className='textContrastColorRed fs12 fw500'>
                  {errorMessage}
                </p>
                <Form.Group
                  className='my-1 me-1 w100'
                  id={`new_protocolo_descricao_${proc_etapa_id}`}
                >
                  <Form.Label
                    className='m-0 fs10 textContrastColor ps-1'
                    htmlFor='descricao'
                  >
                    Detalhes
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={5}
                    id='descricao'
                    name='descricao'
                    value={protocolo.descricao}
                    placeholder='Descrição'
                    className='form-control fs12 textContrast'
                    onChange={(e) => {
                      setProtocolo({ ...protocolo, descricao: e.target.value });
                      setAndamento({ ...andamento, andamento: e.target.value });
                    }}
                  />
                </Form.Group>
              </div>
            ) : null}

            {andamentoNome === 'agendamento' && (
              <>
                <Form.Group
                  className='my-1 gorow align-items-center'
                  id={`new_agenda_location_${proc_etapa_id}`}
                >
                  <Form.Label
                    htmlFor='location'
                    className='wp75 m-0'
                  >
                    <IconsUI
                      info={ui.misc.location}
                      clsnm='svg10 me-1 cGray500'
                      sideText='Local'
                      sideTextClass='ffSoft fs12 textContrast me-2'
                    />
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    id='location'
                    name='location'
                    value={compromisso.location}
                    placeholder='Local'
                    className='form-control faLeft  my-1 w90 fs12 textContrast'
                    onChange={(e) =>
                      setCompromisso({
                        ...compromisso,
                        location: e.target.value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group
                  className='my-1 gorow align-items-center'
                  id={`new_agenda_startTime_${proc_etapa_id}`}
                >
                  <Form.Label
                    htmlFor='startTime'
                    className='wp75 m-0'
                  >
                    <IconsUI
                      info={ui.action.start}
                      clsnm='svg10 me-1 cGray500'
                      sideText='Início'
                      sideTextClass='ffSoft fs12 textContrast me-2'
                    />
                  </Form.Label>
                  <Form.Control
                    type='datetime-local'
                    id='startTime'
                    name='startTime'
                    size='sm'
                    value={compromisso.startTime}
                    className='form-control my-1 wp175 fs12 textContrast'
                    onChange={(e) => handleTimeChange(e)}
                  />
                </Form.Group>

                <Form.Group
                  className='my-1 gorow align-items-center'
                  id={`new_agenda_duration_${proc_etapa_id}`}
                >
                  <Form.Label
                    htmlFor='duration'
                    className='wp75 m-0'
                  >
                    <IconsUI
                      info={ui.status.waiting}
                      clsnm='svg10 me-1 cGray500'
                      sideText='Duração'
                      sideTextClass='ffSoft fs12 textContrast me-2'
                    />
                  </Form.Label>
                  <Form.Control
                    type='number'
                    size='sm'
                    id='duration'
                    name='duration'
                    value={compromisso.duration}
                    className='form-control my-1 wp60 fs12 textContrast'
                    onChange={(e) => handleTimeChange(e)}
                  />
                  <span className='fs12 textContrast ms-2'>minutos</span>
                </Form.Group>

                <Form.Group
                  className='my-1 gorow align-items-center'
                  id={`new_agenda_endTime_${proc_etapa_id}`}
                >
                  <Form.Label
                    htmlFor='endTime'
                    className='wp75 m-0'
                  >
                    <IconsUI
                      info={ui.status.done}
                      clsnm='svg10 me-1 cGray500'
                      sideText='Término'
                      sideTextClass='ffSoft fs12 textContrast me-2'
                    />
                  </Form.Label>
                  <Form.Control
                    type='datetime-local'
                    size='sm'
                    id='endTime'
                    name='endTime'
                    value={compromisso.endTime}
                    className='form-control my-1 wp175 fs12 textContrast'
                    onChange={(e) => handleTimeChange(e)}
                  />
                </Form.Group>

                <div className='my-2'>
                  <Form.Label className='my-1 gocol500 align-items-center'>
                    <span className='my-1 fs10 me-3'>
                      Adicionar participantes
                    </span>

                    <div className='mt-1 mb-0 gocol500  align-items-center'>
                      <Form.Check
                        defaultChecked={compromisso.incluirCliente}
                        className='me-3 fs10'
                        type='checkbox'
                        name='tipo'
                        label={`Cliente`}
                        id={`set_incluirCliente_${proc_etapa_id}`}
                        onClick={(e) => {
                          let states = compromisso.participants.slice();
                          if (e.target.checked) {
                            states.push(procs.CLIENTE_EMAIL);
                          } else {
                            states = states.filter(
                              (mail) => mail !== procs.CLIENTE_EMAIL,
                            );
                          }
                          setCompromisso({
                            ...compromisso,
                            participants: states,
                            incluirCliente: e.target.checked,
                          });
                        }}
                      />

                      {procs.CORRETOR_EMAIL ? (
                        <Form.Check
                          defaultChecked={compromisso.incluirCorretor}
                          className='me-3 fs10'
                          type='checkbox'
                          name='tipo'
                          label={`Corretor`}
                          id={`set_incluirCorretor_${proc_etapa_id}`}
                          onClick={(e) => {
                            let states = compromisso.participants.slice();
                            if (e.target.checked) {
                              states.push(procs.CORRETOR_EMAIL);
                            } else {
                              states = states.filter(
                                (mail) => mail !== procs.CORRETOR_EMAIL,
                              );
                            }
                            setCompromisso({
                              ...compromisso,
                              participants: states,
                              incluirCliente: e.target.checked,
                            });
                          }}
                        />
                      ) : (
                        <span className='fs10'>Não há corretor cadastrado</span>
                      )}
                    </div>
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={1}
                    className='fs10'
                    value={compromisso.participants}
                    id={`new_agendamento_participantes_${proc_etapa_id}`}
                    onChange={(e) => {
                      const emails = e.target.value.split(',');
                      setCompromisso({ ...compromisso, participants: emails });
                    }}
                  />
                  <span className='mt-0 mb-2 fs08 textMenu'>
                    Adicione e-mails separados por vírgula
                  </span>
                </div>
              </>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner
              animation='border'
              variant='success'
              size='sm'
            />
          ) : (
            <>
              <Button
                size='sm'
                variant={theme}
                onClick={setShow}
              >
                Sair
              </Button>
              <Button
                size='sm'
                variant='primary'
                onClick={handleSave}
              >
                Adicionar {andamentoNome}
              </Button>
              {andamentoNome === 'agendamento' && (
                <GoogleCalendarLink
                  title={compromisso.title}
                  description={compromisso.description}
                  location={compromisso.location}
                  startTime={compromisso.startTime.replace(/[-:]/g, '')}
                  endTime={compromisso.endTime.replace(/[-:]/g, '')}
                  participants={compromisso.participants}
                  idLink={`set_googleCalendar_${proc_etapa_id}`}
                />
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
