import React, { useEffect } from 'react';

import './packages/wookie/assets/css';
import './assets/css/_root_.css';
import './assets/css/verified.css';

import { SEO, CONF, GlobalProvider, AppRoutes } from './config/imports';

import { AuthProvider, PackageProvider } from './packages/wookie';

export default function App() {
  useEffect(() => {
    SEO.defineMeta();
    SEO.defineSchema();

    document.title = `${CONF.tenant} ${CONF.headline}`;

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
    const savedTheme = localStorage.getItem('theme') || mediaQuery;
    if (savedTheme) {
      document.documentElement.className = savedTheme;
    }
  }, []);

  return (
    <AuthProvider>
      <PackageProvider>
        <GlobalProvider>
          <AppRoutes />
        </GlobalProvider>
      </PackageProvider>
    </AuthProvider>
  );
}
