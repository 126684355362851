import { useContext } from 'react';
import { API, END, GlobalContext } from '../config/imports';

export function Authorize(required) {
  const { store } = useContext(GlobalContext);
    const { role_id } = store();
    if (role_id <= required) {
      return true;
    }
    return false;

}

async function createLead({ name, email, phone, message }) {
  const data = {
    name,
    email,
    phone,
    message,
  };

  const response = await API(END.lead.create, data);
  return response.data;
}

export { createLead };

export async function recover(email, cpf) {
  const response = await API(END.auth.recover, {
    email,
    cpf,
  });

  return response;
}

export async function reset(email, cpf, password, otp) {
  const response = await API(END.auth.reset, {
    email,
    cpf,
    password,
    otp,
  });

  return response;
}

function createURL(object = [{ key: '', value: '' }]) {
  let url = '';
  object.forEach((element) => {
    url += `${element.key}=${element.value}&`;
  });
  return url;
}

export async function readCRI(params, consulta = 'registro') {
  const urlparams = createURL(params);
  const res = await API(END.cri[consulta], { urlparams });
  return res.data;
}
