import React from 'react';
import { API, END } from '../../../config/imports';

export default function AdminCustom() {
  async function atualizarIndices() {
    console.log('atualizarIndices');
    try {
      await API(END.indices.api.ipca, {});
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <>
      <h4 className='hc-section-title'>Personalizar</h4>
      <div>Linhas de Financiamento</div>
      <div>Tabelas</div>
      <div>Taxas</div>
      <div>Documentos</div>
      <div>Etapas</div>
      <div>Bancos</div>

      <div className='my-5 ffSoft textContrastColor fw600'>
        <p className='mb-3'>Atualizar Indices</p>

        <button
          className='btn btn-primary'
          onClick={atualizarIndices}
        >
          Atualizar
        </button>
      </div>
    </>
  );
}
