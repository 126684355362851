import { useContext, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { GlobalContext, PKG, API, END } from '../../config/imports';

import '../../assets/css/app/section.tasks.css';

export default function Tasks() {
  const { checkLogin, a } = useContext(GlobalContext);
  const { theme } = useContext(PKG.PackageContext);
  const { status, prioridades } = a;
  const { createLinkWhatsapp } = PKG.SCR.Utils;
  const [tasks, setTasks] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [show, setShow] = useState(false);

  const [prioridade, setPrioridade] = useState(3);
  const [prazo, setPrazo] = useState(new Date().toISOString().split('T')[0]);
  const [title, setTitle] = useState('');
  const [andamento, setAndamento] = useState('');
  const [stats, setStatus] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePrioridade = (novaPrioridade) => {
    setPrioridade(novaPrioridade);
  };

  const handleStatus = (novoStatus) => {
    setStatus(novoStatus);
  };

  const handleSave = async () => {
    checkLogin();
    setLoading(true);
    try {
      setLoading(true);
      await API(END.tasks.create, {
        prioridade_id: prioridade,
        status_id: stats,
        prazo: prazo,
        title: title,
        andamento: andamento,
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    setReload(!reload);

    setTimeout(() => {
      setLoading(false);
      setShow(false);
    }, 1000);
  };

  const checkPrazo = (prazo, stats) => {
    if (stats !== 4) {
      const today = new Date();
      const prazoDate = new Date(prazo);
      if (prazoDate < today) {
        return (
          <Badge
            bg='danger'
            className='fw300 fs10 me-1 p-2'
          >
            Atrasado
          </Badge>
        );
      } else {
        const timeDifference = prazoDate.getTime() - today.getTime();
        const oneDay = 1000 * 60 * 60 * 24;
        if (timeDifference > oneDay) {
          return '';
        } else {
          return (
            <Badge
              bg='warning'
              className='fw300 fs10 me-1 p-2'
            >
              Amanhã
            </Badge>
          );
        }
      }
    } else {
      return '';
    }
  };

  const filterTasks = (terms) => {
    if (terms.length > 2) {
      const filteredArray = tasks.filter(
        ({
          TITLE,
          ANDAMENTO,
          PRAZO,
          CLIENTE_NOME,
          CLIENTE_TELEFONE,
          CLIENTE_EMAIL,
          CORRESPONDENTE_NOME,
          CORRETOR_NOME,
          PRIORIDADE,
          STATUS,
        }) =>
          TITLE.toLowerCase().includes(terms.toLowerCase()) ||
          ANDAMENTO.toLowerCase().includes(terms.toLowerCase()) ||
          PRAZO.toLowerCase().includes(terms.toLowerCase()) ||
          CLIENTE_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          CLIENTE_TELEFONE.toLowerCase().includes(terms.toLowerCase()) ||
          CLIENTE_EMAIL.toLowerCase().includes(terms.toLowerCase()) ||
          CORRESPONDENTE_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          CORRETOR_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          PRIORIDADE.toLowerCase().includes(terms.toLowerCase()) ||
          STATUS.toLowerCase().includes(terms.toLowerCase()),
      );
      setFiltered(filteredArray);
    } else {
      setFiltered(tasks);
    }
  };

  const updateStatus = async (taskCode, status_id, tipo) => {
    setLoading(true);
    const endpoint = tipo === 'TASK' ? END.tasks.update.status : END.and.update.status;
    try {
      await API(endpoint, {
        codigo: taskCode,
        status_id,
      });
      setReload(!reload);
    } catch (error) {
      console.log(error.response.data.message);
    }
    setLoading(false);
  };

  const updatePrazo = async (taskCode, prazo, tipo) => {
    setLoading(true);
    const endpoint = tipo === 'TASK' ? END.tasks.update.prazo : END.and.update.prazo;
    try {
      await API(endpoint, {
        codigo: taskCode,
        prazo,
      });
      setReload(!reload);
    } catch (error) {
      console.log(error.response.data.message);
    }
    setLoading(false);
  };

  const updatePrioridade = async (taskCode, prioridade_id, tipo) => {
    setLoading(true);
    const endpoint =
      tipo === 'TASK' ? END.tasks.update.prioridade : END.and.update.prioridade;
    try {
      await API(endpoint, {
        codigo: taskCode,
        prioridade_id,
      });
      setReload(!reload);
    } catch (error) {
      console.log(error.response.data.message);
    }
    setLoading(false);
  };

  const updateTitle = async (taskCode, title, tipo) => {
    setLoading(true);
    console.log(tipo);
    const endpoint = tipo === 'TASK' ? END.tasks.update.title : END.and.update.title;
    try {
      await API(endpoint, {
        codigo: taskCode,
        title,
      });
      setReload(!reload);
    } catch (error) {
      console.log(error.response.data.message);
    }
    setLoading(false);
  };

  const updateAndamento = async (taskCode, andamento, tipo) => {
    setLoading(true);
    const endpoint =
      tipo === 'TASK' ? END.tasks.update.andamento : END.and.update.andamento;
    try {
      await API(endpoint, {
        codigo: taskCode,
        andamento,
      });
      setReload(!reload);
    } catch (error) {
      console.log(error.response.data.message);
    }
    setLoading(false);
  };

  const deleteTask = async (taskCode, tipo) => {
    const endpoint = tipo === 'TASK' ? END.tasks.delete : END.and.delete;
    try {
      await API(endpoint, {
        codigo: taskCode,
      });
      setReload(!reload);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const labelTask = (task) => {
    switch (task.TASKTYPE) {
      case 'TASK':
        return 'Tarefa';
      case 'PROCESSO':
        return 'Andamento de Processo';
      case 'PROTOCOLO':
        return 'Protocolo';
      default:
        return 'Tarefa';
    }
  };

  useEffect(() => {
    const getTasks = async () => {
      setLoading(true);
      try {
        const response = await API(END.tasks.read.user, {});
        const data = response.data;
        setTasks(data);
        setFiltered(data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    checkLogin();
    getTasks();
  }, [checkLogin, reload]);

  const handleDragStart = (e, taskCode, taskTipo) => {
    e.dataTransfer.setData('taskCode', taskCode);
    e.dataTransfer.setData('tipo', taskTipo);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, status_id) => {
    e.preventDefault();
    const taskCode = e.dataTransfer.getData('taskCode');
    const tipo = e.dataTransfer.getData('tipo');
    updateStatus(taskCode, status_id, tipo);
  };

  if (loading) return <PKG.Loading />;

  return (
    <div className='setWidth mt-5'
    >
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        data-bs-theme={theme}
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='ffSoft'>Nova Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            required
            type='text'
            id='title'
            name='title'
            value={title}
            placeholder='Título'
            className='form-control input-cur w100 my-1'
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <div className='gorow align-items-center'>
            <div className='gorow align-items-center'>
              <Form.Group className='mb-3'>
                <Form.Label
                  className='ffSoft fs10 mb-0'
                  htmlFor='prazo'
                >
                  Prazo
                </Form.Label>
                <Form.Control
                  type='date'
                  id='prazo'
                  name='prazo'
                  className=''
                  size='sm'
                  value={prazo}
                  onChange={(e) => {
                    setPrazo(e.target.value);
                  }}
                />
              </Form.Group>
            </div>
            <Form.Group className='mb-3'>
              <Form.Label className='ffSoft fs10 mb-0 ms-3 cGray400'>
                Prioridade
              </Form.Label>
              <PKG.CustomDropdown
                arrayObj={prioridades}
                defaultValue={prioridade}
                value={prioridade}
                changeCallApi={handlePrioridade}
                showLabel={true}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label className='ffSoft fs10 mb-0 ms-3 cGray400'>
                Status
              </Form.Label>
              <PKG.CustomDropdown
                arrayObj={status}
                defaultValue={stats}
                value={stats}
                changeCallApi={handleStatus}
                showLabel={true}
              />
            </Form.Group>
          </div>
          <div>
            <Form.Label>
              <PKG.IconsUI
                info={PKG.ui.social.calendar.reg}
                clsnm='svg16 cGreen me-2'
                sideText='Task'
              />
            </Form.Label>
            <Form.Control
              as='textarea'
              required
              rows={3}
              value={andamento}
              id='andamento'
              name='andamento'
              onChange={(e) => {
                setAndamento(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success'
            onClick={handleSave}
          >
            Adicionar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className='mt-2 mb-5 gorow'>
        <span className='me-2 ffSoft'>Filtrar por</span>
        <input
          type='text'
          id='filter-tasks'
          className='filter-table'
          onChange={(e) => filterTasks(e.target.value)}
          pattern='.{3,20}'
        />
        <PKG.IconsUI
          info={PKG.ui.action.add.circle}
          click={handleShow}
          clsnm='ms-2 svg16 cLink hovGreen'
          tooltip='Adicionar task'
          sideDivClass='hovGreen mousePointerHover'
          sideText='Adicionar task'
          sideTextClass='ms-1 fs10'
        />
      </div>

      <div className='taskStatus'>
        {status &&
          status.map((st) => (
            <div
              key={`task_div_status_${st.ID}`}
              className='taskStatusCol hpMin200'
              onTouchEnd={(e) => handleDrop(e, st.ID)}
              onTouchMove={(e) => handleDragOver(e)}
              onDragOver={(e) => handleDragOver(e)}
              onDrop={(e) => handleDrop(e, st.ID)}
            >
              <PKG.IconsUI
                info={PKG.ui.status[st.NOME]}
                clsnm='me-1 mb-2 svg14 textContrastColor'
                sideText={st.NOME}
                sideTextClass='ms-1 fs10 ffSoft fw600 textContrastColor'
              />

              <div
                key={`indiv_${st.ID}`}
                className='gocol fs10 wpMin200'
              >
                {filtered && filtered.map((task) => {
                  if (task.STATUS_ID === parseInt(st.ID)) {
                    return (
                      <div
                        key={`filter_task_${task.CODIGO}`}
                        className={`px-3 py-1 mt-2 border rounded ${
                          task.STATUS_ID !== 4
                            ? `bor4 border-${task.PRIORIDADE_COLOR}`
                            : (theme === 'light' ? 'bor1 border-light' : 'bor1 border-secondary')
                        }`}
                        draggable
                        onTouchStart={(e) =>
                          handleDragStart(e, task.CODIGO, task.TASKTYPE)
                        }
                        onDragStart={(e) =>
                          handleDragStart(e, task.CODIGO, task.TASKTYPE)
                        }
                      >
                        <div className='gorow align-items-center justify-content-end ffSoft fw400 py-1 fs10'>
                          {labelTask(task)}
                        </div>
                        <div className='d-flex flex-row align-items-center'>
                          {checkPrazo(task.PRAZO, task.STATUS_ID)}
                          <DatePicker
                            selected={new Date(task.PRAZO)}
                            onChange={(date) =>
                              updatePrazo(
                                task.CODIGO,
                                date.toISOString().split('T')[0],
                                task.TASKTYPE,
                              )
                            }
                            dateFormat='dd/MM/yyyy'
                            className='form-control wp90 fs12 faCenter p-1'
                          />

                          <PKG.IconsUI
                            info={PKG.ui.action.delete.trash}
                            click={() => deleteTask(task.CODIGO, task.TASKTYPE)}
                            clsnm='ms-1 svg14 cLink hovGreen'
                            tooltip='Deletar task'
                          />

                          <PKG.CustomDropdown
                            arrayObj={prioridades}
                            defaultValue={task.PRIORIDADE_ID}
                            value={task.PRIORIDADE_ID}
                            changeCallApi={(id) =>
                              updatePrioridade(task.CODIGO, id, task.TASKTYPE)
                            }
                            identification={`prioridade_${task.CODIGO}_${task.PROCESSO_ETAPA_ID}`}
                            showName={true}
                            size={'sm'}
                            svgClass='svgStrokeWhite'
                          />
                        </div>
                        <PKG.IconsUI
                          info={PKG.ui.action.edit}
                          clsnm='svg12 textMenuSoft hovGreen mousePointerHover me-2 my-2'
                          tooltip='Editar título'
                          sideText={task.TITLE}
                          sideTextClass='fs14 fw600'
                          click={() => {
                            document
                              .getElementById(`gr_edit_title_${task.CODIGO}`)
                              .classList.remove('divHide');
                          }}
                        />
                        <Form.Group
                          className='my-2 gorow align-items-center  divHide'
                          id={`gr_edit_title_${task.CODIGO}`}
                        >
                          <Form.Control
                            type='text'
                            className='form-control input-cur me-1'
                            size='sm'
                            id={`edit_title_${task.CODIGO}`}
                          />
                          <PKG.IconsUI
                            info={PKG.ui.action.save}
                            clsnm='svg18 textContrastColor hovGreen mousePointerHover me-1'
                            tooltip='Salvar título'
                            click={() => {
                              const val = document.getElementById(
                                `edit_title_${task.CODIGO}`,
                              ).value;
                              updateTitle(task.CODIGO, val, task.TASKTYPE);
                            }}
                          />
                          <PKG.IconsUI
                            info={PKG.ui.action.close}
                            clsnm='svg18 cRed hovGreen mousePointerHover'
                            tooltip='Fechar'
                            click={() => {
                              document
                                .getElementById(`gr_edit_title_${task.CODIGO}`)
                                .classList.add('divHide');
                            }}
                          />
                        </Form.Group>

                        <PKG.IconsUI
                          info={PKG.ui.action.edit}
                          clsnm='svg12 textMenuSoft hovGreen mousePointerHover me-2 mb-1'
                          tooltip='Editar tarefa'
                          sideText={task.ANDAMENTO}
                          sideTextClass='fs10 fw400'
                          click={() => {
                            document
                              .getElementById(
                                `gr_edit_andamento_${task.CODIGO}`,
                              )
                              .classList.remove('divHide');
                          }}
                        />
                        <Form.Group
                          className='my-2 gorow align-items-center  divHide'
                          id={`gr_edit_andamento_${task.CODIGO}`}
                        >
                          <Form.Control
                            as='textarea'
                            className='form-control input-cur me-1'
                            size='sm'
                            id={`edit_andamento_${task.CODIGO}`}
                          />
                          <PKG.IconsUI
                            info={PKG.ui.action.save}
                            clsnm='svg18 textContrastColor hovGreen mousePointerHover me-1'
                            tooltip='Salvar andamento'
                            click={() => {
                              const val = document.getElementById(
                                `edit_andamento_${task.CODIGO}`,
                              ).value;
                              updateAndamento(task.CODIGO, val, task.TASKTYPE);
                            }}
                          />
                          <PKG.IconsUI
                            info={PKG.ui.action.close}
                            clsnm='svg18 cRed hovGreen mousePointerHover'
                            tooltip='Fechar'
                            click={() => {
                              document
                                .getElementById(
                                  `gr_edit_andamento_${task.CODIGO}`,
                                )
                                .classList.add('divHide');
                            }}
                          />
                        </Form.Group>

                        <p className='mt-3 mb-1'>
                          {task.CLIENTE_TELEFONE ? (
                            <PKG.IconsUI
                              info={PKG.ui.social.whatsapp}
                              clsnm='me-1 svg14'
                              tooltip='Enviar mensagem'
                              link={createLinkWhatsapp(task.CLIENTE_TELEFONE)}
                              linkTargetToBlank={true}
                              linkAriaLabel='Enviar mensagem via whatsapp'
                              linkRel='noreferrer'
                              linkClass='cLink'
                              sideText={
                                <span className='gocol fs10'>
                                  <span>{task.CLIENTE_NOME}</span>
                                  <span>{task.CLIENTE_TELEFONE}</span>
                                </span>
                              }
                            />
                          ) : null}
                        </p>
                        {task.CORRETOR_NOME !== 'Nenhum' &&
                        task.CORRETOR_NOME !== null ? (
                          <p className='mb-1 fs12'>{task.CORRETOR_NOME}</p>
                        ) : (
                          ''
                        )}

                          {task.PROCESSO_CODIGO && (
                            <PKG.IconsUI
                              info={PKG.ui.folder.open}
                              clsnm='me-1 svg14'
                              tooltip='Abrir Processo'
                              link={`/processo/${task.PROCESSO_CODIGO}`}
                              linkTargetToBlank={false}
                              linkAriaLabel='Abrir Processo'
                              linkRel='noreferrer'
                              linkClass='cLink'
                              sideText='Abrir Processo'
                              sideTextClass='fs12'
                            />
                          )}
                      </div>
                    );
                  } else {
                    return '';
                  }
                })}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
