import { Badge } from 'react-bootstrap';
import { PKG } from '../../config/imports';
import { useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';

export function BadgeOnOff({ active, loading, click = null }) {
  const options = active
    ? {
        bg: 'success',
        text: 'ON',
        clsnm: `textContrast wp30 hp30 faCenter ${
          click ? 'mousePointerHover' : ''
        }`,
      }
    : {
        bg: 'secondary',
        text: 'OFF',
        clsnm: `textContrast wp30 hp30 faCenter ${
          click ? 'mousePointerHover' : ''
        }`,
      };

  if (loading)
    return (
      <Spinner
        animation='border'
        variant='success'
        size='sm'
      />
    );

  return (
    <span
      className={`d-flex justify-content-center align-items-center fw600 ffSoft border border-${options.bg} border-2 rounded-5 fs10  m-0 ${options.clsnm}`}
      onClick={click}
      title={active ? 'Ativo' : 'Inativo'}
    >
      {options.text}
    </span>
  );
}

export function BadgeNumber({ number }) {
  return (
    <span
      className={`d-flex justify-content-center align-items-center fw600 ffSoft  ${
        number === 1 ? 'cOrange' : 'cPurple'
      } fs10 me-2`}
    >
      #{number}
    </span>
  );
}

export function BadgeCompor({ compor }) {
  const { theme } = useContext(PKG.PackageContext);
  const options = compor
    ? {
        text: 'info',
        icon: PKG.ui.user.multi,
        tooltip: 'Renda múltipla',
      }
    : {
        text: 'warning',
        icon: PKG.ui.user.single,
        tooltip: 'Renda única',
      };

  return (
    <Badge
      className={`d-flex justify-content-center align-items-center border wp30 hp30 border-2 border-${options.text} bgTransparent`}
      pill
      bg={theme}
      text={options.text}
    >
      <PKG.IconsUI
        info={options.icon}
        clsnm='svg20 '
        tooltip={options.tooltip}
      />
    </Badge>
  );
}

export function BadgeFull({ full }) {
  const { theme } = useContext(PKG.PackageContext);
  const options = full
    ? {
        text: 'info',
        icon: PKG.ui.droplet.full,
        tooltip: 'Simulação Completa',
      }
    : {
        text: 'warning',
        icon: PKG.ui.droplet.half,
        tooltip: 'Simulação Simplificada',
      };

  return (
    <Badge
      className={`d-flex justify-content-center align-items-center border wp30 hp30 border-2 border-${options.text} bgTransparent`}
      bg={theme}
      pill
      text={options.text}
    >
      <PKG.IconsUI
        info={options.icon}
        clsnm='svg20'
        tooltip={options.tooltip}
      />
    </Badge>
  );
}

export function BadgeShare({ share }) {
  const { theme } = useContext(PKG.PackageContext);
  const options = share
    ? {
        text: 'info',
        icon: PKG.ui.action.share.on,
        tooltip: 'Compartilhar com o Corretor',
      }
    : {
        text: 'danger',
        icon: PKG.ui.action.share.off,
        tooltip: 'Não Compartilhar com o Corretor',
      };

  return (
    <Badge
      className={`d-flex justify-content-center align-items-center border wp30 hp30 border-2 border-${options.text} bgTransparent`}
      bg={theme}
      pill
      text={options.text}
    >
      <PKG.IconsUI
        info={options.icon}
        clsnm='svg20'
        tooltip={options.tooltip}
      />
    </Badge>
  );
}

export function BadgeCheckCross({ res }) {
  const options = res
    ? {
        text: 'info',
        icon: PKG.ui.misc.check.reg,
        border: 'borGreen cGreen',
      }
    : {
        text: 'danger',
        icon: PKG.ui.misc.cross,
        border: 'borDanger cRed',
      };

  return (
    <span
      className={`d-flex justify-content-center align-items-center ${options.border} borRad20 wp25 hp25 p-1 bgTransparent`}
    >
      <PKG.IconsUI
        info={options.icon}
        clsnm='svg18'
        tooltip={options.tooltip}
      />
    </span>
  );
}
