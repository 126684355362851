import React, { useContext, useEffect, useState } from 'react';
import { Form, ProgressBar, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import '../assets/css/app/page.processo.css';

import {
  ProtocolosTable,
  AndamentoModal,
  AndamentosLine,
  ConcluidoModal,
  EtapaIcon,
  Footer,
  GlobalContext,
  PKG,
  CONF,
  SCR,
  NavBar,
  API,
  END,
  BlobViewer,
} from '../config/imports';

export default function Processo() {
  const { checkLogin, navigate, store, setEmailArchive, setEmailccArchive } =
    useContext(GlobalContext);
  const { theme } = useContext(PKG.PackageContext);
  const { numberFormat } = SCR.Helpers;
  const { codigo } = useParams();

  const [processo, setProcesso] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [andamentos, setAndamentos] = useState([]);
  const [protocolos, setProtocolos] = useState([]);

  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [percent, setPercent] = useState(0);
  const [percentColor, setPercentColor] = useState('primary');

  const [andamentoModal, setAndamentoModal] = useState([]);
  const [concluidosModal, setConcluidosModal] = useState([]);

  const [procdocs, setProcdocs] = useState([]);

  const [showEtapa, setShowEtapa] = useState([]);

  const [sendingEmail, setSendingEmail] = useState(false);
  const [infoEmail, setInfoEmail] = useState('');

  const [logged, setLogged] = useState(true);

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleModal = (index, modal, prevStates) => {
    let newStates = [];
    switch (modal) {
      case 'modal':
        newStates = [...prevStates];
        newStates[index] = !newStates[index];
        setAndamentoModal(newStates);
        break;
      case 'concluido':
        newStates = [...prevStates];
        newStates[index] = !newStates[index];
        setConcluidosModal(newStates);
        break;
      default:
        break;
    }
  };

  const loadBlobContent = async (
    docSim,
    blobCode,
    blobType,
    cod_doc_sim = '',
  ) => {
    const { token } = store();
    try {
      const form = new FormData();
      if (docSim) {
        form.append('simulacao_codigo', cod_doc_sim);
      }
      form.append('codigo', blobCode);
      form.append('files_type', blobType);
      form.append('token', token);

      const url = docSim
        ? `${CONF.ORA}${END.sim_docs.read.doc}/`
        : `${CONF.ORA}${END.and.read.docs}/`;
      const response = await fetch(url, {
        method: 'POST',
        body: form,
      });

      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error(error);
    }
  };

  const sendDocsToEmail = async (
    email = null,
    archive = false,
    inform = false,
    emailcc = null,
  ) => {
    setSendingEmail(true);
    inform && setInfoEmail('Enviando email...');
    const emailTo = email || document.getElementById('enviarEMail').value;

    const setDocs = async () => {
      const documentsToSend = [];
      const filesToSend = [];
      const filesNameToSend = [];

      document.querySelectorAll('.checkDocumentProcesso').forEach((check) => {
        if (archive) {
          documentsToSend.push(check.id);
        } else {
          if (check.checked) documentsToSend.push(check.id);
        }
      });

      const blobs = async () => {
        const blobsPromises = [];
        documentsToSend.map((doc) => {
          const file = procdocs.find((d) => d.CODIGO === doc);
          blobsPromises.push(
            loadBlobContent(
              file.ORIGIN === 'simulacao',
              file.CODIGO,
              file.FILES_TYPE,
              file.SIMULACAO_CODIGO,
            ),
          );
          return null;
        });

        const res = await Promise.all(blobsPromises);
        return res;
      };

      const blobsResponse = await blobs();

      blobsResponse.map((blob, index) => {
        const file = procdocs.find((d) => d.CODIGO === documentsToSend[index]);
        // const blobURL = URL.createObjectURL(blob);
        filesToSend.push(blob);
        filesNameToSend.push(file.FILES_NAME);
        return null;
      });

      const emailContent = () => {
        const lis = documentsToSend.map((doc) => {
          const name = procdocs.find((d) => d.CODIGO === doc).FILES_NAME;
          const type = procdocs.find((d) => d.CODIGO === doc).DOCUMENTO_TYPE;
          return `<li>${name}  |  (${type})</li>`;
        });

        return `         
          <div>            
              <p>Seguem em anexo os documentos do processo de ${
                processo.CLIENTE_NOME
              }</p>
              <p style='padding-top: 2em;font-weight: 600;'>
                Código: ${processo.PROCESSO_CODIGO}
              </p>
              <p>Cliente: ${processo.CLIENTE_NOME}</p>
              <p>Telefone: ${processo.CLIENTE_TELEFONE}</p>
              <p>E-mail: ${processo.CLIENTE_EMAIL}</p>
              <p>Operação: ${processo.OPERACAO}</p>
              <p>Valor: ${numberFormat(processo.VALOR_IMOVEL)}</p>
              <p>Cidade: ${processo.CIDADE_IMOVEL}</p>
              <p>Corretor: ${
                processo.CORRETOR_NOME === null
                  ? 'Nenhum'
                  : processo.CORRETOR_NOME
              }</p>
              <p>Imobiliária: ${
                processo.IMOBILIARIA_NOME === null
                  ? 'Nenhuma'
                  : processo.IMOBILIARIA_NOME
              }</p>
              <p style='padding-top: 2em;font-weight: 600;'>Documentos anexados:</p>
              <ul>
                ${lis.join('')}
              </ul>
              <br><br><br>
              <p>Atenciosamente,</p>
              <p>Equipe ${CONF.tenant}</p>
          </div>
        `;
      };

      return { filesToSend, filesNameToSend, emailContent };
    };

    const infos = await setDocs();

    try {
      const logo = await API(END.tenants.logo_link, {
        brand: CONF.brand,
      });
      const logo_link = logo.data;
      const { filesToSend, filesNameToSend, emailContent } = infos;
      const formData = new FormData();
      formData.append('email', emailTo);
      formData.append(
        'subject',
        `Documentos | ${processo.CLIENTE_NOME} [${processo.PROCESSO_CODIGO}]`,
      );
      // formData.append('files', filesToSend);
      // formData.append('filename', filesNameToSend);
      filesToSend.forEach((file, index) => {
        formData.append('files[]', file); // 'files[]' indicates an array
        formData.append('filename[]', filesNameToSend[index]); // 'filename[]' as an array for each filename
      });
      if (emailcc) formData.append('cc', emailcc);
      formData.append('content', emailContent());
      formData.append('logo_link', logo_link);
      formData.append('brand', CONF.brand);

      await API(END.bwAPI.maildocs, formData, true);
    } catch (error) {
      console.error(error);
    } finally {
      inform && setInfoEmail(`E-mail enviado com sucesso para ${emailTo}!`);
      setSendingEmail(false);
    }
  };

  const handleArchive = async () => {
    setEmailArchive(CONF.backupmail);
    if (CONF.backupmailcc) setEmailccArchive(CONF.backupmailcc);
    setLoading(true);
    try {
      if (CONF.backupmailcc) {
        await sendDocsToEmail(CONF.backupmail, true, false, CONF.backupmailcc);
      } else {
        await sendDocsToEmail(CONF.backupmail, true, false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    navigate(`/report/processo/${processo.PROCESSO_CODIGO}`);
  };

  useEffect(() => {
    setEmailArchive('');
    setEmailccArchive('');
    const get_processo = async () => {
      setLoading(true);
      try {
        const [
          processoResponse,
          etapasResponse,
          andamentosResponse,
          protocolosResponse,
          procdocsResponse,
        ] = await Promise.all([
          API(END.processos.read.codigo, { codigo }),
          API(END.processo_etapa.read.all, { codigo }),
          API(END.andamentos.read.all, { codigo }),
          API(END.views.protocolos_processo, { codigo }),
          API(END.views.procdocs, { codigo }),
        ]);

        const { data: processoData } = processoResponse;
        const { data: etapasData } = etapasResponse;
        const { data: andamentosData } = andamentosResponse;
        const { data: protocolosData } = protocolosResponse;
        const { data: procdocsData } = procdocsResponse;

        const percent_concluido =
          etapasData.filter((et) => et.PROCESSO_ETAPAS_CONCLUIDA).length /
          etapasData.length;
        const perc = percent_concluido * 100;
        setPercent(perc);

        if (perc < 20) {
          setPercentColor('secondary');
        } else if (perc < 40) {
          setPercentColor('warning');
        } else if (perc < 60) {
          setPercentColor('info');
        } else if (perc < 80) {
          setPercentColor('primary');
        } else {
          setPercentColor('success');
        }

        if (processoData) setProcesso(processoData[0]);

        if (andamentosData) {
          andamentosData.sort((a, b) => {
            return new Date(a.CREATED_AT) - new Date(b.CREATED_AT);
          });
          setAndamentos(andamentosData);
        }

        if (etapasData) setEtapas(etapasData);
        if (protocolosData) setProtocolos(protocolosData);
        if (procdocsData) setProcdocs(procdocsData);

        setAndamentoModal(etapasData.map(() => false));
        setConcluidosModal(etapasData.map(() => false));
        setShowEtapa(
          etapasData.map((etapa) => {
            const state = localStorage.getItem(
              `${processo.PROCESSO_CODIGO}_${etapa.ETAPAS_SEQUENCIA - 1}`,
            );
            return state === 'true';
          }),
        );
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    get_processo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigo, updated, END]);

  const handleUpdate = () => {
    const upd = updated;
    setUpdated(!upd);
  };

  const handleChangeDataConcluido = (index) => {
    setEtapas((prevEtapas) =>
      prevEtapas.map((etapa, i) =>
        i === index
          ? {
              ...etapa,
              PROCESSO_ETAPAS_DATA_INICIAL: document.getElementById(
                `id_modal_started_${etapa.PROCESSO_ETAPAS_ID}`,
              ).value,
              PROCESSO_ETAPAS_DATA_FINAL: document.getElementById(
                `id_modal_done_${etapa.PROCESSO_ETAPAS_ID}`,
              ).value,
            }
          : etapa,
      ),
    );
  };

  const handleSave = async (index) => {
    setLoading(true);
    try {
      await API(END.proc_et.update.done, {
        id: etapas[index].PROCESSO_ETAPAS_ID,
        data_inicial: new Date(
          document.getElementById(
            `id_modal_started_${etapas[index].PROCESSO_ETAPAS_ID}`,
          ).value,
        )
          .toISOString()
          .split('T')[0],
        data_final: new Date(
          document.getElementById(
            `id_modal_done_${etapas[index].PROCESSO_ETAPAS_ID}`,
          ).value,
        )
          .toISOString()
          .split('T')[0],
        processo_etapa_id: etapas[index].PROCESSO_ETAPAS_ID,
        concluida: etapas[index].PROCESSO_ETAPAS_CONCLUIDA,
        avancar:
          etapas.length > index + 1 ? etapas[index + 1].PROCESSO_ETAPAS_ID : 0,
      });

      setUpdated((prevUpdated) => !prevUpdated);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = async (index) => {
    setEtapas(() => {
      const newStates = [...etapas];
      newStates[index].PROCESSO_ETAPAS_CONCLUIDA = 0;
      return newStates;
    });
    handleSave(index);
  };

  const headerProcesso = () => {
    return (
      <>
        <div className='processo-header my-3'>
          <div className='d-flex flex-row align-items-center justify-content-between textContrastColor '>
            <h4 className='ffSoft mb-3'>Processo</h4>
            <div className='gorow align-items-center justify-content-end'>
              <PKG.IconsUI
                info={PKG.ui.action.update}
                clsnm='svg14 hovGreen'
                click={handleUpdate}
                tooltip='Atualizar lista de simulações'
              />
              {!processo.C && (
                <PKG.IconsUI
                  info={PKG.ui.file.pdf}
                  clsnm='svg14 cRed hovGreen mousePointerHover ms-2'
                  canvasIgnore={true}
                  tooltip='Imprimir PDF'
                  id='btnPrintPDF'
                  click={() =>
                    navigate(`/report/processo/${processo.PROCESSO_CODIGO}`)
                  }
                />
              )}
              {store().role_id < 4 && (
                <PKG.IconsUI
                  info={PKG.ui.archive.in}
                  clsnm='svg14 cRed hovGreen mousePointerHover ms-2'
                  canvasIgnore={true}
                  tooltip='Arquivar processo'
                  id='btnArchive'
                  click={handleArchive}
                />
              )}
            </div>
          </div>
          <h6 className='textContrastColor'>{processo.CLIENTE_NOME}</h6>
          <div>
            <p>{`${processo.OPERACAO}: ${numberFormat(
              processo.VALOR_IMOVEL,
            )} (${processo.CIDADE_IMOVEL})`}</p>
            <p>
              {`Corretor: ${
                processo.CORRETOR_NOME === null
                  ? 'Nenhum'
                  : processo.CORRETOR_NOME
              }`}
              {processo.IMOBILIARIA_NOME !== null &&
              processo.IMOBILIARIA_NOME !== '' &&
              processo.IMOBILIARIA_NOME !== 'Nenhuma'
                ? ` | Imobiliária: ${processo.IMOBILIARIA_NOME}`
                : ''}
            </p>
          </div>
          <ProgressBar
            now={percent}
            variant={percentColor}
            label={`${percent}%`}
            className='bgContrastSoft'
          />
        </div>
        {!processo.C ? (
          <>
            <div className='d-flex flex-row justify-content-between mt-4 mb-1'>
              <h6 className='ffSoft textContrastColorBlue'>
                Protocolos do Processo
              </h6>
              <PKG.IconsUI
                info={PKG.ui.expand.more}
                clsnm='hovSvgSoft hovGreen cLink svg16 mousePointerHover'
                click={() => {
                  const el = document.getElementById('dv_cont_processo');
                  el.classList.toggle('divHide');
                }}
              />
            </div>
            <div
              id='dv_cont_processo'
              className={`mb-4 ${protocolos.length === 0 ? 'divHide' : ''}`}
            >
              <ProtocolosTable view_protocolos={protocolos} />
            </div>
          </>
        ) : (
          ''
        )}
        <>
          <div className='d-flex flex-row justify-content-between mt-4 mb-1'>
            <h6 className='ffSoft textContrastColorBlue'>Documentos</h6>
            <PKG.IconsUI
              info={PKG.ui.expand.more}
              clsnm='hovSvgSoft hovGreen cLink svg16 mousePointerHover'
              click={() => {
                const el = document.getElementById('dv_docs_processo');
                el.classList.toggle('divHide');
              }}
            />
          </div>
          <div
            id='dv_docs_processo'
            className={`mb-4 `}
          >
            {procdocs.map((doc) => {
              return (
                <div
                  key={doc.CODIGO}
                  className='d-flex flex-row justify-content-start align-items-center py-2'
                  id={`doc_${doc.CODIGO}`}
                >
                  <input
                    className='form-check m-0 me-2 checkDocumentProcesso'
                    data-bs-theme={theme}
                    type='checkbox'
                    id={doc.CODIGO}
                    key={`select_${doc.CODIGO}`}
                  />
                  <BlobViewer
                    key={doc.CODIGO}
                    blobCode={doc.CODIGO}
                    blobType={doc.FILES_TYPE}
                    blobIcon={PKG.fileIcon(doc.FILES_TYPE, 'svg14')}
                    blobName={doc.FILES_NAME}
                    docSim={doc.ORIGIN === 'simulacao'}
                    cod_doc_sim={doc.SIMULACAO_CODIGO}
                    blobTitle={doc.DOCUMENTO_TYPE}
                  />
                </div>
              );
            })}

            {sendingEmail ? (
              <PKG.Loading />
            ) : (
              <div className='gorow gocol650 align-items-center py-2'>
                <Button
                  className='btn-sm btn-secondary fs10 fw300'
                  type='button'
                  onClick={() => {
                    const checksArr = document.querySelectorAll(
                      '.checkDocumentProcesso',
                    );
                    checksArr.forEach((check) => {
                      check.checked = !check.checked;
                    });
                  }}
                >
                  Selecionar/Remover Todos
                </Button>

                <Form.Group className='m-2 gorow align-items-center justify-content-start'>
                  <Form.Label
                    className='m-0 fs12 wp90'
                    htmlFor='enviarEMail'
                  >
                    Enviar para
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='enviarEMail'
                    size='sm'
                    name='enviarEMail'
                    className='m-0  form-control wp300'
                  />
                  <Button
                    className='btn btn-sm btn-primary cWhite fs12 ms-1'
                    type='button'
                    onClick={sendDocsToEmail}
                  >
                    Enviar
                  </Button>
                </Form.Group>
              </div>
            )}
            <p>{infoEmail}</p>
          </div>
        </>

        <h6 className='my-4 ffSoft textContrastColorBlue'>Etapas</h6>
      </>
    );
  };

  if (!logged) {
    navigate('/logout');
  } else {
    if (loading) {
      return <PKG.LoadingDots conf={CONF} />;
    }

    return (
      <PKG.ContainerBox
        main={
          <div className='w100 mb-5'>
            {headerProcesso()}
            {etapas &&
              etapas.map((et, index) => {
                return (
                  <div
                    key={et.PROCESSO_ETAPAS_ID}
                    className={`divEtapa ${
                      et.PROCESSO_ETAPAS_CONCLUIDA
                        ? 'etConcluida'
                        : processo.ETAPA_ATUAL_ID === et.PROCESSO_ETAPAS_ID
                        ? 'etAtual'
                        : 'etPendente'
                    }`}
                  >
                    <div className='etapaHeader'>
                      <div className='etapaHeaderStart py-1 gorow gocol550'>
                        <span className='headerSection ffSoft'>
                          <span className='me-2'>
                            <EtapaIcon etapa={et.ETAPAS_NOME} />
                          </span>
                          <span
                            className={`me-3 fs12 ffSoft ${
                              et.PROCESSO_ETAPAS_CONCLUIDA
                                ? 'textContrastLow'
                                : processo.ETAPA_ATUAL_ID ===
                                  et.PROCESSO_ETAPAS_ID
                                ? 'fw700 fs16 textContrastColorBlue'
                                : ''
                            }`}
                          >
                            {et.ETAPAS_NOME}
                          </span>
                          {et.PROCESSO_ETAPAS_CONCLUIDA ? (
                            <PKG.IconsUI
                              info={PKG.ui.misc.check.circle}
                              clsnm='cGreen me-2'
                            />
                          ) : (
                            ''
                          )}
                        </span>

                        <span className='headerSection fs10'>
                          <span className='mx-1'>
                            <PKG.IconsUI
                              info={PKG.ui.action.start}
                              clsnm={`svg14 ${
                                et.PROCESSO_ETAPAS_CONCLUIDA
                                  ? ' cGreen'
                                  : processo.ETAPA_ATUAL_ID ===
                                    et.PROCESSO_ETAPAS_ID
                                  ? ' textContrastColorBlue'
                                  : ' textContrastLow'
                              }`}
                            />
                          </span>
                          <span className='mx-1'>
                            {et.PROCESSO_ETAPAS_CONCLUIDA ||
                            processo.ETAPA_ATUAL_ID === et.PROCESSO_ETAPAS_ID
                              ? new Date(
                                  et.PROCESSO_ETAPAS_DATA_INICIAL,
                                ).toLocaleDateString('pt-BR')
                              : ''}
                          </span>
                          <span className='mx-1'>
                            {et.PROCESSO_ETAPAS_CONCLUIDA ? (
                              <>
                                <PKG.IconsUI
                                  info={PKG.ui.status.done}
                                  clsnm={`svg14  cGreen`}
                                />
                                <span className='mx-1'>
                                  {new Date(
                                    et.PROCESSO_ETAPAS_DATA_FINAL,
                                  ).toLocaleDateString('pt-BR')}
                                </span>
                              </>
                            ) : (
                              <>
                                <PKG.IconsUI
                                  info={PKG.ui.status.waiting}
                                  clsnm={`svg14 textContrastColorBlue mx-2`}
                                />
                                <span className='mx-1'>
                                  {et.ETAPAS_DIAS_ESTIMATIVA === 1
                                    ? 'Hoje'
                                    : `+ ${et.ETAPAS_DIAS_ESTIMATIVA} dias`}
                                </span>
                              </>
                            )}
                          </span>
                        </span>
                      </div>
                      {processo.ETAPA_ATUAL_ID === et.PROCESSO_ETAPAS_ID ? (
                        <span className='fs10 fw600 ffSoft textContrastColor px-2'>
                          Etapa Atual
                        </span>
                      ) : (
                        <PKG.IconsUI
                          info={PKG.ui.expand.more}
                          clsnm='hovSvgSoft hovGreen cLink svg16 mousePointerHover'
                          click={() => {
                            const state = showEtapa[index];
                            console.log(state);
                            setShowEtapa(() => {
                              const newStates = [...showEtapa];
                              newStates[index] = !state;
                              return newStates;
                            });
                            localStorage.setItem(
                              `${processo.PROCESSO_CODIGO}_${index}`,
                              !state,
                            );
                          }}
                        />
                      )}
                    </div>
                    <div
                      id={`ands_${et.PROCESSO_ETAPAS_ID}`}
                      className={`etapaContainer ${
                        processo.ETAPA_ATUAL_ID === et.PROCESSO_ETAPAS_ID
                          ? 'divAtual'
                          : 'divHide'
                      } ${
                        localStorage.getItem(`show_${index}`) === true ||
                        showEtapa[index]
                          ? 'showMe'
                          : ''
                      }`}
                    >
                      <div
                        className={`ffSoft fs10 ${
                          etapas[index].PROCESSO_ETAPAS_CONCLUIDA
                            ? 'rowConcluido rowConcluidoDone'
                            : 'rowConcluido'
                        }`}
                      >
                        <ConcluidoModal
                          key={`modal_done_${et.PROCESSO_ETAPAS_ID}`}
                          ind={index}
                          etapaNome={et.ETAPAS_NOME}
                          id_concluido={et.PROCESSO_ETAPAS_ID}
                          data_inicial={et.PROCESSO_ETAPAS_DATA_INICIAL}
                          show={concluidosModal[index]}
                          handleSave={() => handleSave(index)}
                          handleClose={() => handleClose(index)}
                          handleDate={() => handleChangeDataConcluido(index)}
                          setShow={() =>
                            handleToggleModal(
                              index,
                              'concluido',
                              concluidosModal,
                            )
                          }
                        />
                        <Form.Check
                          checked={etapas[index].PROCESSO_ETAPAS_CONCLUIDA}
                          className='concluidoCheck'
                          type='checkbox'
                          id={`done_${et.PROCESSO_ETAPAS_ID}`}
                          key={`done_${et.PROCESSO_ETAPAS_ID}`}
                          label='Concluída'
                          onChange={(e) => {
                            setEtapas(() => {
                              const newStates = [...etapas];
                              newStates[index].PROCESSO_ETAPAS_CONCLUIDA =
                                e.target.checked;
                              return newStates;
                            });
                            e.target.checked
                              ? handleToggleModal(
                                  index,
                                  'concluido',
                                  concluidosModal,
                                )
                              : handleClose(index);
                          }}
                        />

                        <>
                          <PKG.IconsUI
                            info={PKG.ui.action.add.reg}
                            clsnm='svg14 mousePointerHover hovGreen cLink'
                            tooltip='Adicionar Andamento'
                            click={() =>
                              handleToggleModal(index, 'modal', andamentoModal)
                            }
                          />

                          <AndamentoModal
                            key={`modal_andamento_${et.PROCESSO_ETAPAS_ID}`}
                            show={andamentoModal[index]}
                            proc_etapa_id={et.PROCESSO_ETAPAS_ID}
                            procs={processo}
                            updated={setUpdated}
                            setShow={() =>
                              handleToggleModal(index, 'modal', andamentoModal)
                            }
                          />
                        </>
                      </div>

                      {andamentos
                        .filter(
                          (and) =>
                            and.PROCESSO_ETAPA_ID === et.PROCESSO_ETAPAS_ID,
                        )
                        .map((and) => {
                          return (
                            <AndamentosLine
                              key={and.CODIGO}
                              and={and}
                              upd={handleUpdate}
                              c={processo.C}
                              procs={processo}
                              handleModal={() =>
                                handleToggleModal(
                                  index,
                                  'modal',
                                  andamentoModal,
                                )
                              }
                            />
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        }
        footer={<Footer />}
        navbar={<NavBar />}
        overlayMain={false}
        classMain='setWidth'
        navreturn={true}
        navreturnClass='setWidth'
      />
    );
  }
}
