import Card from 'react-bootstrap/Card';
// import '../../../assets/css/components/cardbox.css';

export default function CardBox(cardObject) {

  // expected object example
  // const cards =  {
  //   cardWidth: 300,
  //   items: [
  //     {
  //       title: 'titel',
  //       maintext:
  //         'text',
  //       classes: {
  //         card: 'cardboxMain bgBluerTransp',
  //         header: 'cYellow w100 pt-2',
  //         footer: 'fs10',
  //       },
  //     }
  //   ]
  // };

  const card = () => {
    return (
      <div className={`m-2 cardboxMain ${cardObject.classes.cardWidth}  ${cardObject.classes.main}`}>
        <Card
          className={cardObject.classes.card}
          // bg='dark'
          // text='white'
        >
          {cardObject.image ? (
            <Card.Img
              variant='top'
              src={cardObject.image}
              className={cardObject.classes.image}
              alt={cardObject.image.alt  || 'cardboxImage'}

            />
          ) : (
            ''
          )}
          {cardObject.header ? (
            <h2 className={cardObject.classes.header}>
              {cardObject.header}
            </h2>
          ) : (
            ''
          )}

          <Card.Body className={cardObject.classes.body}>
            {cardObject.title ? (
              <Card.Title className={cardObject.classes.title}>
                {cardObject.title}
              </Card.Title>
            ) : (
              ''
            )}
            {cardObject.subtitle ? (
              <Card.Subtitle className={cardObject.classes.subtitle}>
                {cardObject.subtitle}
              </Card.Subtitle>
            ) : (
              ''
            )}

            {cardObject.maintext ? (
              <Card.Text className={cardObject.classes.text}>
                {cardObject.maintext}
              </Card.Text>
            ) : (
              ''
            )}
          </Card.Body>
          {cardObject.link1 || cardObject.link2 ? (
            <Card.Footer className={cardObject.classes.footer}>
              {cardObject.link1 ? (
                <Card.Link
                  className={cardObject.classes.link}
                  href={cardObject.link1.link}
                >
                  {cardObject.link1.text}
                </Card.Link>
              ) : (
                ''
              )}

              {cardObject.link2 ? (
                <Card.Link
                  className={cardObject.classes.link}
                  href={cardObject.link2.link}
                >
                  {cardObject.link2.text}
                </Card.Link>
              ) : (
                ''
              )}
            </Card.Footer>
          ) : (
            ''
          )}
        </Card>
      </div>
    );
  };

  return card();
}
