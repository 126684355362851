import html2pdf from 'html2pdf.js';
// import jsPDF from 'jspdf';
// import { useCallback, useContext, useEffect, useState } from 'react';
import { useContext,  useState } from 'react';
// import { IMaskInput } from 'react-imask';
// import Html from 'react-pdf-html';
// import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
// import { RotatingSquare } from 'react-loader-spinner';
// import { QRCodeCanvas } from 'qrcode.react';

import {
  // CONF,
  PKG,
  API,
  // SCR,
  // BlobViewer,
  // DragAndDrop,
  END,
  // Financiamentos,
  // GlobalContext,
  ModalLine,
  BadgeCheckCross,
  SimuladorForm,
  CONF,
  // SCR,
} from '../../../config/imports';
import Swal from 'sweetalert2';

export default function SimulacaoModal({
  obj,
  del,
  full,
  buttonCOMPOR,
  buttonSHARE,
  buttonFULL,
}) {
  const { addSpaceComma, curr, isTrue } = PKG.SCR.Utils;
  const { theme, navigate } = useContext(PKG.PackageContext);
 // const savedTheme = theme;
  // const reportTemplateRef = useRef(null);
  const [sendingEmail, setSendingEmail] = useState(false);

  const [email, setEmail] = useState('');
  const [infoEmail, setInfoEmail] = useState('');
  const [editMode, setEditMode] = useState(false);
  // const [loading, setLoading] = useState(false);

  const [data, setData] = useState(obj);

  // const [qrCodeUrl, setQrCodeUrl] = useState('');
  // const canvasRef = useRef();

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   if (canvas) {
  //     canvas.toBlob((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       setQrCodeUrl(url);
  //     });
  //   }
  // }, [canvasRef]);

  // const {  float } = PKG.SCR.Utils;
  // const { mascaraMoeda } = SCR.Helpers;

  // const [dataMask, setDataMask] = useState({});

  // const { a, navigate } = useContext(GlobalContext);
  // const {
  //   bancos,
  //   operacoes,
  //   tabelas,
  //   faixas,
  //   imobiliarias,
  //   corretores,
  //   correspondentes,
  // } = a;

  // const [loadings, setLoadings] = useState(
  //   Object.keys(obj).reduce((acc, key) => {
  //     acc[key] = false;
  //     return acc;
  //   }, {}),
  // );

  // const colorSet =
  // theme === 'light' ? 'secondary hovbgLightBlueTransp hovWhite' : 'secondary';
  // const buttonColor = `outline-${colorSet} textContrast`;
  // const buttonColorActive =
  //   theme === 'light' ? 'outline-primary active' : 'outline-warning active';

  // const colorChecked = theme === 'light' ? 'cBlue fw600' : 'cYellow fw600';

  //   const imovel_descricao = {
  //     1: 'Aquisição de Imóvel Novo',
  //     2: 'Construção',
  //     3: 'Reforma ou Ampliação',
  //     4: 'Aquisição de Imóvel Usado',
  //     6: 'Aquisição de Terreno',
  //     7: 'Empréstimo Garantido por Imóvel',
  //     11: 'Imóveis CAIXA',
  //   };

  //   const estadoCivilButtons = () => {
  //     const estadoCivil = [
  //       'Solteiro(a)',
  //       'Casado(a)',
  //       'Divorciado(a)',
  //       'Viúvo(a)',
  //       'União Estável',
  //     ];

  //     return estadoCivil.map((item) => (
  //       <Button
  //         key={item}
  //         size='sm'
  //         variant={
  //           data.ESTADO_CIVIL === item ? buttonColorActive : buttonColor
  //         }
  //         className={`wpMin150 ${
  //           data.ESTADO_CIVIL === item ? 'active' : ''
  //         }`}
  //         onClick={() => {
  //           const compor = item === 'Casado(a)' ? 1 : 0;
  //           const relacionamento = item === 'Casado(a)' ? 'Cônjuge' : '';
  //           setData({
  //             ...data,
  //             ESTADO_CIVIL: item,
  //             COMPOR: compor,
  //             RELACIONAMENTO: relacionamento,
  //           });
  //         }}
  //       >
  //         {item}
  //       </Button>
  //     ));
  //   };

  // useEffect(() => {
  //   bancos && createSelectProp(bancos, 'proc-banco', 'NOME', true, 'ID');
  //   operacoes && createSelectProp(operacoes, 'proc-operacao', 'NOME');
  //   tabelas && createSelectProp(tabelas, 'proc-tabela', 'NOME');
  //   faixas && createSelectProp(faixas, 'proc-linha', 'NOME');
  //   faixas && createSelectProp(faixas, 'proc-taxa', 'VALUE');

  //   imobiliarias &&
  //     createSelectProp(
  //       imobiliarias,
  //       'proc-imobiliaria',
  //       'NOME',
  //       true,
  //       'CODIGO',
  //     );
  //   corretores &&
  //     createSelectProp(corretores, 'proc-corretor', 'NOME', true, 'COD');
  //   correspondentes &&
  //     createSelectProp(
  //       correspondentes,
  //       'proc-correspondente',
  //       'NOME',
  //       true,
  //       'CODIGO',
  //     );
  // }, [
  //   bancos,
  //   operacoes,
  //   tabelas,
  //   faixas,
  //   imobiliarias,
  //   corretores,
  //   correspondentes,
  //   createSelectProp,
  // ]);

  // const handleValor = (event) => {
  //   let entrada;
  //   let financiado;
  //   if (event.target.name === 'valor_entrada_recursos') {
  //     entrada = float(event.target.value) + float(data.valor_entrada_fgts);
  //     financiado = float(data.valor_imovel) - entrada;
  //   } else if (event.target.name === 'valor_entrada_fgts') {
  //     entrada = float(data.valor_entrada_recursos) + float(event.target.value);
  //     financiado = float(data.valor_imovel) - entrada;
  //   } else if (event.target.name === 'valor_imovel') {
  //     entrada =
  //       float(data.valor_entrada_recursos) + float(data.valor_entrada_fgts);
  //     financiado = float(event.target.value) - entrada;
  //   } else {
  //     entrada =
  //       float(data.valor_entrada_recursos) + float(data.valor_entrada_fgts);
  //     financiado = float(data.valor_imovel) - entrada;
  //   }
  //   setData({
  //     ...data,
  //     valor_entrada: entrada,
  //     valor_financiado: financiado,
  //     [event.target.name]: event.target.value,
  //   });
  //   setDataMask({
  //     ...dataMask,
  //     valor_entrada: mascaraMoeda(entrada),
  //     valor_financiado: mascaraMoeda(financiado),
  //     [event.target.name]: mascaraMoeda(event.target.value),
  //   });
  // };

  // const handleLoadings = (field, option = false) => {
  //   setLoadings({
  //     ...loadings,
  //     [field]: option,
  //   });
  // };

  // const handleSetData = (e) => {
  //   setData({
  //     ...data,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const handleSetDataChecked = (e) => {
  //   setData({
  //     ...data,
  //     [e.target.name]: e.target.checked,
  //   });
  // };

  // const handleSave = async (event, field, endpt) => {
  //   event.preventDefault();
  //   let codigo = obj.SIM_USER_CODIGO;
  //   let value = data[field];
  //   try {
  //     handleLoadings(field, true);
  //     await API(endpt, {
  //       codigo,
  //       [field]: value,
  //     });
  //     if (field === 'telefone') {
  //       obj.proc.user.whatsapp = value;
  //     } else {
  //       obj.proc.user[field] = value;
  //     }
  //     obj(obj);
  //     handleLoadings(field, false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   handleLoadings(field, false);
  // };

  // const { fileIcon } = PKG;

  // const { a, navigate } = useContext(GlobalContext);
  // const { a } = useContext(GlobalContext);
  // const [loading, setLoading] = useState();
  // const [uploading, setUploading] = useState(false);
  // const [files, setFiles] = useState([]);
  // const [objFiles, setObjFiles] = useState([]);

  const handleEditAnotacoes = () => {
    document
      .getElementById(`objAnot_${obj.PROCESSO_CODIGO}`)
      .classList.toggle('divHide');
    document
      .getElementById(`objAnotEdit_${obj.PROCESSO_CODIGO}`)
      .classList.toggle('divHide');
  };

  const handleSaveAnotacoes = async () => {
    setData(obj);

    const formData = new FormData();
    formData.append('codigo', obj.PROCESSO_CODIGO);
    formData.append('anotacoes', obj.ANOTACOES);

    // const res = await API(END.sim.anotacoes, formData);
    // if (res) {
    //   document
    //     .getElementById(`objAnot_${obj.PROCESSO_CODIGO}`)
    //     .classList.toggle('divHide');
    //   document
    //     .getElementById(`objAnotEdit_${obj.PROCESSO_CODIGO}`)
    //     .classList.toggle('divHide');
    // } else {
    //   console.log('Erro ao salvar anotações');
    // }
  };

  // const {
  //   bancos,
  //   operacoes,
  //   tabelas,
  //   faixas,
  //   imobiliarias,
  //   corretores,
  //   correspondentes,
  // } = a;

  // const cleanFiles = () => {
  //   setFiles([]);
  // };

  const handleSaveAsPDF = () => {
    const opt = {
      margin: 1,
      filename: `Simulação_${obj.NOME}_${
        new Date(obj.CREATED_AT).toISOString().split('T')[0]
      }_${obj.PROCESSO_ID}.pdf`,
      autoPaging: 'text',
      enableLinks: false,
      image: { type: 'jpeg', quality: 1 },
      logging: false,
      pagebreak: { mode: ['avoid-all', 'css'] },
      // html2canvas: {
      //   allowTaint: true,
      //   letterRendering: true,
      //   logging: false,
      //   scale: 2,
      //   unit: 'cm',
      //   format: 'a4',
      //   orientation: 'portrait',
      //   compress: false,
      //   putOnlyUsedFonts: true,
      // },
      html2canvas: {
        allowTaint: true,
        letterRendering: true,
        logging: false,
        scale: 2,
      },
      jsPDF: {
        unit: 'cm',
        format: 'a4',
        orientation: 'portrait',
        compress: false,
        autoPaging: 'text',
        logging: false,
        putOnlyUsedFonts: true,
      },
    };
    return opt;
  };

  // async function handleDownload() {
  //   document.documentElement.className = 'light';
  //   // document.getElementById('printHeaderImage').classList.remove('divHide');
  //   const filename = `Relatório de Processo - ${obj.NOME}.pdf`;

  //   SCR.PDF.renderPDFInIframe(`mdsim_${obj.PROCESSO_CODIGO}`, 'simulacao_report');
  //   changeTheme(savedTheme);
  //   // navigate(-1);
  // }

  // useEffect(() => {
  //   document.documentElement.className = 'light';
  //   changeTheme('light');
  //   const status = checkLogin();
  //   setLogged(status);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const downloadPDF = async () => {
  //   document.documentElement.className = 'light';
  //   document
  //     .getElementById(`dados_simulacao_${obj.PROCESSO_CODIGO}`)
  //     .classList.remove('divHide');
  //   const element = document.getElementById(`mdsim_${obj.PROCESSO_CODIGO}`);
  //   const opt = handleSaveAsPDF();
  //   // html2pdf().set(opt).from(element).save();
  //   const blob = await html2pdf().set(opt).from(element).outputPdf('blob');
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.target = '_blank';
  //   link.click();
  //   changeTheme(savedTheme);
  //   document
  //     .getElementById(`dados_simulacao_${obj.PROCESSO_CODIGO}`)
  //     .classList.add('divHide');
  // };

  // async function downloadPDF() {
  //   const filename = `Simulação_${obj.NOME}_${
  //     new Date(obj.CREATED_AT).toISOString().split('T')[0]
  //   }_${obj.PROCESSO_ID}.pdf`;

  //   const elements = [{ text: 'Detalhes da Simulação', style: 'subheader' }];

  //   SCR.PDF.createPDF( filename, elements);
  // }

  // const jsPdf = new jsPDF('p', 'cm', 'a4');
  // const htmlElement = document.getElementById(`mdsim_${obj.PROCESSO_CODIGO}`);

  // const opt = {
  //   callback: async function (jsPdf) {
  //     await jsPdf.save(
  //       `Simulação_${obj.NOME}_${
  //         new Date(obj.CREATED_AT).toISOString().split('T')[0]
  //       }_${obj.PROCESSO_ID}.pdf`,
  //     );
  //   },
  //   margin: [3, 2, 3, 2],
  //   autoPaging: 'text',
  //   html2canvas: {
  //     allowTaint: true,
  //     letterRendering: true,
  //     logging: false,
  //     scale: 0.8,
  //     unit: 'cm',
  //     format: 'a4',
  //     orientation: 'portrait',
  //     compress: false,
  //     putOnlyUsedFonts: true,
  //   },
  // };

  // await jsPdf.html(htmlElement, opt);

  // SCR.PDF.downloadPDF(`mdsim_${obj.PROCESSO_CODIGO}`,
  //   `Simulação_${obj.NOME}_${ new Date(obj.CREATED_AT).toISOString().split('T')[0] }_${obj.PROCESSO_ID}.pdf`
  // );
  // }

  const sendPdfToMail = async () => {
    setSendingEmail(true);
    setInfoEmail('Enviando email...');
    const emailTo = document.getElementById('mailto').value;
    const element = document.getElementById(`mdsim_${obj.PROCESSO_CODIGO}`);
    try {
      const opt = handleSaveAsPDF();
      const blob = await html2pdf().set(opt).from(element).outputPdf('blob');

      const formData = new FormData();
      formData.append('pdf', blob, opt.filename);
      formData.append('email', document.getElementById('mailto').value);
      formData.append('subject', `Simulação ${obj.TIPO} - ${obj.NOME}`);
      formData.append('filename', opt.filename);

      await API(END.sim.mail, formData, true);
    } catch (error) {
      console.error(error);
    } finally {
      setEmail('');
      setInfoEmail(`E-mail enviado com sucesso para ${emailTo}!`);
      setSendingEmail(false);
    }
  };

  // const handleDelete = async (simulacao_codigo, file_codigo) => {
  //   setLoading(true);
  //   try {
  //     await API(END.sim_docs.delete, {
  //       simulacao_codigo,
  //       codigo: file_codigo,
  //     });
  //     fetchFiles();
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   setLoading(false);
  // };

  // const fetchFiles = useCallback(async () => {
  //   const response = await API(END.sim_docs.read.all, {
  //     simulacao_codigo: obj.PROCESSO_CODIGO,
  //   });
  //   const data = response.data;
  //   setObjFiles(data);
  // }, [obj.PROCESSO_CODIGO]);

  // useEffect(() => {
  //   try {
  //     fetchFiles();
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  // const uploadFilesToServer = async () => {
  //   setUploading(true);

  //   try {
  //     const uploadPromises = [];
  //     const countFiles = files.length;

  //     for (let i = 0; i < countFiles; i += 1) {
  //       const formData = new FormData();
  //       formData.append('codigo', obj.PROCESSO_CODIGO);
  //       formData.append('title', files[i].description);
  //       formData.append('files', files[i].file);
  //       formData.append('files_name', files[i].file.name);
  //       formData.append('files_type', files[i].file.type);

  //       const uploadPromise = API(END.sim_docs.create, formData);
  //       uploadPromises.push(uploadPromise);
  //     }

  //     await Promise.all(uploadPromises);
  //   } catch (error) {
  //     console.error('Erro ao fazer upload:', error);
  //   } finally {
  //     setUploading(false);
  //   }
  //   fetchFiles();
  //   cleanFiles();
  // };

  // if (loading) return <PKG.LoadingDots conf={CONF} />;

  if (editMode)
    return (
      <div
        id={`edit_${obj.PROCESSO_CODIGO}`}
        className='setWidth'
      >
        <PKG.IconsUI
          info={PKG.ui.misc.cross}
          clsnm='svg14 cRed hovGreen mousePointerHover'
          tooltip='Cancelar edição'
          click={() => setEditMode(false)}
          canvasIgnore={true}
          sideText='Cancelar edição'
          sideTextClass='me-1 fs12 cRed fw500'
          sideTextPosition='left'
          sideDivClass='divHovGreen hovGreen mousePointerHover w100 d-flex flex-row justify-content-start'
        />
        <SimuladorForm
          obj={data}
          setObj={setData}
          theme={theme}
        />
      </div>
    );

  return (
    <div
      // ref={reportTemplateRef}
      id={`mdsim_${obj.PROCESSO_CODIGO}`}
      className='setWidth'
    >
      <div className='d-flex flex-row flex-wrap justify-content-end align-items-center hpMin50 mb-3 fs10'>
        <PKG.IconsUI
          info={PKG.ui.misc.edit}
          clsnm='svg14 cBlue hovGreen mousePointerHover'
          tooltip='Editar simulação'
          click={() => setEditMode(true)}
          canvasIgnore={true}
          sideText='Editar simulação'
          sideTextClass='me-1 fs12 cBlue fw500'
          sideTextPosition='left'
          sideDivClass='divHovGreen hovGreen mousePointerHover my-2 w100 d-flex flex-row justify-content-start'
        />

        <span className='mx-1'>{buttonCOMPOR}</span>
        <span className='mx-1'>{buttonSHARE}</span>
        <span className='mx-1'>{buttonFULL}</span>

        <span className={`fw400 py-1 px-2 mx-2 textContrastColor `}>
          {obj.STATUS_ID < 2 ? 'Em Simulação' : 'Em Processo'}
        </span>

        <PKG.IconsUI
          info={PKG.ui.file.pdf}
          clsnm='svg18 cLink tran05 growSvg20 mousePointerHover ms-2'
          tooltip='Baixar PDF'
          id='btnPrintPDF'
          click={() => {
            navigate(`/report/simulacao/${obj.PROCESSO_CODIGO}`);
          }}
          canvasIgnore={true}
          sideDivClass='ms-1'
        />

        <PKG.IconsUI
          info={PKG.ui.action.delete.trash}
          clsnm='svg14 cRed tran05 growSvg20 mousePointerHover ms-3'
          tooltip='Deletar'
          click={del}
          canvasIgnore={true}
          sideDivClass='ms-1'
        />
      </div>
      <div>
        <div className='gorow align-items-center justify-content-between'>
          <span className='gocol justify-content-start'>
            <PKG.IconsUI
              info={PKG.ui.user.reg}
              clsnm='svg16 me-2 border rounded-5 border-2 border-info cLink p-1 wp40 hp40 mousePointerHover hovGreen'
              link={obj.USER_CODIGO ? `/cliente/${obj.USER_CODIGO}` : null}
              tooltip={obj.USER_CODIGO ? 'Ver cliente' : 'Ainda não cadastrado'}
              click={
                obj.USER_CODIGO
                  ? null
                  : () => {
                      const sweetProps = CONF.swalProps[theme];
                      Swal.fire({
                        title: 'Cliente não cadastrado',
                        text: 'Deseja cadastrar um novo cliente?',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        ...sweetProps,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          // navigate('cliente/novo');
                        }
                      });
                    }
              }
              canvasIgnore={false}
              sideTextClass='ms-1'
              sideText={
                <span className='fs08 fw300 gocol'>
                  <span className='textContrast'>{obj.NOME}</span>
                  <PKG.IconsUI
                    info={PKG.ui.social.email}
                    clsnm='svg14'
                    link={`mailto:${obj.EMAIL}`}
                    tooltip='Enviar e-mail'
                    sideText={obj.EMAIL}
                    sideTextClass='ms-1 textContrast'
                    sideDivClass='fs12 mt-1 divHovGreen hovGreen'
                  />
                  <PKG.IconsUI
                    info={PKG.ui.social.whatsapp}
                    clsnm='svg14 cGreen hovGreen'
                    link={obj.whatsapp}
                    tooltip='Enviar mensagem no WhatsApp'
                    sideText={obj.WHATSAPP}
                    sideTextClass='ms-1'
                    sideDivClass='mt-1 divHovGreen hovGreen'
                  />
                </span>
              }
            />
          </span>

          <PKG.IconsUI
            info={PKG.ui.misc.clock.reg}
            clsnm='svg12'
            sideText={
              <span className='gocol justify-content-end faRight fs08 fw300'>
                <span>{obj.data_criado}</span>
                <span>{obj.hora_criado}</span>
              </span>
            }
            sideTextClass='ms-1'
          />
        </div>
        <div className='gorow justify-content-between'>
          <div className='gocol mt-5 justify-content-end align-items-start'>
            <h6 className='ffSoft mb-0'>Relatório de Simulação</h6>
            <p className='fs10 textContrastLow mb-3'>
              Gerado em {new Date().toLocaleDateString('pt-BR')}
              {' às '}
              {new Date().toLocaleTimeString('pt-BR')}
            </p>

            <h4 className='textContrastColor mb-0'>{obj.NOME}</h4>
            <p className='fw500 fs12'>{obj.CPF}</p>
          </div>

        </div>

        <div
          id={`dados_simulacao_${obj.PROCESSO_CODIGO}`}
          className='divHide mt-2'
        >
          <p className='textContrast fs12 fw500 mb-0'>{obj.NOME}</p>
          <div className='gorow justify-content-between fs10'>
            <p className='gocol'>
              <span>{obj.EMAIL}</span>
              <span>{obj.WHATSAPP}</span>
            </p>
            <p className=''>
              {obj.data_criado} {obj.hora_criado}
            </p>
          </div>
        </div>

        <div className='gocol mt-3'>
          <div className='fs10 fw400 p-2'>
            <p
              className='fs12'
              data-html2canvas-ignore={true}
            >
              Detalhes da Simulação
            </p>

            <div className='gorow flex-wrap justify-content-start  gocol900 go100W1000'>
              <div className=' p-2'>
                <p className='my-1 fw300 fs10 textContrastColor'>Imóvel</p>
                <ModalLine
                  title='Valor'
                  first={curr(obj.IMOVEL_VALOR)}
                  showNumber={false}
                />
                <ModalLine
                  title='Cidade'
                  first={obj.IMOVEL_CIDADE}
                  showNumber={false}
                />
                <ModalLine
                  title='Operação'
                  first={obj.IMOVEL_DESCRICAO}
                  showNumber={false}
                />
              </div>

              <div className='p-2'>
                <p className='my-1 fw300 fs10 textContrastColor'>Perfil</p>
                <ModalLine
                  title='Nome'
                  first={obj.NOME}
                  second={obj.OUTRO_NOME}
                />
                {obj.ESTADO_CIVIL ? (
                  <ModalLine
                    title='Est. Civil'
                    first={obj.ESTADO_CIVIL}
                    showNumber={false}
                    second=''
                  />
                ) : (
                  ''
                )}
                <ModalLine
                  title='Nascimento'
                  first={obj.NASCIMENTO}
                  second={obj.SIMULACAO_COMPLETA ? obj.OUTRO_NASCIMENTO : ''}
                />
                {obj.CPF ? (
                  <ModalLine
                    title='CPF'
                    first={obj.CPF}
                    second={obj.SIMULACAO_COMPLETA ? obj.OUTRO_CPF : ''}
                  />
                ) : (
                  ''
                )}
                {obj.COMPOR ? (
                  <ModalLine
                    title='Relacionamento'
                    first=''
                    second={obj.RELACIONAMENTO}
                    showNumber={false}
                  />
                ) : (
                  ''
                )}
              </div>

              <div className='p-2'>
                <p className='my-1 fw300 fs10 textContrastColor'>Financeiro</p>

                <ModalLine
                  title='Renda'
                  first={curr(obj.RENDA)}
                  second={obj.SIMULACAO_COMPLETA ? curr(obj.OUTRO_RENDA) : ''}
                />
                <ModalLine
                  title='Comprovação'
                  first={obj.COMPROVAR && addSpaceComma(obj.COMPROVAR)}
                  second={
                    obj.SIMULACAO_COMPLETA
                      ? obj.OUTRO_COMPROVAR &&
                        addSpaceComma(obj.OUTRO_COMPROVAR)
                      : ''
                  }
                />
                <ModalLine
                  title='Saldo de FGTS'
                  first={curr(obj.FGTS)}
                  second={obj.SIMULACAO_COMPLETA ? curr(obj.OUTRO_FGTS) : ''}
                />
                <ModalLine
                  title='Outros Recursos'
                  first={curr(obj.RECURSOS)}
                  second={
                    obj.SIMULACAO_COMPLETA ? curr(obj.OUTRO_RECURSOS) : ''
                  }
                />
              </div>
            </div>
            <div className=' fw400 mt-3 mb-1'>
              <p className='mt-3 mb-1 fw300 textContrastColor'>
                Outras informações do cliente
              </p>
              <p>{obj.INFORMACOES}</p>
            </div>

            <div className=' fw400 mt-3 mb-1'>
              <p className='mt-3 mb-1 fw300 textContrastColor '>
                <PKG.IconsUI
                  info={PKG.ui.action.edit}
                  clsnm='svg14'
                  tooltip='Editar anotações'
                  sideText='Anotações'
                  sideTextClass='ms-1'
                  sideDivClass='fs12 mt-1 divHovGreen hovGreen mousePointerHover'
                  click={handleEditAnotacoes}
                />
                <p id={`objAnot_${obj.PROCESSO_CODIGO}`}>{obj.ANOTACOES}</p>
                <div
                  id={`objAnotEdit_${obj.PROCESSO_CODIGO}`}
                  className='gorow align-content-center divHide '
                  data-html2canvas-ignore={true}
                >
                  <input
                    type='text'
                    className='form-control fs10'
                    value={obj.ANOTACOES}
                    onChange={({ target: { value } }) => {
                      obj.ANOTACOES = value;
                    }}
                  />
                  <PKG.IconsUI
                    info={PKG.ui.action.save}
                    clsnm='svg14 ms-2'
                    tooltip='Salvar anotações'
                    sideDivClass='fs12 mt-1 divHovGreen hovGreen mousePointerHover'
                    click={handleSaveAnotacoes}
                  />
                </div>
              </p>
            </div>
          </div>

          <div className='p-2 mt-2 bgMenuSoft'>
            <p className='fs10 fw400'>Resumo</p>

            <div className='gocol gorowMin450'>
              <div className='d-flex  flex-column wpMin225 mb-3'>
                <span className='d-flex p-2 flex-column mb-1'>
                  <span className='fw400 fs08 textContrastColor'>Imóvel</span>
                  <span className=''>{curr(obj.IMOVEL_VALOR)}</span>
                </span>

                <span className='d-flex p-2 flex-column mb-1'>
                  <span className='fw400 fs08 textContrastColor'>Entrada</span>
                  <span className=''>{curr(obj.VALOR_ENTRADA)}</span>
                </span>

                <span className='d-flex p-2 flex-column mb-1'>
                  <span className='fw400 fs08 textContrastColor'>
                    Financiado
                  </span>
                  <span className=''>{curr(obj.VALOR_FINANCIADO)}</span>
                </span>

                <span className='d-flex p-2 flex-column mb-1'>
                  <span className='fw400 fs08 textContrastColor'>
                    Renda Total
                  </span>
                  <span className=''>{curr(obj.VALOR_RENDA)}</span>
                </span>

                <span className='d-flex p-2 flex-column mb-1'>
                  <span className='fw400 fs08 textContrastColor'>
                    Mais Velho
                  </span>
                  <span className=''>{obj.MAIS_VELHO}</span>
                </span>
              </div>
              <div className='d-flex flex-column fs12 wpMin225'>
                <p className='fs12 fw400 textContrastColor mb-1'>
                  Outras variáveis
                </p>
                <ModalLine
                  title='Foi beneficiado'
                  resumo={true}
                  showNumber={false}
                  first={<BadgeCheckCross res={isTrue([obj.BENEFICIADO])} />}
                  clsnmFirst={isTrue([obj.BENEFICIADO]) ? 'cGreen' : ''}
                />
                <ModalLine
                  title='Imóvel registrado'
                  resumo={true}
                  showNumber={false}
                  first={<BadgeCheckCross res={isTrue([obj.POSSUI_IMOVEL])} />}
                  clsnmFirst={isTrue([obj.POSSUI_IMOVEL]) ? 'cGreen' : ''}
                />
                {isTrue([obj.POSSUI_IMOVEL]) ? (
                  <>
                    <ModalLine
                      title='Cidade'
                      showNumber={false}
                      resumo={true}
                      first={obj.POSSUI_IMOVEL_CIDADE}
                    />
                  </>
                ) : (
                  ''
                )}
                <ModalLine
                  title='Servidor Público'
                  showNumber={false}
                  resumo={true}
                  first={
                    <BadgeCheckCross res={isTrue([obj.SERVIDOR_PUBLICO])} />
                  }
                  clsnmFirst={isTrue([obj.SERVIDOR_PUBLICO]) ? 'cGreen' : ''}
                />
                <ModalLine
                  title='3 anos de FGTS'
                  showNumber={false}
                  resumo={true}
                  first={<BadgeCheckCross res={isTrue([obj.ANOS_FGTS])} />}
                  clsnmFirst={isTrue([obj.ANOS_FGTS]) ? 'cGreen' : ''}
                />

                <ModalLine
                  title='Dependentes'
                  showNumber={false}
                  resumo={true}
                  first={<BadgeCheckCross res={isTrue([obj.DEPENDENTES])} />}
                  clsnmFirst={isTrue([obj.DEPENDENTES]) ? 'cGreen' : ''}
                />
                <ModalLine
                  title='CLT'
                  resumo={true}
                  showNumber={false}
                  first={<BadgeCheckCross res={isTrue([obj.CLT])} />}
                  clsnmFirst={isTrue([obj.CLT]) ? 'cGreen' : ''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        {/* <hr data-html2canvas-ignore={true} />
        <div className='d-flex flex-row justify-content-between'>
          <span className='sp-documentos-title'>Anexos</span>
        </div>
        <div>
          {objFiles.map((file, index) => {
            return (
              <div
                key={`${index}_${file.SIM_DOC_CODIGO}`}
                className='d-flex flex-row align-items-center justify-content-between hover-bg-light-grey'
              >
                <div className='w100'>
                  <span>{file.SIM_DOC_TITLE}</span>

                  <BlobViewer
                    blobCode={file.SIM_DOC_CODIGO}
                    blobIcon={fileIcon(file.SIM_DOC_FILES_TYPE)}
                    blobTitle={file.SIM_DOC_TITLE}
                    blobName={file.SIM_DOC_FILES_NAME}
                    blobType={file.SIM_DOC_FILES_TYPE}
                    cod_doc_sim={obj.PROCESSO_CODIGO}
                    docSim={true}
                  />
                </div>
                <span
                  className={`ms-1 c-red`}
                  onClick={() =>
                    handleDelete(obj.PROCESSO_CODIGO, file.SIM_DOC_CODIGO)
                  }
                >
                  <PKG.IconsUI
                    info={PKG.ui.action.delete.trash}
                    clsnm='mousePointerHover'
                  />
                </span>
              </div>
            );
          })}
        </div>
        <div data-html2canvas-ignore={true}>
          <DragAndDrop
            onFilesSelected={setFiles}
            filesSt={files}
            docs={a.documentos}
            upd={() => cleanFiles()}
          />
        </div>
        {files.length > 0 ? (
          <div
            className='mt-2 d-flex flex-row justify-content-center '
            data-html2canvas-ignore={true}
          >
            <div>
              {uploading ? (
                <RotatingSquare
                  visible={true}
                  height='80'
                  width='80'
                  color='#6cbe00'
                  ariaLabel='rotating-square-loading'
                  wrapperStyle={{}}
                  wrapperClass=''
                />
              ) : (
                <Button
                  variant='primary'
                  className='ms-3 ffSoft'
                  size='sm'
                  onClick={() => {
                    uploadFilesToServer();
                  }}
                >
                  Enviar Arquivos
                </Button>
              )}
            </div>
          </div>
        ) : (
          ''
        )} */}

        {/* <hr data-html2canvas-ignore={true} />
        <Financiamentos
          vr_renda={obj.VALOR_RENDA}
          vr_imovel={obj.IMOVEL_VALOR}
          vr_entrada={obj.VALOR_ENTRADA}
          dt_nascimento={obj.MAIS_VELHO}
          fgts_3anos={obj.ANOS_FGTS}
          beneficiado_mcmv={obj.BENEFICIADO}
          possui_imovel_reg={obj.POSSUI_IMOVEL}
          ismodal={true}
          full={full}
          datahtml2canvasignore={true}
        /> */}

        <div
          className='d-flex flex-column'
          data-html2canvas-ignore={true}
        >
          <span className='mt-4 mb-1 sp-documentos-title'>
            Enviar por e-mail para:
          </span>
          <div className='d-flex flex-row align-items-center justify-content-between '>
            <input
              type='text'
              className='form-control fs08'
              name='mailto'
              onChange={({ target: { value } }) => setEmail(value)}
              value={email}
              id='mailto'
            />
            {sendingEmail ? (
              <Spinner
                animation='border'
                variant='success'
                size='sm'
              />
            ) : (
              <Button
                variant='success'
                className='ms-3 fw500 cBlack ffSoft'
                size='sm'
                onClick={() => {
                  sendPdfToMail();
                }}
              >
                Enviar
              </Button>
            )}
          </div>

          <span
            id='info-email-sent'
            className='ffSoft fs08 my-1'
          >
            {infoEmail}
          </span>
        </div>
        <div
          className='my-5'
          id={`#ativar-${obj.PROCESSO_CODIGO}`}
          data-html2canvas-ignore={true}
        >
          <p className='ffSoft textContrastColor'>
            Ativar andamentos do processo
          </p>
        </div>

        {/* <hr />
        <p className='mt-2 fs08 fw300 text-end'>
          <span className='ffSoft fw600 fs12 textContrastLow'>
            Relatório gerado em {new Date().toLocaleDateString('pt-BR')} às{' '}
            {new Date().toLocaleTimeString('pt-BR')}
          </span>
        </p> */}
      </>

    </div>
  );
}
