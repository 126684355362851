import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../config/imports';

export default function Clientes() {
  const { checkLogin } = useContext(GlobalContext);
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const logged = checkLogin();
    setIsLogged(logged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLogged) return null;

  return (
    <div className='setWidth mt-5'>
      <p>Clientes</p>
    </div>
  );
}
