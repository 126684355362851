import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import {
  API,
  END,
  PKG,
  ProcessoModal,
  SimulacaoModal,
  // BadgeOnOff,
  BadgeCompor,
  BadgeFull,
  BadgeShare,
} from '../../../config/imports';

export default function SimulacaoLine({ obj, upd }) {
  const { theme } = useContext(PKG.PackageContext);

  const { createLinkWhatsapp, curr } = PKG.SCR.Utils;
  const [showSimulacao, setShowSimulacao] = useState(false);
  const [showProcessoCreate, setShowProcessoCreate] = useState(false);
  const [active, setActive] = useState();
  const [loading, setLoading] = useState();

  const [modalSimulacaoFull, setModalSimulacaoFull] = useState(true);

  const handleCloseSimulacao = () => setShowSimulacao(false);
  const handleShowSimulacao = () => setShowSimulacao(true);

  const handleSetSimulacaoModalFull = () => {
    const newstate = !modalSimulacaoFull;
    setModalSimulacaoFull(newstate);
  };

  const handleCloseProcessoCreate = () => setShowProcessoCreate(false);
  // const handleShowProcessoCreate = () => {
  //   setShowProcessoCreate(true);
  // };

  const handleObj = (newObj) => {
    obj = newObj;
  };

  function simpleDescription(description) {
    const simplifiedDescriptions = {
      'Aquisição de Imóvel Novo': 'Imóvel novo',
      Construção: 'Construção',
      'Reforma ou Ampliação': 'Reforma',
      'Aquisição de Imóvel Usado': 'Imóvel usado',
      'Aquisição de Terreno': 'Terreno',
      'Empréstimo Garantido por Imóvel': 'Empréstimo',
      'Imóveis CAIXA': 'Imóveis CAIXA',
    };

    return simplifiedDescriptions[description] || description; // Returns simplified description or the original if not found
  }

  obj.tipo = obj.SIMULACAO_COMPLETA ? 'Completa' : 'Simplificada';

  obj.data_criado = new Date(obj.CREATED_AT).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });

  obj.hora_criado = new Date(obj.CREATED_AT).toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  obj.whatsapp = obj.WHATSAPP ? createLinkWhatsapp(obj.WHATSAPP) : '';

  // obj.imovel = {
  //   valor: parseFloat(obj.IMOVEL_VALOR),
  //   avaliacao: parseFloat(obj.IMOVEL_VALOR),
  //   cidade: obj.IMOVEL_CIDADE,
  // };

  // obj.perfil = {
  //   data_criado: new Date(obj.CREATED_AT).toLocaleDateString('pt-BR', {
  //     day: '2-digit',
  //     month: '2-digit',
  //     year: '2-digit',
  //   }),
  //   hora_criado: new Date(obj.CREATED_AT).toLocaleTimeString('pt-BR', {
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     hour12: false,
  //   }),
  //   whatsapp: obj.WHATSAPP ? createLinkWhatsapp(obj.WHATSAPP) : '',
  //   compor: obj.COMPOR,
  //   relacionamento: obj.RELACIONAMENTO,
  //   mais_velho: obj.MAIS_VELHO,
  //   cliente: {
  //     nome: obj.NOME,
  //     cpf: obj.CPF,
  //     nascimento: obj.NASCIMENTO,
  //     comprovacao: obj.COMPROVAR,
  //     renda: parseFloat(obj.RENDA),
  //     fgts: parseFloat(obj.FGTS),
  //     recursos: parseFloat(obj.RECURSOS),
  //   },
  //   outro: {
  //     nome: obj.OUTRO_NOME,
  //     cpf: obj.OUTRO_CPF,
  //     nascimento: obj.OUTRO_NASCIMENTO,
  //     comprovacao: obj.OUTRO_COMPROVAR,
  //     renda: parseFloat(obj.OUTRO_RENDA || 0),
  //     fgts: parseFloat(obj.OUTRO_FGTS || 0),
  //     recursos: parseFloat(obj.OUTRO_RECURSOS || 0),
  //   },
  //   beneficiado: isTrue([obj.BENEFICIADO]),
  //   possui_imovel: isTrue([obj.POSSUI_IMOVEL]),
  //   servidor_publico: isTrue([obj.SERVIDOR_PUBLICO]),
  //   dependentes: isTrue([obj.DEPENDENTES]),
  //   anos_fgts: isTrue([obj.ANOS_FGTS]),
  //   clt: isTrue([obj.CLT]),
  //   possui_imovel_cidade: obj.POSSUI_IMOVEL_CIDADE,
  // };

  // obj.resumo = {
  //   rendas: parseFloat(obj.VALOR_RENDA),
  //   fgts: parseFloat(obj.VALOR_ENTRADA_FGTS),
  //   recursos: parseFloat(obj.VALOR_ENTRADA_RECURSOS),
  // };

  // obj.resumo.entrada = parseFloat(obj.VALOR_ENTRADA);
  // obj.resumo.financiado = parseFloat(obj.VALOR_FINANCIADO);

  // obj.proc = {
  //   perfil: obj.perfil,
  //   imovel: obj.imovel,
  //   resumo: obj.resumo,
  // };

  // obj.proc.fin = {
  //   banco: '',
  //   tabela: '',
  //   taxa: '',
  //   linha: '',
  //   operacao: obj.IMOVEL_DESCRICAO,
  //   compartilhar: false,
  //   informacoes: obj.INFORMACOES,
  //   headline: '',
  // };

  // obj.proc.atendimento = {
  //   corretor: '',
  //   imobiliaria: '',
  //   correspondente: '',
  // };

  // obj.proc.user = {
  //   nome: obj.NOME,
  //   cpf: obj.CPF,
  //   whatsapp: obj.TELEFONE,
  //   email: obj.EMAIL,
  // };

  const update_active = async (event) => {
    event.preventDefault();
    let codigo = obj.CODIGO;
    let updated_active = active ? 0 : 1;

    try {
      setLoading(true);
      await API(END.sim.update.active, {
        codigo,
        active: updated_active,
      });
      setActive(updated_active);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const delete_simulacao = async (event) => {
    event.preventDefault();
    const delete_sim = async () => {
      try {
        setLoading(true);
        await API(END.sim.delete, {
          codigo: obj.CODIGO,
        });
      } catch (error) {
        console.error(error);
      }
      upd();
      setLoading(false);
    };

    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você deseja deletar essa simulação? Essa ação não pode ser desfeita!',
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonColor: '#c41000',
      confirmButtonText: 'Sim, deletar',
      iconColor: '#c41000',
      denyButtonColor: '#6cbe00',
      denyButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Ok, deletado!', '', 'success');
        delete_sim();
      } else if (result.isDenied) {
        Swal.fire('Tudo bem, vou manter a simulação aqui', '', 'info');
      }
    });
  };

  useEffect(() => {
    const dataBR = (stringDate) => {
      const regexYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
      if (regexYYYYMMDD.test(stringDate)) {
        let parts = stringDate.split('-');
        return (
          parts[2] +
          '/' +
          parts[1].toString().padStart(2, '0') +
          '/' +
          parts[0].toString().padStart(2, '0')
        );
      }
      return stringDate;
    };

    const dateFormat = (stringDate) => {
      let formated = stringDate;
      if (stringDate) {
        if (stringDate.includes('T')) {
          formated = stringDate.split('T')[0];
        }
        return dataBR(formated);
      }
      return formated;
    };

    obj.tipo = obj.SIMULACAO_COMPLETA ? 'Completa' : 'Simplificada';
    obj.NASCIMENTO = dateFormat(obj.NASCIMENTO);
    obj.OUTRO_NASCIMENTO = obj.OUTRO_NASCIMENTO
      ? dateFormat(obj.OUTRO_NASCIMENTO)
      : null;
    obj.MAIS_VELHO = dateFormat(obj.MAIS_VELHO);

    const stringToArray = (string) => {
      if (string && string.includes(',')) {
        return string.split(',');
      }
      if (string && string.length > 0) {
        const newArray = [];
        newArray.push(string);
        return newArray;
      } else {
        return [];
      }
    };

    obj.COMPROVAR = stringToArray(obj.COMPROVAR);
    obj.OUTRO_COMPROVAR = stringToArray(obj.OUTRO_COMPROVAR);
  }, [obj]);

  return (
    <tr className='tableSimulacoes gorowMin550 gocol550 align-items-center justify-content-center w100'>
      <td className='hpMin125 w100 gocol gorowMin900 align-items-center justify-content-center'>
        <span className='gocol gorowMin900 go100W450 align-items-center justify-content-center'>
          <span className='wpMin175 breakAuto'>{obj.NOME}</span>
          <span className='gorowMin750 wpMin175 px-1 align-items-center justify-content-center'>

            <PKG.IconsUI
              info={PKG.ui.social.whatsapp}
              clsnm='svg10 cGreen me-1'
              link={obj.whatsapp}
              linkTargetToBlank={true}
              linkAriaLabel={`Whatsapp de ${obj.NOME}`}
              sideDivClass='mx-1 wpMin125 faCenter divIconsUi hovGreen justify-content-center wp px-1'
              sideText={obj.WHATSAPP}
            />
            <PKG.IconsUI
              info={PKG.ui.social.email}
              clsnm='wp15 wpMin20 hp15  me-1'
              link={`mailto:${obj.EMAIL}`}
              linkTargetToBlank={true}
              linkAriaLabel={`E-mail de ${obj.NOME}`}
              sideDivClass='mx-1 wp200 faCenter divIconsUi hovGreen justify-content-center wp px-1'
              sideText={obj.EMAIL}
              sideTextClass='breakWord'
            />
          </span>
        </span>
      </td>

      <td className='hpMin125 w100 gocol gorowMin1100 align-items-center justify-content-center'>
        <span className='gocol gorowMin1100 align-items-center justify-content-center'>
          <span className='gocol'>
            <span className='wpMin150 px-1'>{curr(obj.IMOVEL_VALOR)}</span>
            <span className='wpMin150 px-1'>
              {simpleDescription(obj.IMOVEL_DESCRICAO)}
            </span>
          </span>
          <span className='wpMin150 breakAuto px-1'>{obj.IMOVEL_CIDADE}</span>
        </span>
      </td>

      <td className='hpMin125 w100 gocol gorowMin900 align-items-center justify-content-center'>
        <span className='gocol mAuto align-items-center justify-content-center'>
          <span className=' mAuto  gorow align-items-center justify-content-center'>
            <span className='mx-1'>{obj.data_criado}</span>
            <span className='mx-1'>{obj.hora_criado}</span>
          </span>

          <span className='gorow flex-wrap  mAuto align-items-center justify-content-center'>
            <span className='gorow py-1 mAuto  align-items-center justify-content-center'>
             
              {obj.STATUS_ID > 1 ? (
                <PKG.IconsUI
                  info={PKG.ui.misc.add_database}
                  clsnm='svg20 wp25 hp25 cGreen mousePointerHover'
                  tooltip='Abrir processo'
                  click={() => setShowProcessoCreate(true)}
                  sideDivClass='mx-2'
                />
              ) : (
                <PKG.IconsUI
                  info={PKG.ui.etapas.simulacao}
                  clsnm='svg20 wp25 hp25 cGreen mousePointerHover'
                  tooltip='Abrir simulação'
                  click={handleShowSimulacao}
                  sideDivClass='mx-2'
                />
              )}

              <span className='mx-2'>
                <BadgeFull full={obj.SIMULACAO_COMPLETA} />
              </span>

              <span className='mx-2'>
                <BadgeCompor compor={obj.SIMULACAO_COMPLETA && obj.COMPOR} />
              </span>
            </span>
            <span className='gorow py-1 mAuto  align-items-center justify-content-center'>
              <span className='mx-2'>
                <BadgeShare share={obj.COMPARTILHAR_CORRETOR} />
              </span>
              <PKG.IconsUI
                info={PKG.ui.action.delete.trash}
                clsnm={`svg16  ${
                  obj.STATUS_ID > 1
                    ? 'textContrastLow'
                    : 'cRed mousePointerHover'
                }`}
                tooltip={obj.STATUS_ID > 1 ? null : 'Deletar simulação'}
                click={obj.STATUS_ID > 1 ? null : delete_simulacao}
                sideDivClass='mx-2'
              />
            </span>
          </span>
        </span>
      </td>

      <>
        <Modal
          show={showSimulacao}
          size='lg'
          fullscreen={modalSimulacaoFull}
          data-bs-theme={theme}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          onHide={handleCloseSimulacao}
        >
          <Modal.Header
            closeButton
            className='setWidth py-3'
          >
            <Modal.Title className='fs16 fw300 w100'>
              <div className='gorow justify-content-between'>
                <span className='gocol'>
                  <span className='fw100 fs10'>Simulação</span>
                  <span className='fw600 textContrastColor'>{obj.NOME}</span>
                </span>
              </div>
            </Modal.Title>
            <PKG.IconsUI
              info={
                modalSimulacaoFull
                  ? PKG.ui.fullscreen.exit
                  : PKG.ui.fullscreen.enter
              }
              clsnm={` svg16 mousePointerHover hovGreen m-1`}
              id='normal'
              tooltip={
                modalSimulacaoFull
                  ? 'Sair do modo tela cheia'
                  : 'Entrar no modo tela cheia'
              }
              click={handleSetSimulacaoModalFull}
            />
          </Modal.Header>
          <Modal.Body>
            <SimulacaoModal
              obj={obj}
              on={active}

              buttonCOMPOR={
                <BadgeCompor compor={obj.SIMULACAO_COMPLETA && obj.COMPOR} />
              }
              buttonSHARE={<BadgeShare share={obj.COMPARTILHAR_CORRETOR} />}
              buttonFULL={<BadgeFull full={obj.SIMULACAO_COMPLETA} />}
              load={loading}
              del={delete_simulacao}
              full={modalSimulacaoFull}
            />
          </Modal.Body>
        </Modal>
      </>
      <>
        <Modal
          show={showProcessoCreate}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          onHide={handleCloseProcessoCreate}
        >
          <Modal.Header
            closeButton
            className='setWidth'
          >
            <Modal.Title>Novo Processo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProcessoModal
              obj={obj}
              on={active}
              changeActive={update_active}
              // buttonON={BadgeOnOff}
              load={loading}
              nobj={handleObj}
            />
          </Modal.Body>
          <Modal.Footer className='setWidth'>
            <Button
              variant='light'
              onClick={handleCloseProcessoCreate}
            >
              Sair
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </tr>
  );
}
