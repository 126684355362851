import { useEffect, useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { PKG } from '../config/imports';
import '../assets/css/app/dragdrop.css';
import Swal from 'sweetalert2';

export default function DragAndDrop({ onFilesSelected, filesSt, docs = [], upd = null }) {
  useEffect(() => {
    setFiles(filesSt);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFiles(filesSt);
    // eslint-disable-next-line
  }, [upd]);
  
  const { theme } = useContext(PKG.PackageContext);
  const [files, setFiles] = useState(filesSt);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      const newFilesObject = newFiles.map((file) => {
        return {
          file,
          description: 'Documento',
        };
      });
      setFiles((prevFiles) => [...prevFiles, ...newFilesObject]);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(files);
  }, [files, onFilesSelected]);

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      const acceptedFiles = newFiles.filter(
        (file) => file.type.includes('pdf') || file.type.includes('image'),
      );
      if (acceptedFiles.length > 0) {
        const newFiles = Array.from(acceptedFiles);
        const newFilesObject = newFiles.map((file) => {
          return {
            file,
            description: 'Documento',
          };
        });
        setFiles((prevFiles) => [...prevFiles, ...newFilesObject]);
      }

      const rejectedFiles = newFiles.filter(
        (file) => !file.type.includes('pdf') && !file.type.includes('image'),
      );

      if (rejectedFiles.length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Apenas arquivos PDF ou imagens são permitidos',
          background: theme === 'light' ? '#fff' : '#333',
          iconColor: theme === 'light' ? '#aa0011' : '#aa0011c2',
          color: theme === 'light' ? '#333' : '#fff',
          customClass: {
            title: 'fs24 fw600 textContrastSoft',
          },
        });
      }
    }
  };

  return (
    <section className='m-1 drag-drop'>
      <div
        className={`document-uploader ${
          files.length > 0 ? 'upload-box active' : 'upload-box'
        }`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <div className='p-3 w100 border border-secondary faCenter rounded'>
          <div className='d-flex flex-row align-items-center justify-content-center'>
            <PKG.IC.AiOutlineCloudUpload className='svg34 cLink me-2' />
            <span className='ffSoft cLink fw500'>
              Arraste seus arquivos aqui
            </span>
          </div>
          <div className=''>
            <input
              type='file'
              hidden
              id='browse'
              onChange={handleFileChange}
              accept='.pdf,.png,.jpg,.jpeg'
              multiple
            />
            <label
              htmlFor='browse'
              className='browse-btn fs08 textContrastLow'
            >
              Ou clique aqui para selecionar
            </label>
          </div>
        </div>

        {files.length > 0 && (
          <div className='filesList mx-1 my-4'>
            {files.map((file, index) => (
              <div
                className='file-item fs10'
                key={index}
              >
                <div className='file-info'>
                  <p>{PKG.fileIcon(file.file.type, 'svg16')}</p>
                  <p className='textContrast breakWord fs12'>{file.file.name}</p>
                  <p className='ms-1 fs12 noBreak'>
                    {PKG.SCR.Utils.formatBytes(file.file.size)}
                  </p>
                </div>

                <Form.Select
                  size='sm'
                  className='mx-2 w85 wpMax400 form-control'
                  aria-label='Tipo de Documento'
                  data-bs-theme={theme}
                  onChange={(e) => {
                    const selected = e.target.value;
                    const newFiles = [...files];
                    newFiles[index].description = selected;
                    setFiles(newFiles);
                  }}
                >
                  <option
                    value='Documento'
                    id={`form_files_sel_0`}
                  >
                    Selecione o tipo
                  </option>
                  {docs.map((doc, i) => (
                    <option
                      key={i}
                      value={doc.NOME}
                      id={`form_files_sel_${i + 1}`}
                    >
                      {doc.NOME}
                    </option>
                  ))}
                </Form.Select>

                <div className='file-actions ms-1'>
                  <PKG.IconsUI
                    info={PKG.ui.action.delete.trash}
                    clsnm='ms-1 cLink svg16'
                    tooltip='Remover arquivo'
                    click={() => handleRemoveFile(index)}
                    sideDivClass='divIconsUi mousePointerHover'
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {files.length > 0 && (
          <div className='d-flex flex-row align-items-center fs10'>
            <PKG.IC.AiOutlineCheckCircle className='cGreen me-2' />
            <span>
              {files.length > 1
                ? `${files.length} arquivos selecionados`
                : `${files.length} arquivo selecionado`}{' '}
            </span>
          </div>
        )}
      </div>
    </section>
  );
}
