import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import { CONF, API, END } from '../../config/imports';

function handleSaveAsPDF(filename) {
  const opt = {
    margin: 1,
    filename: filename,
    autoPaging: 'text',
    enableLinks: false,
    image: { type: 'jpeg', quality: 1 },
    logging: false,
    pagebreak: { mode: ['avoid-all', 'css'] },
    html2canvas: {
      autoPaging: 'text',
      compress: false,
      allowTaint: true,
      letterRendering: true,
      logging: false,
      scale: 1,
    },
    jsPDF: {
      unit: 'cm',
      format: 'a4',
      orientation: 'portrait',
      compress: false,
      autoPaging: 'text',
      logging: false,
      putOnlyUsedFonts: true,
    },
  };
  return opt;
}

export async function download(elementId, filename) {
  const element = document.getElementById(elementId);
  const opt = handleSaveAsPDF(filename);
  html2pdf().set(opt).from(element).save();
  const blob = await html2pdf().set(opt).from(element).outputPdf('blob');
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.click();
}

async function handlePDF(
  elementId,
  action = 'save',
  filename = null,
  scale = 0.0265,
  iframeId = null,
) {
  const jsPdf = new jsPDF('p', 'cm', 'a4');
  const element = document.getElementById(elementId);
  let pdfBlob;

  const opt = {
    callback: async function (jsPdf) {
      pdfBlob = await jsPdf.output('blob');
      const pdfURL = URL.createObjectURL(pdfBlob);

      switch (action) {
        case 'save':
          await jsPdf.save(filename);
          break;
        case 'open':
          window.open(pdfURL, '_blank');
          if (filename) {
            jsPdf.save(filename);
          }
          break;
        case 'iframe':
          const iframe = document.getElementById(iframeId);
          if (iframe) {
            iframe.src = pdfURL;
          }
          break;
        case 'blob':
          return pdfBlob;
        default:
          console.error('Ação inválida.');
          break;
      }
    },
    margin: [1.5, 1, 1.5, 1],
    enableLinks: false,
    image: { type: 'jpeg', quality: 1 },
    compress: false,
    autoPaging: 'text',
    logging: false,
    pagebreak: { mode: ['avoid-all', 'css'] },
    putOnlyUsedFonts: true,
    html2canvas: {
      allowTaint: true,
      letterRendering: true,
      logging: false,
      pagebreak: { mode: ['avoid-all', 'css'] },
      scale,
      unit: 'cm',
      format: 'a4',
      orientation: 'portrait',
      compress: false,
      putOnlyUsedFonts: true,
    },
  };

  await jsPdf.html(element, opt);
  if (action === 'blob') return pdfBlob;
}

export function downloadPDF(elementId, filename, scale = 0.0265) {
  handlePDF(elementId, 'save', filename, scale);
}

export async function getBlob(elementId) {
  const blob = await handlePDF(elementId, 'blob');
  return blob;
}

export function previewPDF(elementId, filename = null, scale = 0.0265) {
  handlePDF(elementId, 'open', filename, scale);
}

export function renderPDFInIframe(elementId, iframeId, scale = 0.0265) {
  handlePDF(elementId, 'iframe', null, scale, iframeId);
}

export async function sendPdfToMail(
  file,
  filename,
  mailTo,
  subject,
  emailContent = '',
  mailToCC = '',
) {
  try {
    const logo = await API(END.tenants.logo_link, {
      brand: CONF.brand,
    });
    const logo_link = logo.data;

    const files = [];
    const filenames = [];
    files.push(file);
    filenames.push(filename);

    const formData = new FormData();
    formData.append('email', mailTo);
    formData.append('cc', mailToCC);
    formData.append('subject', subject);

    files.forEach((file, index) => {
      formData.append('files[]', file);
      formData.append('filename[]', filenames[index]);
    });

    formData.append('content', emailContent);
    formData.append('logo_link', logo_link);
    formData.append('brand', CONF.brand);
    await API(END.mail.docs, formData);
  } catch (error) {
    console.error(error);
  }
}
